import React from 'react';
import { useFormikContext } from 'formik';
import { MAGICIAN_SUBSCRIPTION_MONTHLY_COST } from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';

type multimonthConfig = {
  value: number;
  savings?: string;
  subheader: string;
  title: string;
  starter_pack: boolean;
  titleExtra?: string;
  badgeContent?: string;
};

const MagicianMultimonthPlanSelectionForm = ({ firstTimeChoice }) => {
  const { values } = useFormikContext<{
    multimonth_plan: number;
    drug_variant: string;
  }>();
  const selectedSubProduct = values.drug_variant || MagicianSubProducts.Semaglutide;

  const savingsConfig = {
    [MagicianSubProducts.Semaglutide]: {
      3: '120.00',
    },
    [MagicianSubProducts.Tirzepatide]: {
      3: '240.00',
    },
  };

  const multimonthConfig: multimonthConfig[] = [
    {
      value: 3,
      savings: `$${savingsConfig[selectedSubProduct][3]} yearly savings`,
      subheader: 'Bills every 3 months',
      title: 'Quarterly Plan',
      starter_pack: false,
    },
    {
      value: 1,
      subheader: 'Bills every month',
      title: 'Monthly Plan',
      starter_pack: false,
    },
  ];

  if (firstTimeChoice) {
    multimonthConfig.unshift({
      value: 3,
      starter_pack: true,
      titleExtra: '(first 3 mos)',
      badgeContent: 'Best Value',
      subheader: 'Start with 3 months of treatment then Quarterly or Monthly after that',
      title: 'Starter Pack',
    });
  }

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.Magician}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={multimonthConfig}
      priceConfig={MAGICIAN_SUBSCRIPTION_MONTHLY_COST}
    />
  );
};

export default MagicianMultimonthPlanSelectionForm;
