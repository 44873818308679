import React, { useEffect, useState } from 'react';
import MessagesIcon from '../../../../../images/icon-messages.svg';
import { push } from 'connected-react-router/immutable';
import { useDispatch } from 'react-redux';
import * as Routes from 'app/constants/Routes';
import axios from 'axios';
import './css/Messages.scss';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';

const MessagesButton = () => {
  const customer = useAppSelector(selectCustomer);
  if (!customer.get('messages_enabled')) return null;

  const dispatch = useDispatch();
  const [unread, setUnread] = useState<boolean>(false);

  const goToMessages = () => {
    dispatch(push(Routes.Messages));
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (document.visibilityState !== 'visible') return;
      try {
        const response = await axios.get('/api/messages/check_unread');
        setUnread(response.data.unread);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    const intervalId = setInterval(fetchMessages, 5000);
    fetchMessages();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <button onClick={goToMessages} className="relative">
      <img alt="messages" src={MessagesIcon} />
      {unread && <div className="red-circle"></div>}
    </button>
  );
};

export default MessagesButton;
