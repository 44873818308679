function importAll(r: __WebpackModuleApi.RequireContext) {
  const images: { [key: string]: string } = {};
  r.keys().forEach((fileName: string) => {
    // Remove the './' prefix if needed
    const key = fileName.replace('./', '').split('.')[0];
    images[key] = r(fileName).default || r(fileName);
  });
  return images;
}

const assetLibrary = importAll(require.context('assets', false));
export default assetLibrary;
