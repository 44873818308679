import React, { ComponentProps, ReactNode, forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog as DialogSource, Icon } from '@blueprintjs/core';
import { Modal } from 'mui';
import Color from '../../styles/color.module.css';
import Type from './_type.module.css';

/**
  __Component 'Dialog'__
* */

const Dialog = forwardRef(
  (
    props: {
      type?: keyof typeof Type;
      view?: 'filled' | 'smooth' | 'raised';
      color?: keyof typeof Color;
      icon?: ComponentProps<typeof Icon>['icon'];
      title?: ReactNode;
      text?: ReactNode;
      leftButton?: ReactNode;
      rightButton?: ReactNode;
      backdropOpacity?: 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
      onClose?: () => void;
      className?: string;
      notification?: ReactNode;
      onClick?: () => void;
      isOpen: boolean;
      showCloseModalIcon?: boolean;
    } & ComponentProps<typeof DialogSource>,
  ) => {
    const {
      type = 'default',
      view = 'filled',
      color = 'default',
      icon,
      title,
      text,
      leftButton,
      rightButton,
      backdropOpacity = '20',
      onClose,
      className,
      notification,
      showCloseModalIcon = true,
      ...restProps
    } = props;

    return (
      <Modal isOpen={restProps.isOpen} onClose={onClose} showCloseIcon={showCloseModalIcon} dialogClassName={className}>
        <Modal.Header>
          <h3 className="lg:hidden">{title}</h3>
          <h5 className="hidden lg:block lg:visible">{title}</h5>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
      </Modal>
    );
  },
);

Dialog.propTypes = {
  /**
   `The type of the component.
   * Variants: `dense` `default`
   * Default value (if undefined): `default` `
   */
  type: PropTypes.oneOf(['default', 'dense']),
  /**
   ` The view of the component.
   * Variants: `smooth` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(['filled', 'smooth', 'raised']),
  /**
  ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  /**
   * Click event handler.
   */
  onClick: PropTypes.func,
  /**
   * Name of a icon to render in the dialog's header. Note that the header
   *      will only be rendered if title is provided.
   */
  icon: PropTypes.any,
  /**
   * Title of the dialog.
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Action text. Haven't default state.
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Opacity of backdrop. From 0 to 100 with step 10.
   */
  backdropOpacity: PropTypes.oneOf([0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
  /**
   * Left button of dialog.
   */
  leftButton: PropTypes.element,
  /**
   * Right button of dialog.
   */
  rightButton: PropTypes.element,
  /**
   * Toggles the visibility of the overlay and its children. This prop is required because the component is controlled.
   */
  isOpen: PropTypes.any,
  /**
   * A callback that is invoked when user interaction causes the overlay to close, such as clicking on the overlay or pressing the esc key.
   */
  onClose: PropTypes.func,
  /**
   * A boolean flag that decides to either show the cross icon at the top of modal or not.
   */
  showCloseModalIcon: PropTypes.bool,
};

export default Dialog;
