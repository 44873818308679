import React, { useEffect } from 'react';
import './css/FullscreenModal.scss';
import { createPortal } from 'react-dom';

export const FullscreenModal = ({ text }) => {
  useEffect(() => {
    const fullScreenModalHandler = (isOpen) => (document.documentElement.style.overflow = isOpen ? 'hidden' : 'auto');

    fullScreenModalHandler(true);

    return () => fullScreenModalHandler(false);
  }, []);

  return (
    <>
      {createPortal(
        <div className="fixed top-[56px] left-0 bg-white w-full h-full overflow-scroll">{text}</div>,
        document.body,
      )}
    </>
  );
};
