import React from 'react';
import './css/DiscountBanner.scss';
import { P } from 'app/components/common/Typography';
import dayjs from 'dayjs';

const ProductChangeNote = ({ nextBillingCycleStr }) => {
  if (!nextBillingCycleStr) return null;
  if (dayjs(nextBillingCycleStr).isBefore(dayjs(), 'day')) return null;

  return (
    <div className="mt-2">
      <P className="mb12">
        <b>NOTE:</b> Any changes you make to your product will take effect after your next billing cycle on{' '}
        <b>{nextBillingCycleStr}</b>.
      </P>
      <P>
        If you would like it sooner,{' '}
        <a target="_blank" className="underlined" href="mailto:support@maximustribe.com" rel="noreferrer">
          contact a Maximus concierge team member
        </a>{' '}
        to make a change to your current subscription.
      </P>
    </div>
  );
};

export default ProductChangeNote;
