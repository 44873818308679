import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { useDispatch } from 'react-redux';
import { selectCurrentIntake, selectCurrentIntakeProduct, selectNextStepPath } from 'app/selectors/customer';
import { apiRequestUserCommand } from 'app/actions/customer';
import { push } from 'connected-react-router/immutable';
import { selectUserProduct } from 'app/selectors/admin';
import { LabKitTypes } from 'app/constants/Products';
import { Button } from 'mui';

type Props = {
  setUnavailableProductDialog: (isOpen: boolean) => void;
  setOralTrtAvailable: (isAvailable: boolean) => void;
};

const UnavailableProductModal = ({ setUnavailableProductDialog, setOralTrtAvailable }: Props) => {
  const dispatch = useDispatch();
  const intake = useAppSelector(selectCurrentIntake);
  const kingV2AvailableStates = intake.get('king_v2_available_states');
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectUserProduct(state, currentProductName));
  const labKitType = product?.get('lab_kit_type');

  const nextStep = useAppSelector(selectNextStepPath);

  const onSuccessActionCreator = (_, _stateAfterSuccess) => push(nextStep);

  const modifyLabSelection = () => {
    const action = apiRequestUserCommand({
      cmdType: 'lab_selection_opt_in',
      params: {
        lab_kit_type: 'at_home_lab_kit',
        product_name: currentProductName,
      },
    });
    dispatch(action);
  };

  const updateProductSelection = () => {
    if (labKitType !== LabKitTypes.UseYourOwnLab) {
      modifyLabSelection();
    }
    const action = apiRequestUserCommand({
      cmdType: 'select_king_v2_product',
      params: { selected_king_v2_product: 'enclomiphene', intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
    setUnavailableProductDialog(false);
    setOralTrtAvailable(true);
  };

  const handleCorrectAddress = () => {
    setUnavailableProductDialog(false);
    dispatch(push(nextStep));
  };

  return (
    <>
      <p className="mt12">
        We recommend switching to our Enclomiphene Protocol and we will notify you as soon as TRT becomes available in
        your state.
      </p>
      <p className="mt12">TRT is currently only offered in these states: {kingV2AvailableStates.join(', ')}</p>
      <Button className="mt24" variant="primary" onClick={updateProductSelection}>
        SWITCH TO ENCLOMIPHENE PROTOCOL
      </Button>
      <Button className="mt24" variant="primary" onClick={handleCorrectAddress}>
        CORRECT ADDRESS
      </Button>
    </>
  );
};

export default UnavailableProductModal;
