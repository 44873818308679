import React, { useCallback, useEffect, useState } from 'react';
import Spinner from 'app/components/customer/Spinner';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/helpers/hooks';
import { apiRequestPromise } from 'app/api';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';
import { selectCustomerId } from 'app/selectors/customer';
import Alert from 'app/components/common/Alert';
import { trackEvent } from 'app/utils/useCommonTracking';
import { ManageSubscription as ManageSubscriptionRoute } from 'app/constants/Routes';
import { push } from 'connected-react-router/immutable';
import { useDispatch } from 'react-redux';

const NOT_FOUND_ERROR = 'not_found';
const NOT_VALID_FOR_PRODUCT_ERROR = 'not_valid_for_product';
const EXPIRED_ERROR = 'expired';
const ALREADY_APPLIED_ERROR = 'already_applied';
const CAPACITY_REACHED_ERROR = 'capacity_reached';

const errorMessages = {
  [NOT_FOUND_ERROR]: 'Deal not found',
  [NOT_VALID_FOR_PRODUCT_ERROR]: 'Invalid deal',
  [EXPIRED_ERROR]: 'Deal is no longer valid',
  [ALREADY_APPLIED_ERROR]: 'Deal has already been applied',
  [CAPACITY_REACHED_ERROR]: 'Capacity reached',
};

interface PromoCodeError {
  parsedJson: {
    errors: {
      promo_code: keyof typeof errorMessages;
    };
  };
}

function isPromoCodeError(e: unknown): e is PromoCodeError {
  if (typeof e !== 'object' || e === null) return false;
  if (!('parsedJson' in e)) return false;
  const parsedJson = (e as { [key: string]: unknown }).parsedJson;
  if (typeof parsedJson !== 'object' || parsedJson === null) return false;
  if (!('errors' in parsedJson)) return false;
  const errors = (parsedJson as { [key: string]: unknown }).errors;
  if (typeof errors !== 'object' || errors === null) return false;
  return 'promo_code' in errors;
}

const ApplySubscriptionDeal = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userId = useAppSelector(selectCustomerId);
  const queryParams = new URLSearchParams(location.search);
  const productName = queryParams.get('product') ?? 'king';
  const promoCode = queryParams.get('deal') ?? '';
  const eventName = queryParams.get('event');

  const [errorMessage, setErrorMessage] = useState('');

  const applyPromoCode = useCallback(async () => {
    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'create_discount_with_promo_code',
        user_id: userId,
        params: {
          promo_code: promoCode.trim(),
          product_name: productName,
        },
      });
      await dispatch(apiRequestSuccess(resp));
      return true;
    } catch (e: unknown) {
      let errorMessage = e instanceof Error ? e.message : 'An error occurred (ApplySubscriptionDeal)';
      if (isPromoCodeError(e)) {
        const errorType: keyof typeof errorMessages = e.parsedJson.errors.promo_code;
        errorMessage = errorMessages[errorType];
        setErrorMessage(errorMessage);
      } else {
        setErrorMessage('Invalid deal');
        console.error(e);
      }
      dispatch(apiRequestFailure({ message: errorMessage, reqId: 42, request: {} }));
      return false;
    }
  }, [userId, promoCode, productName, dispatch]);

  const logEvent = useCallback(() => {
    if (eventName) trackEvent(eventName, { product_name: productName });
  }, [eventName, productName, trackEvent]);

  useEffect(() => {
    applyPromoCode().then((success) => {
      if (success) {
        logEvent();
        dispatch(push(ManageSubscriptionRoute));
      }
    });
  }, [applyPromoCode, logEvent, dispatch]);

  return errorMessage.length === 0 ? (
    <Spinner isCenter />
  ) : (
    <Alert noClose type="error" className="m24 flex-center">
      {errorMessage}
    </Alert>
  );
};

export default ApplySubscriptionDeal;
