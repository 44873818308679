import React from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts } from 'app/constants/Products';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import WarriorSubProductsList from 'app/components/customer/steps/Payment/warrior/WarriorSubProductsList';
import WarriorSubProductScreen from 'app/components/customer/steps/Payment/warrior/WarriorSubProductScreen';
import ProductChangeNote from 'app/components/customer/steps/Payment/ProductChangeNote';

type Props = {
  firstTimeChoice?: boolean;
  availableSubProducts: any;
  handleSubmit: () => void;
};

const WarriorProductSelectionForm = ({ availableSubProducts, firstTimeChoice, handleSubmit }: Props) => {
  const { values } = useFormikContext<{
    warrior_strength: string;
  }>();
  const nextBillingCycleStr = nextBillingCycle(AvailableProducts.Warrior);

  const renderAlert = () => <ProductChangeNote nextBillingCycleStr={nextBillingCycleStr} />;

  const renderBody = () => {
    const selectedProduct = values.warrior_strength;

    if (!selectedProduct) {
      return <WarriorSubProductsList availableSubProducts={availableSubProducts} />;
    }

    return <WarriorSubProductScreen handleSubmit={handleSubmit} />;
  };

  return (
    <>
      {!firstTimeChoice && renderAlert()}
      {renderBody()}
    </>
  );
};

export default WarriorProductSelectionForm;
