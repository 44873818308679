/* eslint-disable jsx-a11y/label-has-for */
import React, { ComponentProps, FC, ReactNode } from 'react';
import { Field, isString, useField } from 'formik';
import cx from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dayjs from 'dayjs';

export function formatDate(date, format, _locale?) {
  if (isString(date) && !date.includes(':')) {
    // This is fix for date only fields
    return date && dayjs(date).format(format);
  }

  return date && dayjs(date).tz().format(format);
}

export const DayPickerComponent = ({
  field,
  form,
  meta,
  dateFormat = 'MM/DD/YYYY',
  className,
  disabledDays,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  ...rest
}) => (
  <DayPickerInput
    format={dateFormat}
    formatDate={(d) => formatDate(d, dateFormat)}
    placeholder={dateFormat}
    inputProps={{
      ...rest,
      readOnly: true,
      disabled: field.disabled,
      className: cx('rounded-lg', className, { 'field-error': meta?.error }),
      onBlur: form.handleBlur(field.name),
    }}
    value={formatDate(field.value, dateFormat) || ''}
    dayPickerProps={{ showOutsideDays: true, disabledDays }}
    onDayChange={(d) => {
      form.setFieldValue(field.name, formatDate(d, 'YYYY-MM-DD'));
    }}
  />
);

const InputRow: FC<{
  label?: ReactNode;
  name: string;
  type?: ComponentProps<typeof Field>['type'];
  testId?: string;
  rightElement?: ReactNode;
  disabledDays?: any;
}> = ({ label, name, type = 'number', testId, rightElement, disabledDays }) => {
  const [, meta] = useField(name);
  return (
    <label className="form-field-container">
      <div className="form-field-label-holder">
        <span className="form-field-label">{label}</span>
      </div>
      <div className="form-field-input-holder">
        <Field
          className={cx('form-field-input', { valid: meta.touched && !meta.error, error: meta.error })}
          name={name}
          type={type}
          step="any"
          data-testid={testId}
          component={DayPickerComponent}
          disabledDays={disabledDays}
        />
        <div>{rightElement}</div>
      </div>
      {meta.error && <span className="error_message">{meta.error}</span>}
    </label>
  );
};

export default InputRow;
