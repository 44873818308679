import React, { FC } from 'react';
import cx from 'classnames';
import { Button } from 'mui';

interface Props {
  className?: string;
  buttonClassName?: string;
  backDisabled?: boolean;
  onBack?: () => void;
  nextDisabled?: boolean;
  onNext?: () => void;
  noNext?: boolean;
  noBack?: boolean;
  nextText?: string;
  testId?: string;
}

const BackNextFooter: FC<Props> = ({
  className,
  buttonClassName,
  backDisabled,
  nextDisabled,
  onNext,
  onBack,
  noNext,
  noBack = false,
  nextText = 'Next',
  testId = 'next-step',
}) => {
  const nextButton = (
    <Button className={buttonClassName} onClick={onNext} disabled={nextDisabled} testid={testId}>
      {nextText}
    </Button>
  );

  const backButton = (
    <Button
      className={cx(buttonClassName, 'mt-4')}
      variant="text"
      onClick={onBack ?? (() => {})}
      disabled={backDisabled}
      testid="back-to-previous-step"
    >
      Back
    </Button>
  );

  return (
    <div className={cx(className)}>
      {!noNext && nextButton}
      {!noBack && backButton}
    </div>
  );
};

export default BackNextFooter;
