import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import React, { useState } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySubscription from 'app/components/customer/Checkout/ModalBodySubscription';
import PriceCalculator from 'app/helpers/priceCalculator';

const BILLED_EVERY_INFO = {
  1: 'month',
  3: '3 months',
  12: '12 months',
};

const BILLED_EVERY_INFO_SHORT = {
  1: 'mo',
  3: '3 mos',
  12: 'year',
};

const MODAL_DAYS_MAPPING = {
  magician: {
    1: 28,
    3: 84,
    6: 168,
    12: 336,
  },
  default: {
    1: 30,
    3: 90,
    12: 360,
  },
  warrior: 90,
};

type Props = {
  multimonthPlan: number;
  nextBillingCycle: string;
  currentProductName: string;
  isOnboarding: boolean;
  priceCalculator: PriceCalculator;
  starterPack: boolean;
};

const GenericSubscriptionSummary = ({
  isOnboarding,
  nextBillingCycle,
  multimonthPlan,
  priceCalculator,
  currentProductName,
  starterPack,
}: Props) => {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);

  const subscriptionPeriodDays = (): number => {
    if (MODAL_DAYS_MAPPING[currentProductName] && MODAL_DAYS_MAPPING[currentProductName][multimonthPlan]) {
      return MODAL_DAYS_MAPPING[currentProductName][multimonthPlan];
    }

    return MODAL_DAYS_MAPPING['default'][multimonthPlan];
  };

  if (isOnboarding && starterPack) {
    return (
      <>
        <div className="bg-main-grey rounded-xl p-4 lg:p-8">
          <div className="flex flex-row justify-between font-medium items-center text-xs mb-2">Due today</div>
          <div className="flex flex-row justify-between font-medium items-center">
            <div>
              <h5>Starter Pack</h5>
            </div>
            <PriceFormatter price={priceCalculator.subscriptionCost()} period="" />
          </div>
          {priceCalculator.bbIncluded() && <div className="paragraph-light-normal text-xs">W/ Building Blocks</div>}
          <div className="mb-3 lg:mb-6 "></div>
          <div className="text-xs">
            <p className="pb-4">
              Includes 3 months (12 weeks) supply of medicine. Starter Pack plan medications are shipped together in one
              delivery.
            </p>
          </div>
          <div className="mt-2 mb-5" style={{ borderTop: '1px solid #a9b6ff' }} />
          <div className="flex flex-row justify-between font-medium items-center text-xs mb-2">
            Starts in {Math.abs(multimonthPlan)} months
          </div>
          <div className="flex flex-row justify-between font-medium items-center">
            <div>
              <h6>Monthly Plan</h6>
            </div>
            <PriceFormatter price={priceCalculator.monthlySubscriptionCost() || 0} period="mo" />
          </div>
          {priceCalculator.bbIncluded() && <div className="paragraph-light-normal text-xs">W/ Building Blocks</div>}
          <div className="text-xs mt-3">
            <p className="pb-4">
              After 3 months (12 weeks) you will be auto-enrolled in our Monthly Plan which auto-renews on a recurring
              monthly (every 4 weeks) plan* unless a cancelation request is placed before the end of the billing cycle.
            </p>
          </div>
          <div className="text-xs mt-1 pb-4">
            <p className="">Medication ships monthly to allow for treatment customization.</p>
            <button className="link" onClick={() => setOpenModalSubscription(true)}>
              *Full Details
            </button>
          </div>
        </div>
        <SetProductDialog
          isOpen={openModalSubscription}
          title="How Your Subscription Works"
          text={
            <ModalBodySubscription setOpenModalSubscription={setOpenModalSubscription} subscriptionPeriodDays={28} />
          }
          onClose={() => setOpenModalSubscription(false)}
          className="manage_subscription__cancel_modal align-left"
        />
      </>
    );
  }

  return (
    <>
      <div className="bg-main-grey rounded-xl p-4 lg:p-8">
        <div className="flex flex-row justify-between font-medium mb-4 lg:mb-8 items-center">
          <div>
            <h5>Subscription Cost</h5>
          </div>
          <PriceFormatter price={priceCalculator.subscriptionCost()} period={BILLED_EVERY_INFO_SHORT[multimonthPlan]} />
        </div>
        {isOnboarding && (
          <div className="text-xs">
            <p className="pb-4">
              Your subscription bills upfront and auto-renews every {BILLED_EVERY_INFO[multimonthPlan]} (
              {subscriptionPeriodDays()} days)* unless a cancelation request is placed before the end of the billing
              cycle.
            </p>
            <p>Medication ships monthly to allow for treatment customization.</p>
            <p>
              <button className="link" onClick={() => setOpenModalSubscription(true)}>
                *Full Details
              </button>
            </p>
          </div>
        )}
        {!isOnboarding && (
          <p className="mb-3">
            Your new subscription cost will start on your{' '}
            <span className="bold">next billing cycle, {nextBillingCycle}</span>, and will auto-renew every{' '}
            {BILLED_EVERY_INFO[multimonthPlan]} ({subscriptionPeriodDays()} days).
            <br />
            <button className="link" onClick={() => setOpenModalSubscription(true)}>
              Full subscription details
            </button>
            .
          </p>
        )}
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="How Your Subscription Works"
        text={
          <ModalBodySubscription
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={subscriptionPeriodDays()}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default GenericSubscriptionSummary;
