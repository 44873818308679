import React, { useState } from 'react';
import Question from 'app/constants/Question';
import { useField, useFormikContext } from 'formik';
import { IntakeRadio } from 'mui';

const SwitchableRadioQuestion = ({ name, submit = true, ...rest }) => {
  const question = rest.question || new Question(name);

  const optionsByUnit = (u) => {
    return question.options.filter((o) => {
      return o.unit == u;
    });
  };

  const [field, , t] = useField({ id: question.name, name: question.name });
  const { value } = field;
  const units = question.data.switch_keys;
  const [unitIndex, setUnitIndex] = useState(optionsByUnit(units[1]).includes(value) ? 1 : 0);
  const unit = units[unitIndex];
  const { setFieldValue, submitForm } = useFormikContext();
  const revertedUnitIndex = unitIndex === 0 ? 1 : 0;

  const switchUnit = () => {
    setUnitIndex(revertedUnitIndex);
  };

  const handleChange = async (opt) => {
    await setFieldValue(question.name, opt.value);
    await t.setValue(opt.value);
    if (submit) {
      setTimeout(submitForm, 300);
    }
  };

  return (
    <div>
      <p className="mb-6 lg:mb-8">
        Amounts are shown in {unit}{' '}
        <button className="pl-2 link" onClick={switchUnit}>
          Use {units[revertedUnitIndex]} instead
        </button>
      </p>
      <div className="flex flex-col gap-y-4 lg:gap-y-6">
        {optionsByUnit(unit)?.map((opt) => (
          <button className="block" key={opt.value as string} onClick={() => handleChange(opt)}>
            <IntakeRadio
              key={opt.value as string}
              checked={opt.value === value}
              label={opt.label}
              testid={`question_${question.name}_option_${opt.value}`}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default SwitchableRadioQuestion;
