import React from 'react';
import { FormCheckbox } from 'mui';

const OTHER_PHARMACY_WARNING =
  'While you are free to choose your own pharmacy, our custom doses of specific, speciality compounded drugs are NOT carried at retail pharmacies like CVS or Walgreens. If you still decide to choose another pharmacy, you will need to verify this yourself (which is highly unlikely), and it will significantly delay your shipment. Therefore, we strongly suggest choosing the Maximus Recommended Pharmacies option, as those pharmacies are accustomed to stocking and fulfilling specific lifestyle medications, if deemed clinically appropriate and prescribed by your attending physician at the time of consult.';
const OtherOption = ({ expanded, setFieldValue, checkboxValue, toggleCheckbox }) => {
  if (!expanded) {
    return null;
  }

  return (
    <>
      <hr />
      <p>
        <b>WARNING: </b>
        {OTHER_PHARMACY_WARNING}
      </p>
      <FormCheckbox
        wrapperClassName="mb-2"
        className="lg:mt-2"
        label={
          <p>
            I acknowledge that choosing a different pharmacy may delay or prevent my ability to receive treatment.
            Maximus cannot guarantee that my pharmacy of choice can compound the ingredients included in any Maximus
            protocol I subscribe to.
          </p>
        }
        testId="pharmacy-other"
        id="choice"
        name="choice"
        checked={checkboxValue}
        onChange={async () => {
          if (checkboxValue) {
            toggleCheckbox(false);
            await setFieldValue('choice', '');
          } else {
            toggleCheckbox(true);
            await setFieldValue('choice', 'other');
          }
        }}
      />
    </>
  );
};

export default OtherOption;
