import React, { FC, ReactNode } from 'react';

import cx from 'classnames';
import './css/PopupModal.scss';

type Props = {
  children: ReactNode;
  isShow?: boolean;
  mobile?: boolean;
  headerText?: string;
  size?: 'small' | 'medium' | 'large';
  onHide?: () => void;
  onSubmit?: () => void;
  closeModal: () => void;
  cancelButtonText?: string | null;
  submitButtonText?: string;
  className?: string;
};

export const SimplePopupModal: FC<{
  isShow?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  children: ReactNode;
}> = ({ children, isShow = false, size = 'medium', className }) => (
  <div className={cx('popup-modal', className, { 'popup-modal-show': isShow })}>
    <div className={cx('popup-modal__content', size)}>{children}</div>
  </div>
);

const PopupModal = ({
  className,
  children,
  headerText,
  onSubmit,
  onHide,
  closeModal,
  isShow = false,
  mobile = false,
  size = 'medium',
  cancelButtonText = 'Cancel',
  submitButtonText = 'Ok',
}: Props) => {
  const cancelHandler = () => {
    if (onHide) {
      onHide();
    }
    closeModal();
  };

  const submitHandler = () => {
    if (onSubmit) {
      onSubmit();
    }
    closeModal();
  };

  return (
    <SimplePopupModal isShow={isShow} size={size} className={cx(className, { mobile })}>
      <p className="popup-modal__header">{headerText}</p>
      <div>{children}</div>
      <div className="popup-modal__footer">
        {submitButtonText && (
          <p className="popup-modal__footer_submit" onClick={submitHandler}>
            {submitButtonText}
          </p>
        )}
        {cancelButtonText && (
          <p className="popup-modal__footer_cancel" onClick={cancelHandler}>
            {cancelButtonText}
          </p>
        )}
      </div>
    </SimplePopupModal>
  );
};

export default PopupModal;
