import React, { FC, useCallback } from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { Formik, FormikProps, useFormikContext, FormikContextType } from 'formik';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useAppSelector } from 'app/helpers/hooks';
import { ImmutableMap } from 'app/types/admin';
import { RootState } from 'app/configureStore';
import { CustomerShippingAddress } from 'app/types';
import { InputMask } from 'app/components/common/formik/Input';
import Spinner from 'app/components/customer/Spinner';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import { clearAddressFormErrors } from 'app/actions/customer';
import { apiRequestPromiseViaDispatch } from 'app/api';
import { PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';
import { ShippingSchema } from 'app/helpers/validators';
import BackNextFooter from './BackNextFooter';
import OutOfServiceAlert from './Payment/OutOfServiceAlert';
import axios from 'axios';
import { selectValidStates } from 'app/selectors/customer';
import { PageWrapper, Card } from 'mui';
import { ShippingFields as DefaultShippingFields } from './Payment/Generic/GenericShippingForm';
import GoogleAutoCompleteWrapper from 'app/components/common/GoogleAutoComplete';
import SmsConsent from 'app/components/customer/Shipping/SmsConsent';

interface ShippingValues extends CustomerShippingAddress {
  phone_number: string;
  receive_sms: boolean;
}

const mapStateToProps = (reduxState: RootState) => {
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState) || Map();
  const customer = selectors.selectCustomer(reduxState);
  const phone_number = customer.get('phone_number') || '';
  const receive_sms = !!customer.get('receive_sms');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const prev = selectors.selectPreviousStepPath(reduxState);
  const previousStepAction = push(prev);
  const initialFullName = selectors.selectCustomerFullName(reduxState);
  const onSuccessAction = push(selectors.selectNextStepPath(reduxState));
  const initialValues = shippingAddress.merge({
    full_name: initialFullName,
    address_line_1,
    address_line_2,
    city,
    receive_sms,
    state,
    postal_code,
    phone_number: phoneDisplayFormatter(phone_number),
  });
  const initialErrors = reduxState.customer.getIn(['forms', 'update_address_form', 'errors'], {});

  return {
    initialErrors,
    initialValues,
    onSuccessAction,
    previousStepAction,
  };
};

export const ShippingFields: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  inputDefaultClassName?: string;
  setProductPharmacyValid?: (productPharmacyValid: boolean) => void;
}> = ({ disabled, className, labels = true, inputDefaultClassName = 'mt12', setProductPharmacyValid }) => {
  const { values, setFieldValue, setFieldTouched }: FormikContextType<ShippingValues> = useFormikContext();

  const validStates = useAppSelector(selectValidStates);
  const product = useAppSelector((state) =>
    selectors.selectCustomerProduct(state, selectors.selectCurrentIntakeProduct(state)),
  );
  const pharmacyProductName = product?.get('pharmacy_product_name');

  const handleStateSelection = useCallback(
    async (option: string) => {
      if (pharmacyProductName) {
        const response = await axios.get('/api/product_pharmacy/product_pharmacy_availability', {
          params: {
            pharmacy_product_name: pharmacyProductName,
            state: option,
          },
        });

        const { product_pharmacy_available } = response.data;

        if (setProductPharmacyValid) {
          setProductPharmacyValid(Boolean(product_pharmacy_available) && validStates.includes(option));
        }
      }
    },
    [pharmacyProductName, validStates, setProductPharmacyValid],
  );

  return (
    <GoogleAutoCompleteWrapper
      values={values}
      setFieldValue={setFieldValue}
      setFieldTouched={setFieldTouched}
      inputDefaultClassName={inputDefaultClassName}
      disabled={disabled}
      labels={labels}
      className={className}
    >
      <DefaultShippingFields handleStateSelection={handleStateSelection} />
    </GoogleAutoCompleteWrapper>
  );
};

interface IShippingStep {
  dispatch: Dispatch;
  previousStepAction: Action;
  initialValues: ImmutableMap<ShippingValues>;
  initialErrors: Record<keyof ShippingValues, string>;
  onSuccessAction: Action;
}
const ShippingStep = ({
  dispatch,
  previousStepAction,
  initialValues,
  initialErrors,
  onSuccessAction,
}: IShippingStep) => {
  const intakeName = useAppSelector(selectors.selectCurrentIntake)?.get('name');
  const redirectPrevious = () => dispatch(previousStepAction);
  const validStates = useAppSelector(selectors.selectValidStates);
  const customerId = useAppSelector(selectors.selectCustomerId);

  const onSubmit = (values, form) =>
    apiRequestPromiseViaDispatch({
      dispatchFn: dispatch,
      path: '/api/commands',
      body: {
        type: 'update_shipping_address',
        user_id: customerId as string,
        params: {
          ...values,
          phone_number: values.phone_number.replace(/\D/g, ''),
          intake: intakeName,
        },
      },
      onErrorFn: (errors) => {
        Object.entries(errors?.parsedJson?.errors).forEach((entry) => {
          const [key, value] = entry;
          form.setFieldError(key, value as string);
        });
      },
      onSuccessAction,
      form,
    });

  return (
    <Formik initialValues={initialValues.toJS()} validationSchema={ShippingSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting, values, errors, setFieldValue }: FormikProps<ShippingValues>) => (
        <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
          <h3 className="ml-10 sm:ml-5">Shipping Address</h3>
          {values?.state && !validStates.includes(values.state) && <OutOfServiceAlert validStates={validStates} />}
          <Card>
            <Card.Body>
              <>
                {isSubmitting ? (
                  <Spinner isCenter />
                ) : (
                  <>
                    <ShippingFields />
                    <div className="mt12 mt16 mb24">
                      <InputMask
                        id="phone_number"
                        name="phone_number"
                        onKeyUp={() => dispatch(clearAddressFormErrors())}
                        label="Mobile Phone Number"
                        displayFormatter={phoneDisplayFormatter}
                        mask={PHONE_MASK}
                        inputMode="tel"
                        initialError={initialErrors.phone_number}
                      />
                    </div>
                    <SmsConsent
                      onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                      checked={values.receive_sms}
                    />
                  </>
                )}
                <BackNextFooter
                  onNext={handleSubmit}
                  nextDisabled={[!isEmpty(initialErrors), !isEmpty(omit(errors, ['api'])), isSubmitting].some(Boolean)}
                  onBack={redirectPrevious}
                />
              </>
            </Card.Body>
          </Card>
        </PageWrapper>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(ShippingStep);
