import { Button } from 'mui';
import React from 'react';

const ModalBodySubscriptionMonthlyInstallment = ({
  setOpenModalSubscription,
  subscriptionPeriodDays = 30,
  multimonthPlan = 3,
}) => (
  <>
    <p>
      Your plan bills every {subscriptionPeriodDays} days, but as a {multimonthPlan == 3 ? 'quarterly' : 'yearly'} plan,
      you’ll continue to be charged and receive your medication for the full {multimonthPlan == 3 ? 'quarter' : 'year'},
      even if you cancel midway.
    </p>
    <p>
      Plans renew automatically unless paused, modified, or canceled before the end of your current
      {multimonthPlan == 3 ? ' quarterly' : ' annual'} plan.
    </p>
    <p>
      If you are ineligible for treatment, medication costs will be fully refunded, excluding lab work and doctor
      consultation fees.
    </p>
    <p>
      Prices are subject to change and take effect on your next billing cycle. To decline a price change, cancel before
      your current cycle ends.
    </p>
    <p>Cancelations take effect in the next billing cycle once all medication costs are settled.</p>
    <p>
      For full billing details, see{' '}
      <a href="/terms-of-use" target="_blank">
        Terms of Use
      </a>
      .
    </p>
    <hr />
    <Button onClick={() => setOpenModalSubscription(false)}>Close</Button>
  </>
);

export default ModalBodySubscriptionMonthlyInstallment;
