import React from 'react';

type Props = {
  price: number;
  className?: string;
  from?: boolean;
  period?: string;
  showCents?: boolean;
  negative?: boolean;
  free?: boolean;
  size?: string;
};
const PriceFormatter: React.FC<Props> = ({
  price,
  className = '',
  from = false,
  period = null,
  showCents = false,
  negative = false,
  free = false,
  size = 'big',
}) => {
  const showCentsEnabled = (price / 100) % 1 > 0 || showCents;

  const priceClassName = size === 'big' ? 'text-base lg:text-lg' : '';

  return (
    <div className={free ? `generic_payment_page__price_format_free_container ${className}` : className}>
      {from && <div className="text-right text-sm lg:text-base">From</div>}
      <div className={free ? 'generic_payment_page__price_strikethrough' : ''}>
        <div className={priceClassName}>
          <sup>$</sup>
          {negative && <span>&minus;</span>}
          {Math.floor(price / 100)}
          {showCentsEnabled && <sup>{((price / 100) % 1).toFixed(2).replace('1.00', '00').replace('0.', '')}</sup>}
          {period && <span className="font-normal">/{period}</span>}
        </div>
      </div>
      {free && <span className="generic_payment_page__price_label_free">Free</span>}
    </div>
  );
};

export default PriceFormatter;
