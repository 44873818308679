import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Switch, Redirect, Route } from 'react-router';
import { useAppSelector, useAppDispatch } from 'app/helpers/hooks';

import Login from 'app/components/customer/Login/Login';
import PasswordReset from 'app/components/customer/Login/Reset';
import { authPath } from 'app/helpers/utils';
import * as RouteConstants from 'app/constants/Routes';
import Spinner from 'app/components/customer/Spinner';
import { useMarketingTrackingWatcher, trackUserIdentify } from 'app/utils/marketingTracking';

import { selectAuthentication, selectCustomerIsProspect, selectCustomer } from 'app/selectors/customer';
import ApologizePage from 'app/components/apologize/ApologizePage';
import PrivateRoute from './PrivateRoute';
import Registration from './Registration';
import CustomerRoutes from './Customer';

const AdminAsync = React.lazy(() => import('./Admin'));

const ComingSoon = () => <p>Maximus is coming to a state near you soon</p>;
const Goodbye = () => (
  <p className="m16">
    We are sorry to see you go. If you haven&apos;t done so already, we have canceled your subscription. You will not be
    charged starting the next billing cycle and will lose access to the Maximus protocol. We have also started the
    process of removing your information from our systems with the exception of your electronic medical records which we
    will archive in case you need access in the future. Please contact us directly if you need access to them.
  </p>
);
const scrubLocation = () => {
  const { origin, pathname, search } = window.location;
  const u = new URLSearchParams(search);
  u.delete('first_name');
  u.delete('last_name');
  u.delete('notice');
  u.delete('need_sign_in');
  const scrubbedSearch = u.toString();

  const scrubbedURL = `${origin + pathname}?${scrubbedSearch}`;

  return { scrubbedSearch, scrubbedURL };
};

export const Routes = () => {
  const { pathname } = useLocation();
  const isProspect = useAppSelector(selectCustomerIsProspect);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch.admin.setOpenModal('');
  }, []);

  useEffect(() => {
    if (isProspect && window.analytics) {
      const { scrubbedURL, scrubbedSearch } = scrubLocation();
      window.analytics.page(undefined, undefined, { url: scrubbedURL, search: `?${scrubbedSearch}` });
    }
  }, [isProspect, pathname]);

  const authentication = useAppSelector(selectAuthentication);
  const authRoute = authPath(authentication);
  const isAdmin = authentication === 'admin';
  const isCustomer = authentication === 'customer';
  const isStranger = authentication === 'stranger';
  const customer = useAppSelector(selectCustomer);

  useMarketingTrackingWatcher();

  function randomNumberGen() {
    return Math.random().toString(36).substring(2, 10);
  }

  useEffect(() => {
    trackUserIdentify(isCustomer ? customer?.get('id') : '', isCustomer ? customer?.get('email_hash') : '');
    if (window.analytics && isCustomer) {
      const customer_id = customer?.get('id') || window.analytics.user().anonymousId() || randomNumberGen();
      window.analytics.identify(`${customer_id}`);
    }
  }, [customer]);

  return (
    <Suspense fallback={<Spinner isCenter />}>
      <Switch>
        <PrivateRoute
          path={RouteConstants.Dashboard}
          allowed={isCustomer}
          redirectTo={authRoute}
          component={CustomerRoutes}
        />
        <PrivateRoute path={RouteConstants.Admin} allowed={isAdmin} redirectTo={authRoute} component={AdminAsync} />
        <PrivateRoute path={RouteConstants.Login} allowed={isStranger} redirectTo={authRoute} component={Login} />
        <PrivateRoute
          path={RouteConstants.PasswordReset}
          allowed={isStranger}
          redirectTo={authRoute}
          component={PasswordReset}
        />
        <PrivateRoute
          path={RouteConstants.Registration}
          component={Registration}
          redirectTo={authRoute}
          allowed={isStranger}
        />
        <PrivateRoute
          exact
          path={RouteConstants.ComingSoon}
          allowed={isStranger}
          component={ComingSoon}
          redirectTo={authRoute}
        />
        <PrivateRoute
          exact
          path={RouteConstants.Goodbye}
          allowed={isStranger}
          component={Goodbye}
          redirectTo={authRoute}
        />
        <Route path={RouteConstants.Apologize} exact render={() => <ApologizePage />} />
        <Route path={RouteConstants.Root} render={() => <Redirect to={authRoute} />} />
      </Switch>
    </Suspense>
  );
};
