import React from 'react';
import { Formik, Form } from 'formik';
import Input from 'app/components/common/formik/Input';
import { RegistrationSchemaLabAccountDetails } from 'app/helpers/validators';
import { Button, Card, FormSelect } from 'mui';

const SEX_OPTIONS = [
  { value: 'f', label: 'Female' },
  { value: 'm', label: 'Male' },
];

const AccountDataFillStep = ({ initialValues, onSubmit }) => (
  <Formik
    initialValues={{
      ...initialValues,
    }}
    onSubmit={onSubmit}
    validationSchema={RegistrationSchemaLabAccountDetails}
  >
    {({ isSubmitting, errors, values, setFieldValue, isValid }) => (
      <Form>
        <Card>
          <Card.Body>
            <h3 className="title">Create Account</h3>
            <p className="description mb8">
              To keep your health data secure, we’ll need you to create an account. You’ll be able to access your
              analysis with this information.
            </p>

            <Input id="first_name" name="first_name" label="First Name" placeholder="Enter First Name" />
            <Input id="last_name" name="last_name" label="Last Name" placeholder="Enter Last Name" />

            <div className="flex flex-row gap-x-4">
              <Input
                name="date_of_birth"
                label="Date of Birth"
                type="date"
                containerClassName="flex1"
                className="text-xs appearance-none h-[42px] lg:h-[48px]"
              />
              <FormSelect
                options={SEX_OPTIONS}
                label={'Sex at Birth'}
                value={SEX_OPTIONS.find((p) => p.value === values.sex)}
                isSearchable={false}
                onChange={(v) => setFieldValue('sex', v?.value)}
                containerClassName="flex1"
                classNames={{ valueContainer: () => 'form-select-field-input !leading-[26px] lg:!leading-[32px]' }}
              />
            </div>

            <hr />

            <Input
              inputId="password"
              name="password"
              label="Password"
              placeholder="Enter a Password"
              type="password"
              className="text-field"
            />
            <Input
              inputId="password_confirmation"
              name="password_confirmation"
              label="Confirm Password"
              type="password"
              placeholder="Re-enter Password"
              className="text-field"
              error={errors && errors.api}
            />

            <Button
              disabled={isSubmitting || !isValid}
              type="submit"
              data-testid="registration-step-1-submit"
              className="button-next"
            >
              Next
            </Button>
          </Card.Body>
        </Card>
      </Form>
    )}
  </Formik>
);

export default AccountDataFillStep;
