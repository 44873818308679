import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';
import { Button, FormCheckbox } from 'mui';
import { Accordion } from 'mui';

const WarriorSafetyAcknowledgment = ({ handleSubmit, selectOtherTreatments }) => {
  const [risksAcknwledged, setRisksAcknowledged] = useState(false);
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectCustomer);

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  const handleClick = (event) => {
    setRisksAcknowledged(event.target.checked);
  };

  const handleInhibitorsConfirmation = () => {
    dispatch.admin.apiRequestUserCommandWithHandledCatch({
      cmdType: 'answer_questions',
      userId: customer.get('id'),
      params: {
        intake: 'warrior_onboarding',
        ar_inhibitors_confirmation: true,
      },
    });
    handleSubmit();
  };

  const renderFooter = () => (
    <div className="flex flex-col gap-y-6 pt-4">
      <FormCheckbox
        label={
          <p>
            I have read the safety information, and confirm that I will promptly report any adverse effects, changes to
            my medical condition, or changes to medications to the clinical team to ensure my safety and the efficacy of
            my treatment.
          </p>
        }
        onChange={handleClick}
        checked={risksAcknwledged}
        testid="confirm-risk-acknowledgment-checkbox"
      />

      <Button
        type="button"
        onClick={handleInhibitorsConfirmation}
        disabled={!risksAcknwledged}
        data-testid="confirm-risk-acknowledgment"
      >
        Confirm
      </Button>

      <p className="m-auto">
        <button onClick={selectOtherTreatments} className="link bold">
          Explore Other Treatments
        </button>
      </p>
    </div>
  );

  const safetyItems = [
    {
      title: 'Psychological Side Effects',
      content:
        'While most people tolerate dutasteride well, a few individuals have reported mild mood changes, such as feelings of depression or anxiety. It has also been linked to an increase in suicidal thoughts/behaviors. These occurrences are uncommon. If you notice any significant changes in your mood, please consult your healthcare provider.',
      open: false,
    },
    {
      title: 'Sexual Side Effects',
      content:
        'A small number of users may experience sexual side effects, including decreased libido, reduced semen volume, or difficulties with erectile function or ejaculation. These effects are often temporary and tend to diminish with continued use.',
      open: false,
    },
    {
      title: 'Fertility Considerations',
      content:
        '5-alpha reductase inhibitors may temporarily affect semen parameters, such as sperm count or motility, in some individuals. These changes are generally reversible upon discontinuation of the medication. If you are planning to conceive or have concerns about fertility, please discuss this with your healthcare provider to ensure the best approach for your needs.',
      open: false,
    },
    {
      title: 'Other Possible Side Effects',
      content:
        'Some individuals might experience minor scalp-related issues when using topical formulations, such as itching, dryness, or irritation. These symptoms are usually mild and often resolve over time. Rarely, some people may notice breast tenderness or enlargement.',
      open: false,
    },
    {
      title: 'Likelihood of Side Effects',
      content: (
        <p>
          <a href="https://pubmed.ncbi.nlm.nih.gov/27672412/" target="_blank" rel="noreferrer">
            Clinical studies
          </a>{' '}
          indicate that side effects associated with 5-alpha reductase inhibitors are generally uncommon and may
          decrease with continued use. Sexual side effects, if they occur, are more likely during the initial phase of
          treatment and often lessen over time, becoming comparable to those experienced by individuals taking a placebo
          by the second year. Using dutasteride topically may also reduce the likelihood of systemic side effects
          compared to oral forms of either dutasteride or finasteride.
        </p>
      ),
      open: false,
    },
    {
      title: 'What to Do if You Experience Side Effects',
      content:
        'If you notice any side effects, regardless of their severity, inform your healthcare provider. They can guide you on managing symptoms or adjust your treatment plan if necessary. If you experience suicidal thoughts, behaviors, a medical emergency, or an allergic reaction, seek immediate help by contacting emergency services (911) or visiting the nearest emergency room.',
      open: false,
    },
    {
      title: 'References and Further Reading',
      content: (
        <>
          <p>
            You can refer to the FDA prescribing information and the Prescriber{"'"}s Digital Reference (PDR) for
            comprehensive overviews of this class of medication. For more details on studies related to the use and side
            effects from 5-alpha reductase inhibitors, please visit:
          </p>
          <ol className="list-inside list-decimal flex flex-col gap-y-2">
            <li>
              <a href="https://pubmed.ncbi.nlm.nih.gov/27672412/" target="_blank" rel="noreferrer">
                Adverse effects and safety of 5-alpha reductase inhibitors finasteride and dutasteride: A systematic
                review
              </a>
            </li>
            <li>
              <a
                href="https://academic.oup.com/jcem/article-abstract/92/5/1659/2598215"
                target="_blank"
                rel="noreferrer"
              >
                Effect of 5α-Reductase Inhibition with Dutasteride and Finasteride on Semen Parameters and Serum
                Hormones in Healthy Men
              </a>
            </li>
          </ol>
        </>
      ),
      open: false,
    },
  ];

  return (
    <div className="flex flex-col max-h-[80vh] overflow-hidden">
      <div className="flex flex-col pb-2 overflow-y-scroll text-sm lg:text-base">
        <p>
          Please take a moment to review this important safety information regarding dutasteride and finasteride. This
          does not replace discussions with your doctor or healthcare provider about your medical condition or
          treatment.
        </p>
        <hr className="mt-4" />
        <Accordion items={safetyItems} />
      </div>

      {renderFooter()}
    </div>
  );
};

export default WarriorSafetyAcknowledgment;
