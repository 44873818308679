/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';

import Question from 'app/constants/Question';
import { useField, useFormikContext } from 'formik';
import { IntakeRadio } from 'mui';

type SubmitType = boolean | ((current_answer: string) => boolean);

const RadioQuestion = ({
  name,
  submit = true,
  question = null,
}: {
  name: string;
  submit?: SubmitType;
  question?: Question | null;
}) => {
  const questionObj = question ?? new Question(name);
  const [field] = useField({ id: questionObj.name, name: questionObj.name });
  const { value } = field;
  const { setFieldValue, submitForm, isSubmitting } = useFormikContext();
  const [disabled, setDisabled] = useState(false);
  const onSubmit = useCallback(() => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
      submitForm();
    }, 300);
  }, []);

  return (
    <div className="flex flex-col gap-y-4 lg:gap-y-6">
      {questionObj.options?.map((opt) => (
        <IntakeRadio
          key={opt.value as string}
          checked={opt.value === value}
          label={opt.label}
          disabled={isSubmitting}
          onClick={() => {
            if (isSubmitting || disabled) {
              return;
            }
            setFieldValue(questionObj.name, opt.value);

            const resSubmit = submit instanceof Function ? submit(opt.value as string) : submit;

            if (resSubmit) {
              onSubmit();
            }
          }}
          name={questionObj.name}
          testid={`question_${questionObj.name}_option_${opt.value}`}
        />
      ))}
    </div>
  );
};

export default RadioQuestion;
