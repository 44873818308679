import { useEffect } from 'react';
import { CustomerUserImm } from 'app/types/admin/customerUser';

export const useSegmentTracking = (viewEventName?: string, completionEventName?: string) => {
  useEffect(() => {
    if (window.analytics && viewEventName) {
      window.analytics.track(viewEventName, {
        url: window.location.href,
        timestamp: Date.now(),
        e_type: 'viewEvent',
      });
    }
  }, [viewEventName]);

  const trackCompletionEvent = (properties) => {
    if (window.analytics && completionEventName) {
      window.analytics.track(completionEventName, {
        url: window.location.href,
        timestamp: Date.now(),
        e_type: 'completionEvent',
        ...properties,
      });
    }
  };

  return { trackCompletionEvent };
};

export const trackEvent = (eventName: string, properties?: Record<string, any>) => {
  if (window.analytics && eventName) {
    window.analytics.track(eventName, {
      ...properties,
      timestamp: new Date().toISOString(),
    });
  }
};

export const trackCheckoutPage = (protocol: string, customer: CustomerUserImm) => {
  const facebookData = customer?.get('facebook_friendly_user_data_h')?.toJS() || {};

  trackEvent('ViewedCheckoutPageV2', {
    ...facebookData,
    protocol,
  });
};
