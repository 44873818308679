import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router/immutable';
import { withPersonaTemplateId, withPersonaEnv, isTestOrLower } from 'app/helpers/env';
import Persona from 'persona';
import { apiRequestCommand, apiRequestUserCommand } from 'app/actions/customer';
import { useAppSelector } from 'app/helpers/hooks';
import * as selectors from 'app/selectors/customer';

import BackNextFooter from './BackNextFooter';

import Spinner from '../Spinner';

import './css/Identity.scss';
import { Heading, PageWrapper } from 'mui';

const errorMessage =
  "We're experiencing a difficulty with our identity verification mechanism. Please come back later and try again.";
const mapStateToProps = (state) => {
  const prev = selectors.selectPreviousStepPath(state);
  const previousStepAction = push(prev);
  const lastTransaction = selectors.selectCustomerLastWithPersonaTransaction(state);
  const inquiryId = lastTransaction?.get('transaction_id');
  const nextStepAction = push(selectors.selectNextStepPath(state));
  const transaction_status = lastTransaction?.get('status');
  const customer = selectors.selectCustomer(state);
  const isSpinning = isTestOrLower()
    ? false
    : !transaction_status || (customer.get('identity_verification_enabled') && !lastTransaction);

  return {
    inquiryId,
    nextStepAction,
    userId: selectors.selectCustomerId(state),
    isDone: transaction_status === 'completed',
    previousStepAction,
    customer,
    lastTransaction,
    isSpinning,
  };
};

const IdentityVerification = ({
  userId,
  nextStepAction,
  isDone,
  dispatch,
  previousStepAction,
  isSpinning,
  inquiryId,
}) => {
  const redirectPrevious = () => dispatch(previousStepAction);
  const [spinningTooLong, setSpinningTooLong] = useState(false);

  const currentProductName = useAppSelector(selectors.selectCurrentIntakeProduct);
  const onSuccessActionCreator = useCallback(
    (_, stateAfterSuccess) => {
      const path = selectors.selectFurthestProductStepPath({ customer: stateAfterSuccess }, currentProductName);

      return push(path);
    },
    [currentProductName],
  );
  const [withPersonaIframeError, setWithPersonaIframeError] = useState(true);

  useEffect(() => {
    const params = {
      type: 'enable_identity_verification',
      user_id: userId,
      params: {},
    };
    const action = apiRequestCommand({ params });
    dispatch(action);
  }, []);
  useEffect(() => {
    if (isSpinning) {
      const clearId = setTimeout(() => setSpinningTooLong(true), 10000);

      return () => clearTimeout(clearId);
    }
    setSpinningTooLong(false);
  }, [isSpinning]);
  if (isSpinning) {
    if (spinningTooLong) {
      return <p>{errorMessage}</p>;
    }

    return <Spinner isCenter />;
  }

  if (isDone) {
    setTimeout(() => dispatch(nextStepAction), 0);
  }

  const onErrorWithPersona = (err) => {
    if (err.status == 409 && err.code && err.code.match(/unauthenticated/)) {
      const cmdType = 'invalidate_id_verification';
      const params = {
        user_id: userId,
        params: {},
        type: cmdType,
      };
      const action = apiRequestUserCommand({ params, cmdType });
      dispatch(action);
    } else {
      setWithPersonaIframeError(true);
    }
  };

  const onComplete = (transaction_id, status, metadata) => {
    const cmdType = 'with_persona_verify_identity';
    const params = { transaction_id: transaction_id, transaction_metadata: metadata, status: status };
    const action = apiRequestUserCommand({
      params,
      cmdType,
      context: {
        onSuccessActionCreator,
      },
    });
    dispatch(action);
  };

  return (
    <PageWrapper>
      {withPersonaIframeError && (
        <p>
          We{"'"}re initializing the ID verification process. If the load time is too long. Please refresh your page to
          try again OR contact support
        </p>
      )}
      <div className="persona-widget">
        <Heading className="mb-2" headingLevel="p" label>
          Verify ID
        </Heading>
        <h3 className="mb-6">We use Persona to verify your ID. Follow the steps below.</h3>
        <Persona.Inquiry
          {...(isTestOrLower() ? { templateId: withPersonaTemplateId() } : { inquiryId: inquiryId })}
          environmentId={withPersonaEnv()}
          referenceId={userId}
          onLoad={() => setWithPersonaIframeError(false)}
          onComplete={({ inquiryId, status, fields }) => {
            // Inquiry completed. Optionally tell your server about it.
            onComplete(inquiryId, status, JSON.stringify(fields, null, 2));
          }}
          onError={(error) => onErrorWithPersona(error)}
        ></Persona.Inquiry>
      </div>
      <BackNextFooter className="mt-6" noNext onBack={redirectPrevious} />
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(IdentityVerification);
