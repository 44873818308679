import React from 'react';
import { Formik, Form } from 'formik';
import Input from 'app/components/common/formik/Input';
import { IdentitySchema } from 'app/helpers/validators';
import { apiRequestUserCommand } from 'app/actions/customer';
import { useDispatch } from 'react-redux';
import { FormSelect } from 'mui';

const SEX_OPTIONS = [
  { value: 'f', label: 'Female' },
  { value: 'm', label: 'Male' },
];

const PersonalDetailForm = ({ personalFormRef, setPersonalFormValid }) => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params: values,
      context: {},
    });
    dispatch(action);
  };

  return (
    <>
      <h5>Personal Details</h5>
      <Formik
        innerRef={personalFormRef}
        initialValues={{ date_of_birth: null, sex: null }}
        onSubmit={onSubmit}
        validationSchema={IdentitySchema}
      >
        {({ values, setFieldValue, isValid }) => {
          setPersonalFormValid(isValid);
          return (
            <Form>
              <div className="flex flex-row gap-x-4">
                <Input
                  name="date_of_birth"
                  label="Date of Birth"
                  type="date"
                  containerClassName="flex1"
                  className="text-xs appearance-none h-[42px] lg:h-[48px]"
                />
                <FormSelect
                  options={SEX_OPTIONS}
                  label={'Sex at Birth'}
                  value={SEX_OPTIONS.find((p) => p.value === values.sex)}
                  isSearchable={false}
                  onChange={(v) => setFieldValue('sex', v?.value)}
                  containerClassName="flex1"
                  classNames={{ valueContainer: () => 'form-select-field-input !leading-[26px] lg:!leading-[32px]' }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PersonalDetailForm;
