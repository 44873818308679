import LabOnboardingWidget from './LabOnboardingWidget';
import React from 'react';
import PriceCalculator from 'app/helpers/priceCalculator';

type Props = {
  setOpenModalAtHomeLab: any;
  use_own_lab: boolean | null;
  lab_kit_type?: string;
  setFieldValue: any;
  own_lab_file_uploaded: any;
  inputFile: any;
  setOwnLabFile: any;
  setOpenModalOwnLab: any;
  intake: any;
  priceCalculator?: PriceCalculator;
  kingV2OralTrt?: any;
  byolFormSubmitted?: any;
  labSelectionStep?: any;
};
const LabWidget = ({
  setOpenModalAtHomeLab,
  use_own_lab,
  lab_kit_type = '',
  setFieldValue,
  own_lab_file_uploaded,
  inputFile,
  byolFormSubmitted = null,
  setOwnLabFile,
  setOpenModalOwnLab,
  intake,
  priceCalculator,
  kingV2OralTrt,
  labSelectionStep,
}: Props) => (
  <>
    <h5 className="mb-6">{labSelectionStep || kingV2OralTrt ? 'Lab Testing' : 'Baseline At-Home Lab Kit'}</h5>
    {(labSelectionStep || kingV2OralTrt) && (
      <p className="mb-4">
        Your Maximus doctor requires lab testing to get started and again after a month of treatment in order to
        customize your treatment plan.
      </p>
    )}

    <LabOnboardingWidget
      product_name={intake.get('product_name')}
      setOpenModalAtHomeLab={setOpenModalAtHomeLab}
      use_own_lab={use_own_lab}
      lab_kit_type={lab_kit_type}
      priceCalculator={priceCalculator}
      setFieldValue={setFieldValue}
      own_lab_file_uploaded={own_lab_file_uploaded}
      inputFile={inputFile}
      setOwnLabFile={setOwnLabFile}
      setOpenModalOwnLab={setOpenModalOwnLab}
      byolFormSubmitted={byolFormSubmitted}
      kingV2OralTrt={kingV2OralTrt}
      labSelectionStep={labSelectionStep}
    />
  </>
);

export default LabWidget;
