import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik, FormikProps, FormikValues, useFormikContext } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { apiRequestPromise, getCSRF } from 'app/api';
import './css/ListItem.scss';
import {
  selectCustomer,
  selectLastKingIntake,
  selectNextStepPath,
  selectPreviousStepPath,
} from 'app/selectors/customer';
import { isEmpty, pickBy } from 'lodash';
import { useAppSelector } from 'app/helpers/hooks';
import heic2any from 'heic2any';
import { Icon } from '@blueprintjs/core';
import ProgressBar from '@setproduct-ui/core/ProgressBar';
import { push } from 'connected-react-router/immutable';
import { apiRequestSuccess } from 'app/helpers/commandHelpers';
import { Action, Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { CallHistoryMethodAction } from 'connected-react-router';
import { Button, Card, PageWrapper } from 'mui';
import FormInput from 'app/components/common/formik/FormInput';
import { formatDate } from 'app/components/admin/UserPage/utils';
import dayjs from 'dayjs';

const limitations = {
  total_testosterone: { min: 10.0, max: 2000.0 },
  shbg: { min: 1.0, max: 150.0 },
  free_testosterone: { 'pg/mL': { min: 1.0, max: 250.0 }, 'ng/dL': { min: 1.0, max: 100.0 } },
  lh: { min: 0.1, max: 30.0 },
  estradiol: { min: 1.0, max: 150.0 },
  hematocrit: { min: 20.0, max: 65.0 },
  psa: { min: 0.0, max: 8.0 },
  alt: { min: 0.0, max: 100.0 },
  fsh: { min: 1.0, max: 30.0 },
  prolactin: { min: 1.0, max: 30.0 },
  ggt: { min: 0.0, max: 100.0 },
};

const minError = (val) => `Minimum allowed value is ${val}`;
const maxError = (val) => `Maximum allowed value is ${val}`;
const reqError = (attr) => `Please provide ${attr} Value`;
const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

const validationSchema = (isTrt) =>
  Yup.object().shape(
    {
      collected_at: Yup.string()
        .required('Please provide Sample Collection Date')
        .test(
          'not-future-date',
          'The lab test date cannot be in the future. Please enter a valid date.',
          function (value) {
            if (!value) return true; // Let the required validation handle empty values
            const selectedDateStr = formatDate(value, 'YYYY-MM-DD');
            const todayStr = formatDate(dayjs().format('YYYY-MM-DD'), 'YYYY-MM-DD');
            return selectedDateStr <= todayStr;
          },
        ),
      total_testosterone: Yup.lazy(() =>
        Yup.string().when('free_testosterone', (free_testosterone) => {
          const baseConditions = Yup.number()
            .min(limitations.total_testosterone.min, minError(limitations.total_testosterone.min))
            .max(limitations.total_testosterone.max, maxError(limitations.total_testosterone.max));

          if (free_testosterone) {
            return baseConditions;
          } else return baseConditions.required(reqError('Total Testosterone'));
        }),
      ),
      shbg: Yup.lazy(() =>
        Yup.string().when('free_testosterone', (free_testosterone) => {
          const baseConditions = Yup.number()
            .min(limitations.shbg.min, minError(limitations.shbg.min))
            .max(limitations.shbg.max, maxError(limitations.shbg.max));

          if (free_testosterone) {
            return baseConditions;
          } else return baseConditions.required(reqError('SHBG'));
        }),
      ),
      free_testosterone: Yup.string().when(
        ['testosterone_unit', 'total_testosterone', 'shbg'],
        // @ts-expect-error TODO: Check the types
        (testosterone_unit, total_testosterone, shbg) => {
          const baseConditions = Yup.number()
            .min(
              limitations.free_testosterone[testosterone_unit].min,
              minError(limitations.free_testosterone[testosterone_unit].min),
            )
            .max(
              limitations.free_testosterone[testosterone_unit].max,
              maxError(limitations.free_testosterone[testosterone_unit].max),
            );

          if (shbg) {
            return baseConditions;
          } else return baseConditions.required(reqError('Free Testosterone'));
        },
      ),
      lh: Yup.number()
        .required(reqError('LH'))
        .min(limitations.lh.min, minError(limitations.lh.min))
        .max(limitations.lh.max, maxError(limitations.lh.max)),
      estradiol: Yup.number()
        .min(limitations.estradiol.min, minError(limitations.estradiol.min))
        .max(limitations.estradiol.max, maxError(limitations.estradiol.max)),
      hematocrit: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.hematocrit.min, minError(limitations.hematocrit.min))
          .max(limitations.hematocrit.max, maxError(limitations.hematocrit.max));

        if (isTrt) {
          return baseConditions.required(reqError('Hematocrit'));
        } else return baseConditions;
      }),
      psa: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.psa.min, minError(limitations.psa.min))
          .max(limitations.psa.max, maxError(limitations.psa.max));

        if (isTrt) {
          return baseConditions.required(reqError('PSA'));
        } else return baseConditions;
      }),
      alt: Yup.string().when([], () => {
        return Yup.number()
          .min(limitations.alt.min, minError(limitations.alt.min))
          .max(limitations.alt.max, maxError(limitations.alt.max));
      }),
      fsh: Yup.number()
        .min(limitations.fsh.min, minError(limitations.fsh.min))
        .max(limitations.fsh.max, maxError(limitations.fsh.max)),
      prolactin: Yup.number()
        .min(limitations.prolactin.min, minError(limitations.prolactin.min))
        .max(limitations.prolactin.max, maxError(limitations.prolactin.max)),
      ggt: Yup.number()
        .min(limitations.ggt.min, minError(limitations.ggt.min))
        .max(limitations.ggt.max, maxError(limitations.ggt.max)),
    },
    // @ts-expect-error TODO: Check the types
    [['free_testosterone'], ['testosterone_unit', 'total_testosterone', 'shbg']],
  );

const UseOwnLabForm = ({ canSubmit, isTrt }) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const [fileError, setFileError] = useState('');
  const [ownLabFile, setOwnLabFile] = useState<File | null>(null);
  const [showCompleted, setShowCompleted] = useState(false);
  const [ownLabFileIsDeleting, setOwnLabFileIsDeleting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ownLabFileIsLoading, setOwnLabFileIsLoading] = useState(false);
  const [showWrongFileErrorMessage, setShowWrongFileErrorMessage] = useState(false);

  useEffect(() => {
    if (uploadProgress === 1) setShowCompleted(true);
  }, [uploadProgress]);

  useEffect(() => {
    if (ownLabFile) submitOwnLabFile();
  }, [ownLabFile]);

  const customer = useAppSelector(selectCustomer);
  const intake = useAppSelector(selectLastKingIntake)!;
  const intake_name = intake.get('name');
  const productName = intake.get('product_name');

  const inputFile = useRef<HTMLInputElement>(null);
  const handlePhotoUpload = () => inputFile.current?.click();

  const photoPurpose = `${productName}_onboarding_lab`;
  const uploadLabResults = async (params) => {
    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'upload_own_lab_results',
        user_id: customer.get('id'),
        params: {
          ...params,
          intake_name,
          photo_purpose: photoPurpose,
        },
      });
      dispatch(apiRequestSuccess(resp));
    } catch (e: any) {
      setFileError(e.message);
    }
  };

  type AppAction = Action<string> | CallHistoryMethodAction<[string, any?]>;
  type AppDispatch = Dispatch<AppAction>;
  const dispatch = useDispatch<AppDispatch>();

  const nextStepAction = push(useAppSelector(selectNextStepPath));
  const previousStepAction = push(useAppSelector(selectPreviousStepPath));

  const onSubmit = (params) => {
    uploadLabResults(params).then(() => {
      dispatch(nextStepAction);
    });
  };

  const onCancel = () => {
    if (ownLabFile) removeOwnLabfile();
    dispatch(previousStepAction);
  };

  const onImageChange = ({ target }) => {
    const file = target.files?.[0];
    target.value = '';
    if (file) {
      if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 }).then((newImage) => {
          // @ts-expect-error TODO: check the types
          setOwnLabFile(newImage);
        });
      } else if (!allowedTypes.includes(file.type)) {
        setShowWrongFileErrorMessage(true);
        setOwnLabFile(null);
      } else {
        setShowWrongFileErrorMessage(false);
        setOwnLabFile(file);
      }
    }
  };

  const submitOwnLabFile = async () => {
    const raw_params = {
      file: ownLabFile,
      user_id: customer.get('id'),
      intake_name,
      photo_purpose: photoPurpose,
      cmdType: 'upload_own_lab',
      type: 'upload_own_lab',
    };

    const data = new FormData();

    for (const key in raw_params) {
      data.append(key, raw_params[key]);
    }

    setOwnLabFileIsLoading(true);
    setFileError('');

    const onUploadProgress = ({ loaded, total }: { loaded: number; total?: number }) => {
      if (!total) return;
      setUploadProgress(loaded / total);
    };

    await axios
      .post('/api/commands', data, { headers: { 'X-CSRF-Token': getCSRF() }, onUploadProgress })
      .then(() => {
        setOwnLabFileIsLoading(false);
        setFieldValue('own_lab_file_uploaded', true);
      })
      .catch(({ response }) => {
        setFileError(response?.data?.errors?.file);
        setOwnLabFileIsLoading(false);
      });
  };

  const removeOwnLabfile = async () => {
    setOwnLabFileIsDeleting(true);

    await axios
      .post(
        '/api/commands',
        {
          user_id: customer.get('id'),
          intake_name,
          photo_purpose: photoPurpose,
          cmdType: 'delete_own_lab',
          type: 'delete_own_lab',
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      )
      .then(() => {
        setFieldValue('own_lab_file_uploaded', false);
        setFieldValue('own_lab_file', null);
        setFieldValue('own_lab_file_name', null);
        setOwnLabFile(null);
        setOwnLabFileIsDeleting(false);
        setFileError('');
      })
      .catch(() => setOwnLabFileIsDeleting(false));
  };

  const labPrefix = productName === 'king' ? '' : 'magician_';
  const { own_lab_file_name = customer.get(`${labPrefix}own_lab_file_name`) } = values;

  const ownLabFileName = ownLabFile?.name || own_lab_file_name;

  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-8">
      <h4>Required</h4>
      <FormInput
        label="Sample Collection Date"
        name="collected_at"
        data-testid="own-lab-modal-collected-at"
        type="date"
      />
      <FormInput
        label="Total Testosterone"
        name="total_testosterone"
        insideElement={<b className="text-black">ng/dL</b>}
        type="number"
      />
      <FormInput
        label="Sex Hormone Binding Globulin (SHBG)"
        name="shbg"
        insideElement={<b className="text-black">nmol/L</b>}
        type="number"
      />
      {isTrt && (
        <>
          <FormInput
            label="Hematocrit"
            name="hematocrit"
            insideElement={<b className="text-black">%</b>}
            type="number"
          />
          <FormInput
            label="Prostate-Specific Antigen (PSA)"
            name="psa"
            insideElement={<b className="text-black">ng/mL</b>}
            type="number"
          />
        </>
      )}
      <FormInput
        label="Luteinizing Hormone (LH)"
        name="lh"
        data-testid="own-lab-modal-lh"
        insideElement={<b className="text-black">IU/L</b>}
        type="number"
      />
      <div className="display_flex flex-row">
        <FormInput
          label="Free Testosterone (Required if SHBG not provided)"
          name="free_testosterone"
          type="number"
          data-testid="own-lab-free-testosterone"
          insideElement={<b className="text-black">{values.testosterone_unit}</b>}
          containerClassName="flex-1"
        />
        <div className="ml-2 mt-6">
          <span className="display_flex" style={{ alignItems: 'center', marginRight: '10px' }}>
            <label>
              <Field type="radio" name="testosterone_unit" value="ng/dL" className="radio_button_small mr-0" /> ng/dL
            </label>
          </span>
          <span className="display_flex" style={{ alignItems: 'center', marginRight: '10px' }}>
            <label>
              <Field type="radio" name="testosterone_unit" value="pg/mL" className="radio_button_small mr-0" /> pg/mL
            </label>
          </span>
        </div>
      </div>

      <hr />

      <h4>Optional</h4>
      <FormInput
        label="Estradiol (E2) (Optional)"
        name="estradiol"
        type="number"
        data-testid="own-lab-modal-estradiol"
        insideElement={<b className="text-black">pg/mL</b>}
      />
      <FormInput label="Prolactin (Optional)" name="prolactin" insideElement={<b className="text-black">µg/mL</b>} />
      {!isTrt && (
        <FormInput label="Hematocrit (Optional)" name="hematocrit" insideElement={<b className="text-black">%</b>} />
      )}
      <FormInput
        label="Alanine Aminotransferase (ALT) (Optional)"
        name="alt"
        insideElement={<b className="text-black">IU/L</b>}
      />
      {!isTrt && (
        <FormInput
          label="Prostate-Specific Antigen (PSA) Optional"
          name="psa"
          insideElement={<b className="text-black">ng/mL</b>}
        />
      )}
      <FormInput
        label="Follicular Stimulating Hormone (FSH) (Optional)"
        name="fsh"
        insideElement={<b className="text-black">IU/L</b>}
      />
      <FormInput
        label="Gamma-Glutamyl Transferase (GGT) (Optional)"
        name="ggt"
        insideElement={<b className="text-black">IU/L</b>}
      />

      <hr />

      <p>
        By uploading your results, you attest your baseline lab work meets the criteria outlined above, and agree to use
        the same lab vendor and panel for follow-up testing.
      </p>
      <p>
        A member of the Maximus clinical team will review your lab work within 2 business days to ensure lab criteria
        are met.
      </p>
      <div>
        {!ownLabFileName && (
          <Button
            variant="outline"
            data-testid="own-lab-modal-choose"
            onClick={handlePhotoUpload}
            disabled={ownLabFileIsLoading || ownLabFileIsDeleting}
          >
            Upload File
          </Button>
        )}
        <input
          ref={inputFile}
          type="file"
          id="file"
          style={{ display: 'none' }}
          accept="image/*, application/pdf"
          onChange={onImageChange}
        />
        <div>
          {ownLabFile && (
            <>
              <hr className="my-6" />
              <span className="mb-3 mt-6" style={{ color: '#349C00' }}>
                File successfully uploaded
              </span>
              <br />
            </>
          )}
          {ownLabFileName && <span className="color_44 bold lab_upload_paragraph_format">{ownLabFileName}</span>}
          {showWrongFileErrorMessage && (
            <span className="error_message lab_upload_paragraph_format">Only images and pdf are supported</span>
          )}
          {ownLabFileIsLoading && (
            <div className="progress_bar_container">
              {showCompleted && (
                <div className="progress_bar_completed">
                  <Icon icon="tick" size={20} />
                  <span>Completed</span>
                </div>
              )}
              <ProgressBar value={uploadProgress} color="primary" stripes={false} />
            </div>
          )}
          {ownLabFileName && (
            <Button className="mt-3" variant="text" data-testid="own-lab-modal-remove-file" onClick={removeOwnLabfile}>
              Remove file
            </Button>
          )}
        </div>
      </div>
      <hr />
      <Button
        onClick={() => onSubmit(pickBy(values, (p) => p))}
        disabled={!ownLabFileName || ownLabFileIsLoading || ownLabFileIsDeleting || !canSubmit || !!fileError}
        testid="own-lab-modal-confirm"
      >
        Submit
      </Button>
      <Button variant="text" testid="own-lab-modal-close" disabled={ownLabFileIsDeleting} onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
};

interface FormValues {
  collected_at: string;
  total_testosterone: number;
  shbg: number;
  free_testosterone: number;
  free_testosterone_unit: string;
  lh: number;
  fsh: number;
  estradiol: number;
  hematocrit: number;
  psa: number;
  alt: number;
  prolactin: number;
  ggt: number;
}

const UseOwnLabStep = () => {
  const intake = useAppSelector(selectLastKingIntake)! as any;
  const isTrt = intake.get('selected_king_v2_product')?.includes('trt');
  const isOralTrt = intake.get('selected_king_v2_product')?.includes('oral_trt');

  const byolForm = useRef<FormikProps<any> | null>(null);
  const customer = useAppSelector(selectCustomer);
  const lab_orders = customer.get('lab_orders')?.toArray();
  const lab_order = lab_orders.find((order) => order.get('intake_name') === 'onboarding');
  const initialValues: Partial<FormValues> | undefined = lab_order?.get('own_results')?.toJS();

  const {
    collected_at,
    total_testosterone,
    shbg,
    free_testosterone,
    free_testosterone_unit,
    lh,
    fsh,
    estradiol,
    hematocrit,
    psa,
    alt,
    prolactin,
    ggt,
  } = initialValues || {};

  const filteredValues = {
    ...(collected_at !== null && { collected_at }),
    ...(total_testosterone !== null && { total_testosterone }),
    ...(shbg !== null && { shbg }),
    ...(free_testosterone !== null && { free_testosterone }),
    ...(lh !== null && { lh }),
    ...(fsh !== null && { fsh }),
    ...(estradiol !== null && { estradiol }),
    ...(hematocrit !== null && { hematocrit }),
    ...(psa !== null && { psa }),
    ...(alt !== null && { alt }),
    ...(prolactin !== null && { prolactin }),
    ...(ggt !== null && { ggt }),
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <div className="px-4">
        <h3 className="mb-3">Use Your own Labs</h3>
      </div>
      <Card>
        <Card.Body>
          <h4>Read carefully</h4>
          <p>You may use your own lab work if it meets the following criteria:</p>
          <ul className="disc-list">
            <li>Labs must include Total Testosterone, LH, & SHBG (or Free Testosterone).</li>
            <li>Must have been collected within the last six months.</li>
          </ul>
          {isOralTrt && (
            <>
              <p>(Oral TRT & Oral TRT+) Labs must also include Hematocrit & Prostate-Specific Antigen (PSA).</p>
              <p>
                (Oral TRT+ only) Must have been collected at least two weeks after stopping Testosterone Replacement
                Therapy (TRT), Anabolic Steroids, SARMs, & SERMS.
              </p>
            </>
          )}
          <p>
            You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 30 days
            after starting treatment, or as required by your licensed Maximus doctor).
          </p>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Formik
            innerRef={(formik) => (byolForm.current = formik)}
            validationSchema={validationSchema(isTrt)}
            validateOnMount={true}
            validateOnBlur={true}
            onSubmit={() => {}}
            initialValues={{
              ...filteredValues,
              testosterone_unit: free_testosterone_unit || 'ng/dL',
            }}
            initialTouched={{
              collected_at: !!collected_at,
              total_testosterone: !!total_testosterone,
              shbg: !!shbg,
              free_testosterone: !!free_testosterone,
              lh: !!lh,
              fsh: !!fsh,
              estradiol: !!estradiol,
              hematocrit: !!hematocrit,
              psa: !!psa,
              alt: !!alt,
              prolactin: !!prolactin,
              ggt: !!ggt,
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <UseOwnLabForm canSubmit={!isEmpty(touched) && isEmpty(errors)} isTrt={isTrt} />
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default UseOwnLabStep;
