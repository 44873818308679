import { useCallback } from 'react';

export const useLiveChat = () => {
  const openLiveChat = useCallback(() => {
    try {
      window.Intercom('showSpace', 'messages');
    } catch (error) {
      console.warn("Intercom can't open:", error);
    }
  }, []);

  return { openLiveChat };
};
