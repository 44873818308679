import React from 'react';
import { useFormikContext } from 'formik';
import {
  LOVER_SUBSCRIPTION_MONTHLY_COST,
  LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2,
  LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V1,
  LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V2,
} from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import { AvailableProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';
import { useExperiment } from 'app/utils/useExperiment';

const LoverMultimonthPlanSelectionForm = () => {
  const { values } = useFormikContext<{
    number_of_doses: number;
    daily_strength: string;
  }>();

  const bloodflow_variant_2 =
    useExperiment('bloodflow_multimonth', 'bloodflow_multimonth_variation_0') === 'bloodflow_multimonth_variation_2';
  const bloodflow_experiment_pricing_q1_25 = useExperiment(
    'bloodflow_pricing_q1_25',
    'bloodflow_pricing_q1_25_variation_0',
  );

  let priceConfig = bloodflow_variant_2 ? LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2 : LOVER_SUBSCRIPTION_MONTHLY_COST;

  switch (bloodflow_experiment_pricing_q1_25) {
    case 'bloodflow_pricing_q1_25_variation_1':
      priceConfig = LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V1;
      break;
    case 'bloodflow_pricing_q1_25_variation_2':
      priceConfig = LOVER_SUBSCRIPTION_MONTHLY_COST_EXP_V2;
      break;
    default:
      // Keep the initial pricing based on bloodflow_multimonth experiment
      break;
  }

  const selectedSubProduct = values.daily_strength || values.number_of_doses?.toString();

  const multimonthConfig = [
    {
      value: 12,
      specialLabel: 'Best Value',
      subheader: 'Bills every 12 months',
      title: 'Yearly Plan',
    },
    {
      value: 3,
      specialLabel: 'Most Popular',
      subheader: 'Bills every 3 months',
      title: 'Quarterly Plan',
    },
    {
      value: 1,
      subheader: 'Bills every month',
      title: 'Monthly Plan',
    },
  ];

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.Lover}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={multimonthConfig}
      priceConfig={priceConfig}
    />
  );
};

export default LoverMultimonthPlanSelectionForm;
