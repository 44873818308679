import React, { useMemo } from 'react';
import { push } from 'connected-react-router/immutable';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Routes from 'app/constants/Routes';
import { apiRequestUserCommand } from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { TreatmentGoingSchema } from 'app/helpers/validators';
import { Badge, Card, PageWrapper, ProgressBar, Button } from 'mui';
import protocolNames from 'app/utils/protocolNames';
import Question from 'app/constants/Question';
import { SanitizeHTML } from 'app/utils';
import TextArea from 'app/components/common/formik/TextArea';

const RefillCheckpoint = ({ dispatch, intake, onSuccessAction, position, total, productName }) => {
  const onSubmit = (params) => {
    const name = 'treatment_going';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessAction, form: name },
    });
    dispatch(action);
  };
  const initialValues = useMemo(() => ({ treatment_going: '' }), []);
  const refillCheckpointQuestion = useMemo(() => new Question('freeform_on_follow_up'), []);

  return (
    <PageWrapper>
      <Card>
        <Card.Body>
          <ProgressBar progress={(100 * position) / total} />
          <div className="flex flex-row justify-between">
            <Badge variant={productName}>{protocolNames[productName]}</Badge>
          </div>
          <p>Optional question or comment</p>
          <p>
            <strong>{refillCheckpointQuestion.sentence}</strong>
          </p>
          {!!refillCheckpointQuestion.data.description && (
            <p className="text-sm">
              <SanitizeHTML html={refillCheckpointQuestion.data.description} />
            </p>
          )}
          <Formik validationSchema={TreatmentGoingSchema} initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue, isValid, dirty }) => (
              <>
                <TextArea
                  data-testid="freeform_on_follow_up"
                  name="treatment_going"
                  id="treatment_going"
                  placeholder="Add your note to the doctor here..."
                  onChange={({ currentTarget: { value: content } }) => {
                    setFieldValue('treatment_going', content);
                  }}
                />
                <div className="centered">
                  <Button onClick={() => handleSubmit()} disabled={!isValid || !dirty} testid="next-step">
                    Submit
                  </Button>
                  <Button variant="text" testid="cancel" onClick={() => onSubmit(initialValues)} className="mt-4">
                    Skip
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  const productName = selectors.selectCurrentIntakeProduct(state);
  const onSuccessAction = push(Routes.ProductDashboard(productName));
  const previousStepAction = push(selectors.selectPreviousStepPath(state));

  return {
    intake: selectors.selectCurrentIntake(state),
    onSuccessAction,
    previousStepAction,
    productName,
  };
};

export default connect(mapStateToProps)(RefillCheckpoint);
