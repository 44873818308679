import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import Input from 'app/components/common/formik/Input';
import { RegistrationSchemaStep4 } from 'app/helpers/validators';
import { phoneDisplayFormatter } from 'app/helpers/formatters';
import { useSegmentTracking } from 'app/utils/useSegmentTracking';
import { SEGMENT_KING_EVENTS } from 'app/constants/SegmentEvents';
import { Button, Card, Heading } from 'mui';
import { push } from 'connected-react-router/immutable';

const AccountRegistrationStep = ({ initialValues, emailExist, onSubmit, trackSegmentEvent = false }) => {
  useSegmentTracking(trackSegmentEvent ? SEGMENT_KING_EVENTS.VIEWED_ACCOUNT_CREATION : undefined);

  const dispatch = useDispatch();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={RegistrationSchemaStep4}>
      {({
        isSubmitting,
        errors,
        setFieldValue,
      }: {
        isSubmitting: boolean;
        errors: {
          api?: string;
        };
        setFieldValue: (field: string, value: any) => void;
      }) => (
        <Form>
          <Card>
            <Card.Body>
              <div>
                <Heading headingLevel="h3" className="mb-2">
                  Create an account
                </Heading>
                <p>Set up your Maximus login now.</p>
              </div>

              <div className="flex flex-col gap-y-2">
                <Input
                  onChange={(e) => setFieldValue('email', e.target.value.trim())}
                  id="email"
                  label="Email Address"
                  name="email"
                  placeholder="Email (used as username)"
                  className="text-field"
                  disabled={emailExist}
                  errorText={
                    emailExist && (
                      <>
                        This email already exists. Would you like to <Link to={Routes.Login}>Log in</Link>?
                      </>
                    )
                  }
                />
                <Input
                  id="phone_number"
                  label="Phone Number"
                  name="phone_number"
                  placeholder="Phone (optional)"
                  type="tel"
                  onChange={(e) => setFieldValue('phone_number', phoneDisplayFormatter(e.target.value))}
                  className="text-field text-field__phone_number"
                  disabled={emailExist}
                />
              </div>
              <hr />
              <div
                className={cx('flex flex-col gap-y-2', {
                  hidden: emailExist,
                })}
              >
                <Input
                  id="password"
                  label="Password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  className="text-field"
                  disabled={emailExist}
                />
                <Input
                  id="password_confirmation"
                  label="Confirm Password"
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirm Password"
                  className="text-field"
                  disabled={emailExist}
                />
              </div>

              {errors && errors.api && (
                <div className="border border-main-danger text-main-danger px-4 py-2 rounded-xl">{errors.api}</div>
              )}

              {emailExist ? (
                <Button onClick={() => dispatch(push(Routes.Login))}>Log In</Button>
              ) : (
                <>
                  <Button disabled={isSubmitting} type="submit" data-testid="registration-step-4-submit" text="Next">
                    Next
                  </Button>

                  <div className="links">
                    <div className="links__item">
                      Already have an account? <Link to={Routes.Login}>Log In</Link>
                    </div>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default AccountRegistrationStep;
