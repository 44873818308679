import React, { useState } from 'react';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySubscription from 'app/components/customer/Checkout/ModalBodySubscription';
import PriceCalculator from 'app/helpers/priceCalculator';

const BILLED_EVERY_INFO = {
  1: 'month',
  3: '3 months',
  12: '12 months',
};

const BILLED_EVERY_INFO_SHORT = {
  1: 'mo',
  3: '3 mos',
  12: 'year',
};

const MODAL_DAYS_MAPPING = {
  1: 30,
  3: 90,
  12: 360,
};

type Props = {
  multimonthPlan: number;
  nextBillingCycle: string;
  isOnboarding: boolean;
  productChanged: boolean;
  priceCalculator: PriceCalculator;
};

const GenericSubscriptionSummaryKingV2 = ({
  isOnboarding,
  nextBillingCycle,
  multimonthPlan,
  priceCalculator,
  productChanged,
}: Props) => {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const subscriptionPeriodDays = MODAL_DAYS_MAPPING[multimonthPlan];

  return (
    <>
      <div className="bg-main-grey rounded-xl p-4 lg:p-8">
        <div className="price_row mb-4 lg:mb-8">
          <div>
            <h5>Subscription Cost</h5>
          </div>
          <PriceFormatter price={priceCalculator.subscriptionCost()} period={BILLED_EVERY_INFO_SHORT[multimonthPlan]} />
        </div>
        {isOnboarding && (
          <div className="text-xs">
            <p className="pb-4">
              Your subscription bills upfront and auto-renews every {BILLED_EVERY_INFO[multimonthPlan]} (
              {subscriptionPeriodDays} days)* unless a cancelation request is placed before the end of the billing
              cycle.
            </p>
            <p>Medication ships monthly to allow for treatment customization.</p>
            <p>
              <button className="link" onClick={() => setOpenModalSubscription(true)}>
                *Full Details
              </button>
            </p>
          </div>
        )}
        {!isOnboarding && (
          <>
            <p className="mb-3">
              Your new subscription cost will start on your <b>next billing cycle, {nextBillingCycle}</b>, and will
              auto-renew every {BILLED_EVERY_INFO[multimonthPlan]} ({subscriptionPeriodDays} days).
              <br />
              <button className="link" onClick={() => setOpenModalSubscription(true)}>
                Full subscription details
              </button>
              .
            </p>
            {productChanged && (
              <div>
                <p>
                  <b>When will I start receiving my new product choice?</b>
                </p>
                <p className="mb-3">
                  You will start receiving your new product choice after your next billing cycle once your subscription
                  is updated.
                </p>
                <p>
                  If you’d like to start receiving your <b>new product choice</b> sooner, you must contact the Maximus
                  concierge team to have your current subscription updated prior to your next billing cycle.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="How Your Subscription Works"
        text={
          <ModalBodySubscription
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={subscriptionPeriodDays}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default GenericSubscriptionSummaryKingV2;
