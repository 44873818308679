import React from 'react';
import { useField, useFormikContext } from 'formik';
import mapValues from 'lodash/mapValues';
import './css/MultiSelect.scss';
import { IntakeCheckbox, FormField } from 'mui';

const NoneOfTheAbove = ({ noneOfTheAboveText, noneOfTheAboveClicked, isSubmitting, value }) => (
  <IntakeCheckbox
    onChange={() => noneOfTheAboveClicked()}
    label={noneOfTheAboveText}
    testid="checkbox_none_of_the_above"
    checked={value?.none_of_the_above}
    disabled={isSubmitting}
  />
);

const MultiSelectQuestion = ({ question, showNoneOfTheAbove = true, showOther = true, ...restProps }) => {
  const { id, name, options } = question;

  const noneOfTheAboveText = question.data.none_of_the_above?.text;
  const noneOfTheAbovePosition = question.data.none_of_the_above?.position;
  const otherTextContent = question.data.other ? question.data.other?.text : 'Other (please list)';
  if (question.data.no_other) {
    showOther = false;
  }
  const [{ value: fieldVal }] = useField({ id, name });
  const value = fieldVal || {};
  const { setFieldValue, isSubmitting } = useFormikContext();
  const questionOptions = options.map((option, index) => {
    const checked = value[option.value];
    const onOptionChanged = () => {
      let newValues = value;
      if (option.no_other) {
        newValues = mapValues(value, (v) => (v === true ? false : v));
      }
      options
        .filter((o) => !!o.no_other)
        .forEach((v) => {
          newValues[v.value] = false;
        });
      const val = {
        ...newValues,
        [option.value]: !checked,
      };
      if (noneOfTheAboveText) {
        val.none_of_the_above = false;
      }

      setFieldValue(name, val);
    };

    return (
      <IntakeCheckbox
        key={index}
        label={[option.label, option.sublabel].join('\n')}
        onChange={onOptionChanged}
        checked={checked}
        testid={`checkbox_${option.value}`}
        disabled={isSubmitting}
        {...restProps}
      />
    );
  });
  const otherClicked = () => {
    const nextOther = !value.other;
    const overrides = nextOther ? { none_of_the_above: false } : { other_content: '' };
    setFieldValue(name, { ...value, ...overrides, other: nextOther });
  };
  const noneOfTheAboveClicked = () => {
    const falseValues = mapValues(value, () => false);
    if (!question.data.no_other) {
      (falseValues.other_content as unknown) = '';
    }
    setFieldValue(name, {
      ...falseValues,
      none_of_the_above: !value.none_of_the_above,
    });
  };
  const textarea = (
    <FormField
      multiline
      inputClassName="min-h-[5rem]"
      testid="other-textarea"
      value={value.other_content}
      placeholder="Enter text here"
      onChange={({ target: { value: content } }) => {
        setFieldValue(name, { ...value, other_content: content });
      }}
    />
  );

  return (
    <div className="flex flex-col gap-4">
      {noneOfTheAbovePosition === 'top' && showNoneOfTheAbove && !!noneOfTheAboveText && (
        <NoneOfTheAbove
          noneOfTheAboveClicked={noneOfTheAboveClicked}
          noneOfTheAboveText={noneOfTheAboveText}
          value={value}
          isSubmitting={isSubmitting}
        />
      )}
      {questionOptions}
      {noneOfTheAbovePosition !== 'top' &&
        noneOfTheAbovePosition !== 'bottom' &&
        showNoneOfTheAbove &&
        !!noneOfTheAboveText && (
          <NoneOfTheAbove
            noneOfTheAboveClicked={noneOfTheAboveClicked}
            noneOfTheAboveText={noneOfTheAboveText}
            value={value}
            isSubmitting={isSubmitting}
          />
        )}
      {showOther && !!otherTextContent && (
        <IntakeCheckbox
          label={otherTextContent}
          checked={value?.other}
          testid="checkbox_other"
          onChange={() => otherClicked()}
          disabled={isSubmitting}
        />
      )}
      {showOther && value?.other && textarea}
      {noneOfTheAbovePosition === 'bottom' && showNoneOfTheAbove && !!noneOfTheAboveText && (
        <NoneOfTheAbove
          noneOfTheAboveClicked={noneOfTheAboveClicked}
          noneOfTheAboveText={noneOfTheAboveText}
          value={value}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default MultiSelectQuestion;
