import React from 'react';

export const InjectableTestosteroneInfo = ({ withoutTitle = false }) => (
  <>
    {!withoutTitle && <h6 className="underline mt-2 mb-4">Injectable Testosterone</h6>}

    <p className="bold">What is Injectable Testosterone?</p>
    <p className="mb-3">Injectable testosterone is a prescription medicine used to improve symptoms of hypogonadism.</p>
    <p className="bold">Who should not use Injectable Testosterone?</p>
    <p className="mb-3">
      Do not use injectable testosterone if you:
      <ul className="disc-list">
        <li>Are allergic to testosterone injections or any of their ingredients.</li>
        <li>Have or might have prostate cancer.</li>
        <li>Are under 18 years of age.</li>
      </ul>
    </p>

    <p className="bold">What should I tell my healthcare provider before using Injectable Testosterone?</p>
    <p className="mb-3">
      Before you use injectable testosterone, tell your healthcare provider if you:
      <ul className="disc-list">
        <li>Have heart disease, high blood pressure, or high cholesterol.</li>
        <li>Have kidney or liver problems.</li>
        <li>Have diabetes.</li>
        <li>Have breathing problems during sleep (sleep apnea).</li>
        <li>Have any other medical conditions.</li>
      </ul>
      Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
      medicines, vitamins, and herbal supplements.
    </p>

    <p className="bold">How should I use Injectable Testosterone?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Use injectable testosterone exactly as prescribed by your healthcare provider.</li>
        <li>The injection is usually administered into a muscle by a healthcare professional.</li>
        <li>Follow all instructions for safe injection if self-administering.</li>
      </ul>
    </p>

    <p className="bold">What are the possible side effects of Injectable Testosterone?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Common side effects include:</li>
        <li>Increased red blood cell count (hematocrit).</li>
        <li>Acne</li>
        <li>Hair Loss</li>
        <li>Mood changes, including irritability or aggression.</li>
        <li>Slight fluid retention or swelling.</li>
        <li>Pain or irritation at the injection site.</li>
        <li>Reduced fertility and testicular function.</li>
      </ul>
      <ul className="disc-list">
        <li>Serious side effects include:</li>
        <li>Blood clots in the legs or lungs.</li>
        <li>Enlarged prostate or worsening of urinary symptoms.</li>
        <li>Liver problems.</li>
        <li>Heart problems, including heart attack or stroke.</li>
        <li>Allergic reactions.</li>
      </ul>
    </p>

    <p className="bold">Seek emergency medical attention if you experience:</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Signs of a blood clot, such as leg pain, swelling, warmth, or redness.</li>
        <li>Chest pain or pressure</li>
        <li>Sudden shortness of breath</li>
        <li>Sudden weakness or numbness</li>
      </ul>
    </p>

    <p className="bold">What else should I know about Injectable Testosterone?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Regular monitoring of blood tests is necessary.</li>
        <li>Inform your healthcare provider about all other medications you are taking.</li>
        <li>Discuss any concerns regarding fertility with your healthcare provider.</li>
      </ul>
    </p>

    <p className="bold">How should I store Injectable Testosterone?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Store according to the instructions provided by your pharmacist or healthcare provider.</li>
        <li>Keep out of reach of children.</li>
      </ul>
    </p>

    <p className="bold">General information about the safe and effective use of Injectable Testosterone</p>
    <p className="mb-3">
      Do not use injectable testosterone for a condition for which it was not prescribed. Do not share this medication
      with others.
    </p>
  </>
);
