import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch } from 'app/helpers/hooks';
import { DangerDialog } from 'app/components/common/Dialog';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';
import { push } from 'connected-react-router/immutable';
import { Button, Card, CardBody, Modal, PageWrapper } from 'mui';
import DumbShippingCard from './steps/Payment/DumbShippingCard';
import { ShippingSchemaWithoutFullName } from '../../helpers/validators';
import { Formik } from 'formik';
import { useAppSelector } from '../../helpers/hooks';
import { selectCustomerShippingAddress } from '../../selectors/customer';
import DumbPaymentMethodNew from './steps/Payment/DumbPaymentMethodNew';
import PaymentContextProvider from './steps/Payment/PaymentContext/provider';
import { PasswordPreferencesForm } from './dashboard/PasswordPreferences';

const mapStateToProps = (state) => {
  const customerId = selectors.selectCustomerId(state);
  const deleteContext = {
    onSuccessAction: actions.hardRedirectRequested('/goodbye'),
  };
  const uncancelParams = {
    type: 'uncancel_account',
    user_id: customerId,
    params: {},
  };
  const deleteAction = actions.apiRequestUserCommand({
    params: {},
    cmdType: 'delete_customer',
    context: deleteContext,
  });
  const uncancelAction = actions.apiRequestCommand({
    params: uncancelParams,
    context: {},
  });

  return {
    deleteAction,
    uncancelAction,
  };
};

const mapDispatchToProps = {
  redirect: push,
};

const DELETE_TITLE = 'Delete Account';
const DELETE_TEXT = 'Are you sure you want to delete your account?';
const Settings = ({ deleteAction, redirect }) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openPasswordChangeModal, setOpenPasswordChangeModal] = useState(false);

  const dispatch = useAppDispatch();
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const toggleDeleteModal = () => {
    setOpenDelete(!openDelete);
  };
  const togglePasswordChangeModal = () => {
    setOpenPasswordChangeModal(!openPasswordChangeModal);
  };

  const handleDeleteClick = () => {
    setDeleteButtonDisabled(true);
    dispatch(deleteAction);
  };

  const shippingAddress = useAppSelector(selectCustomerShippingAddress)?.toJS() || {};
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress;
  const initialValues = {
    ...shippingAddress,
    address_line_1,
    address_line_2,
    city,
    state,
    postal_code,
  };

  delete initialValues.full_name;

  return (
    <PageWrapper withDesktop={false}>
      <div className={'flex flex-col gap-6'}>
        <h3 className="ml-10 sm:ml-5">Account Settings</h3>
        {shippingAddress && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={() => {}}
            validationSchema={ShippingSchemaWithoutFullName}
          >
            <DumbShippingCard />
          </Formik>
        )}
        <Card>
          <CardBody>
            <h4>Payment method</h4>
            <PaymentContextProvider>
              <DumbPaymentMethodNew />
            </PaymentContextProvider>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4>Account</h4>
            <Button variant={'outline'} onClick={() => redirect(Routes.ManageSubscription)}>
              Manage Subscriptions
            </Button>
            <Button variant={'outline'} onClick={togglePasswordChangeModal}>
              Change Password
            </Button>
            <Button variant={'text'} onClick={toggleDeleteModal}>
              Delete Account
            </Button>
            <DangerDialog
              isOpen={openDelete}
              title={DELETE_TITLE}
              text={DELETE_TEXT}
              leftDisabled={deleteButtonDisabled}
              onClose={toggleDeleteModal}
              leftText="Delete"
              leftClick={handleDeleteClick}
              rightText="Cancel"
              rightClick={toggleDeleteModal}
              leftButton
              rightButton
            />
            <Modal isOpen={openPasswordChangeModal} onClose={togglePasswordChangeModal} showCloseIcon={true}>
              <PasswordPreferencesForm customOnCancel={togglePasswordChangeModal} />
            </Modal>
            <Modal isOpen={openDelete} onClose={toggleDeleteModal} showCloseIcon={false}>
              <Modal.Header>
                <h3 className="lg:hidden">Delete Account</h3>
                <h5 className="hidden lg:block lg:visible">Delete Account</h5>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                <Button variant={'primary'} color="danger" onClick={handleDeleteClick}>
                  Delete
                </Button>
                <Button variant={'outline'} onClick={toggleDeleteModal}>
                  Cancel
                </Button>
              </Modal.Body>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </PageWrapper>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
