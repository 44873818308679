import React from 'react';
import '../css/ProductSelection.scss';
import { useFormikContext } from 'formik';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { Badge, Card, Button } from 'mui';
import Radio from 'app/components/common/Radio';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { MAGICIAN_SUBSCRIPTION_MONTHLY_COST } from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import assetLibrary from 'app/utils/assetLibrary';

type Props = {
  firstTimeChoice?: boolean;
  handleSubmit?: () => void;
  changeProductHandler?: ((kingProductType: string, setFieldValue: any) => void) | null;
  multimonth_plan?: number;
  starter_pack: boolean;
};

const MagicianProductSelectionForm = ({
  firstTimeChoice = true,
  changeProductHandler = null,
  handleSubmit = undefined,
  multimonth_plan = 3,
  starter_pack = false,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    drug_variant: string;
    strength_variant: string;
  }>();

  if (!firstTimeChoice && starter_pack) multimonth_plan = 3;

  const handleChange = (value: string) => {
    setFieldValue('drug_variant', value);

    const strenghtVariant = value === MagicianSubProducts.Semaglutide ? '0_25mg' : '2_5mg';
    setFieldValue('strength_variant', strenghtVariant);

    if (changeProductHandler) {
      changeProductHandler(value, setFieldValue);
    }
  };

  const subProductConfigPrice = (product) => {
    if (starter_pack) {
      return MAGICIAN_SUBSCRIPTION_MONTHLY_COST[product]['starter_pack'];
    } else {
      return MAGICIAN_SUBSCRIPTION_MONTHLY_COST[product][multimonth_plan];
    }
  };

  const subProductsConfig = [
    {
      name: MagicianSubProducts.Semaglutide,
      price: subProductConfigPrice(MagicianSubProducts.Semaglutide),
      description:
        'Unlock a slimmer you with Semaglutide - proven to help shed an average of 16.9% body weight in 68 weeks.',
    },
    {
      name: MagicianSubProducts.Tirzepatide,
      price: subProductConfigPrice(MagicianSubProducts.Tirzepatide),
      description:
        'Discover the most effective weight loss tool on the market. People using Tirzepatide lose more weight than any other GLP-1 product. Tirzepatide patients lose on average 22.5% of body weight in 72 weeks.',
    },
  ];

  const renderRadio = (selectedSubProduct, subProduct, showDivider) => (
    <>
      <Radio
        className="no-borders top-aligned"
        onChange={() => handleChange(subProduct.name)}
        checked={selectedSubProduct === subProduct.name}
        disabled={subProduct.notAvailableInState}
        testId={`drug-variant-${subProduct.name}`}
        label={
          <div className="-mt-6 lg:-mt-5">
            <div className="flex flex-row justify-between items-end">
              <h5>{subProductNames[AvailableProducts.Magician][subProduct.name]}</h5>
              <PriceFormatter
                period={starter_pack ? `${Math.abs(multimonth_plan)}mo` : 'mo'}
                from
                price={subProduct.price}
              />
            </div>
            <p className="mt-2 lg:mt-3 text-sm lg:text-base">{subProduct.description}</p>
          </div>
        }
      />
      {showDivider && <hr />}
    </>
  );

  const image = assetLibrary[`prod-magician-${starter_pack ? 'starter' : values.drug_variant}`];

  return (
    <Card>
      <Card.Body>
        <figure className="h-[180px] lg:h-[240px] mb-4">
          <img className="h-full w-auto" src={image} alt="Product" />
        </figure>

        <div className="text-center mt-4">
          <Badge variant={AvailableProducts.Magician} className="mb-2">
            {protocolNames[AvailableProducts.Magician]}
          </Badge>
        </div>

        {firstTimeChoice && starter_pack && (
          <div className="mb-3">
            <h5 className="mb-2">Starter Pack</h5>
            <p className="text-sm mb-6">
              Choose either Semaglutide or Tirzepatide GLP-1 starter pack for 3 months of medication for one low price.
            </p>
            <hr />
          </div>
        )}

        {subProductsConfig.map((subProduct, index) =>
          renderRadio(values.drug_variant, subProduct, index !== subProductsConfig.length - 1),
        )}
        {handleSubmit && (
          <>
            <hr />

            <Button onClick={handleSubmit} data-testid="submit-product-selection">
              Continue
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default MagicianProductSelectionForm;
