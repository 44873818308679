import React from 'react';
import { AvailableProducts, LabKitTypes } from 'app/constants/Products';
import { apiRequestUserCommand } from 'app/actions/customer';
import { useAppDispatch } from 'app/helpers/hooks';
import { push } from 'connected-react-router/immutable';
import { selectFurthestProductStepPath } from 'app/selectors/customer';
import { Button, Card } from 'mui';
import LabImage from 'images/LabImage.png';
import { useFormikContext } from 'formik';

type Props = {
  setFieldValue: any;
  setOpenModalAtHomeLab: any;
  kingV2OralTrt?: boolean;
  setOpenModalOwnLab?: any;
  labSelectionStep: boolean;
};

const KingLabWidget = ({ setFieldValue, setOpenModalAtHomeLab, setOpenModalOwnLab, labSelectionStep }: Props) => {
  const dispatch = useAppDispatch();
  const { handleSubmit } = useFormikContext();

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, AvailableProducts.King));

  const handleOwnLabClick = () => {
    setFieldValue('lab_kit_type', LabKitTypes.UseYourOwnLab);
    setFieldValue('use_own_lab', true);

    if (!labSelectionStep) {
      setOpenModalOwnLab(true);
    } else {
      const action = apiRequestUserCommand({
        cmdType: 'lab_selection_opt_in',
        params: {
          lab_kit_type: LabKitTypes.UseYourOwnLab,
          product_name: AvailableProducts.King,
          use_own_lab: true,
        },
        context: { onSuccessActionCreator },
      });
      dispatch(action);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img className="h-full w-auto" src={LabImage} alt="king v2" />
          </figure>
          <h4>At-Home Lab Kit</h4>
          <p>
            Maximus uses CLIA-certified lab tests that ship straight to your door. The tests are painless and do not
            require finger-pricking. Results are available FAST (typically within four business days).
          </p>
          <ul className="disc-list">
            <li>Free Priority Shipping to you</li>
            <li>Free Overnight Shipping to lab </li>
            <li>Results typically within 24 hours</li>
          </ul>
          <div>
            <button className="link" onClick={() => setOpenModalAtHomeLab(LabKitTypes.AtHomeLabKit)}>
              Full details
            </button>
          </div>
          <Button className="mt-4" onClick={handleSubmit} testid="submit-lab-selection">
            Next
          </Button>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Already have labs?</h4>
          <div>
            If you have lab work completed within the past 6 months and meet the clinical requirements, you may use your
            own lab results instead.
          </div>
          <Button variant="outline" onClick={handleOwnLabClick} data-testid="use-own-lab">
            Use Own Labs
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default KingLabWidget;
