import { AvailableProducts, LabKitTypes } from 'app/constants/Products';
import { ONBOARDING_TYPES } from 'app/selectors/customer';
import dayjs from 'dayjs';

type HomePageUpNextItem = { title: string; description: string; cta?: string };

const homePageNextItems = (customer, productName: string | null): HomePageUpNextItem[] => {
  const product = customer.getIn(['maximus_products', productName]);
  const currentIntake = product.get('intakes').last();
  const patientPortalRegisteredAt = customer.get('questionnaire').get('patient_portal_registered_at');
  const labOrderResults = product.get('lab_order_results');
  const isKing = productName === AvailableProducts.King;
  const isMagician = productName === AvailableProducts.Magician;
  const isWarrior = productName === AvailableProducts.Warrior;
  const isLover = productName === AvailableProducts.Lover;
  const isLaas = customer.get('only_lab');
  const hasLabResults = labOrderResults && labOrderResults.size > 0;
  const pendingAppointmentSchedule = customer.get('pending_appointment_schedule');
  const currentAppointment = customer.get('current_appointment');
  // TODO: Should be specific to the product?
  const labOrders = customer?.get('lab_orders')?.toArray();
  const labOrder = labOrders?.find((order) => ['placed', 'pending'].includes(order.get('order_status')));
  const clinicianQuestionsCompletedAt = currentIntake?.get('clinician_questions_completed_at');
  const startedIntake = currentIntake?.get('steps')?.first()?.get('completed');
  const isOnboarding = ONBOARDING_TYPES.includes(currentIntake?.get('type'));
  const labSelectionStepPresent = currentIntake?.get('steps')?.some((step) => step.get('name') === 'lab_selection');
  const questLabBaselineResults = isKing && customer.get('king_lab_kit_type') === LabKitTypes.WalkIn;
  const customerPrescriptions = customer.get('prescriptions')?.toJS() ?? [];
  const intakePrescriptions = customerPrescriptions.filter(
    (prescription) =>
      prescription.product_name === productName && prescription.intake_name === currentIntake?.get('name'),
  );
  const hasIntakePrescriptions = intakePrescriptions.length > 0;
  let totalValidOrders = 0;
  let totalOrdersWithRefills = 0;
  intakePrescriptions.forEach((prescription) => {
    totalOrdersWithRefills += (prescription?.refills ?? 0) + 1;
    const validOrders = prescription?.orders?.filter((order) => !order?.lost_or_returned_at) ?? [];
    totalValidOrders += validOrders.length;
  });
  const totalShipments = totalOrdersWithRefills;
  const remainingRefills = totalShipments - totalValidOrders;
  const daysUntilNextRefill = currentIntake?.get('days_until_next_refill');

  const pendingAppointment = () => {
    return isKing && Boolean(pendingAppointmentSchedule);
  };

  const showScheduleVideoConsultation = () => {
    return pendingAppointment() && hasLabResults;
  };

  const showScheduleVideoConsultationAfterLabProcessed = () => {
    return pendingAppointment() && !hasLabResults;
  };

  const showAttendVideoConsultation = () => {
    return isKing && Boolean(currentAppointment);
  };

  const showMedicalQuestionnaire = () => {
    if (isLaas && isKing && !startedIntake) return false; // When Laas, will show only upsell until started intake
    return !clinicianQuestionsCompletedAt; // Show until questions are completed
  };

  const showElationPassportRegistration = () => {
    if (!clinicianQuestionsCompletedAt) return false; // Only show labs after clinician questions are completed
    if (patientPortalRegisteredAt) return false; // When registered, don't show
    return isOnboarding;
  };

  const showCompleteBaselineLabs = () => {
    if (hasLabResults) return false; // When results are present, don't show
    if (isKing && isLaas && labOrder) return true; // Laas always show
    if (!isKing && !isMagician) return false; // King and Magician are the only ones with labs
    if (!clinicianQuestionsCompletedAt) return false; // Only show labs after clinician questions are completed
    if (!labSelectionStepPresent) return false; // There must be a lab selection step (magician needs risk factor)
    return isOnboarding;
  };

  const showLabsProcessed = () => {
    if (!isKing) return false;
    if (!isLaas) return false;
    if (startedIntake) return false;
    return hasLabResults;
  };

  const showDoctorReview = () => {
    if (hasIntakePrescriptions) return false; // Don't show if already has prescriptions
    if (!clinicianQuestionsCompletedAt) return false; // Don't show if incomplete
    if (!isOnboarding) return true; // Follow-up and Sustain
    if (isKing && hasLabResults) return true; // King with lab results
    if (isMagician && (hasLabResults || !labSelectionStepPresent)) return true; // Magician when no lab required or has lab results
    return isLover || isWarrior; // Lover or Warrior always show
  };

  const showOrderFulfillment = () => {
    return remainingRefills > 0;
  };

  const showRenewalQuestionnaire = () => {
    if (!hasIntakePrescriptions) return false;
    if (remainingRefills > 0) return false;
    if (showMedicalQuestionnaire()) return false;
    return Boolean(daysUntilNextRefill);
  };

  if (product.get('current_status') === 'cancelled') {
    return [
      {
        title: 'Subscription canceled',
        description: 'Resume your subscription',
      },
    ];
  }

  const upNextItems: HomePageUpNextItem[] = [];

  if (showScheduleVideoConsultation()) {
    upNextItems.push({
      title: 'Schedule video consultation',
      description: 'Get ready to meet your doctor',
    });
  }

  if (showAttendVideoConsultation()) {
    upNextItems.push({
      title: 'Attend video consultation',
      description: `${currentAppointment.get('started_at').split(',').slice(0, -1).join(',')}`,
    });
  }

  if (showMedicalQuestionnaire()) {
    upNextItems.push({
      title: 'Complete medical questionnaire',
      description: 'Your doctor will review to customize your treatment',
    });
  }

  if (showElationPassportRegistration()) {
    upNextItems.push({
      title: 'Elation Passport Registration',
      description: 'You’ll receive an invite via email',
    });
  }

  if (showCompleteBaselineLabs()) {
    upNextItems.push({
      title: 'Complete baseline labs',
      description: questLabBaselineResults ? 'Visit Quest Labs' : 'Your kit arrives within 3 days of purchase',
    });
  }

  if (showLabsProcessed()) {
    upNextItems.push({
      title: 'Labs Processed',
      description: dayjs(labOrderResults.last().get('date')).format('MMMM DD, YYYY'),
    });
  }

  if (showDoctorReview()) {
    upNextItems.push({
      title: 'Doctor Review',
      description: 'You will be notified once review is complete',
    });
  }

  if (showOrderFulfillment()) {
    const sent = totalShipments - remainingRefills;
    let text = 'You will be notified once your order has shipped.';
    if (sent > 0) {
      text += ` ${sent} of ${totalShipments} total shipments sent`;
    }
    upNextItems.push({
      title: 'Order fulfillment',
      description: text,
    });
  }

  if (showRenewalQuestionnaire()) {
    upNextItems.push({
      title: 'Complete renewal',
      description: `Your renewal questionnaire will be available in ${daysUntilNextRefill} days`,
    });
  }

  if (showScheduleVideoConsultationAfterLabProcessed()) {
    upNextItems.push({
      title: 'Schedule video consultation',
      description: 'After Labs have processed',
      cta: ' ',
    });
  }

  return upNextItems;
};

export default homePageNextItems;
