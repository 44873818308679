import React from 'react';
import { Button } from 'mui';
import { AvailableProducts, LabKitTypes } from 'app/constants/Products';

type Props = {
  setOpenModalAtHomeLab: (openModalAtHomeLab: string | boolean) => void;
  openModalAtHomeLab?: string | null | boolean;
  productName?: string;
  kingV2OralTrt?: boolean;
};
const ModalBodyAtHomeLab = ({
  setOpenModalAtHomeLab,
  openModalAtHomeLab = null,
  productName,
  kingV2OralTrt = false,
}: Props) => {
  const encloPregAtHomeModalBody = (
    <>
      <p>Baseline and follow-up lab testing is required in months 1 and 2.</p>
      <p>
        If you choose to use Maximus’ at-home lab testing, the baseline lab charge occurs when you submit your payment
        information during your onboarding medical questionnaire. It costs $99.99.
      </p>
      <p>The follow-up lab charge occurs automatically 14 days after your first prescription ships. It costs $99.99.</p>
      <p>At-home lab kits are automatically ordered and delivered directly to your shipping address.</p>
      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>
      <p>Otherwise, you will be required to provide updated lab work on an annual basis thereafter.</p>
    </>
  );

  const encloPregOwnLabModalBody = (
    <>
      <p>Baseline and follow-up lab testing is required in months 1 and 2.</p>

      <p>
        If you choose to provide your own lab results (versus using Maximus’ at-home lab tests), you will need to do so
        during your onboarding medical questionnaire (baseline) and again three weeks into treatment (follow-up).
      </p>

      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>

      <p>Otherwise, you will be required to provide updated lab work on an annual basis thereafter.</p>
    </>
  );

  const oralTrtAtHomeModalBody = (
    <>
      <p>Baseline and follow-up lab testing is required in month 1, month 2, and month 3</p>

      <p>
        If you choose to use Maximus’ at-home lab testing, the baseline lab charge occurs when you submit your payment
        information during your onboarding medical questionnaire. It costs $149.99.
      </p>

      <p>
        The first follow-up lab charge occurs automatically 14 days after your first prescription ships. It costs
        $99.99.
      </p>

      <p>
        The second follow-up lab charge occurs automatically 14 days after your second prescription ships. It costs
        $149.99.
      </p>

      <p>At-home lab kits are automatically ordered and delivered directly to your shipping address.</p>

      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>

      <p>Otherwise, you will be required to provide updated lab work on an annual basis thereafter.</p>
    </>
  );

  const oralTrtOwnLabModalBody = (
    <>
      <p>Baseline and follow-up lab testing is required in month 1, month 2, and month 3</p>

      <p>
        If you choose to provide your own lab results (versus using Maximus’ at-home lab tests), you will need to do so
        during your onboarding medical questionnaire (baseline), again three weeks into treatment (first follow-up), and
        once more seven weeks into treatment (second follow-up).
      </p>

      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>

      <p>Otherwise, you will be required to provide updated lab work on an annual basis thereafter.</p>
    </>
  );

  const magicianAtHomeModalBody = (
    <>
      <p>Baseline and follow-up lab testing is required in months 1 and 3.</p>

      <p>
        If you choose to use Maximus’ at-home lab testing, the baseline lab charge occurs when you submit your payment
        information during your onboarding medical questionnaire. It costs $65.00.
      </p>

      <p>The follow-up lab charge occurs automatically 14 days after your third prescription ships. It costs $65.00.</p>

      <p>At-home lab kits are automatically ordered and delivered directly to your shipping address.</p>

      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>
    </>
  );

  const magicianOwnLabModalBody = (
    <>
      <p>Baseline and follow-up lab testing is required in months 1 and 3.</p>

      <p>
        If you choose to provide your own lab results (versus using Maximus’ at-home lab tests), you will need to do so
        during your onboarding medical questionnaire (baseline), and again eleven weeks into treatment (follow-up).
      </p>

      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>
    </>
  );

  const kingWalkInModalBody = (
    <>
      <p>
        A baseline lab test is required to begin treatment. A follow-up lab test is also required after the first month
        of treatment.
      </p>
      <p>
        The <b>baseline lab charge</b> for walk-in lab testing from Quest is due today when you submit your payment at
        checkout.
      </p>
      <p>
        The <b>follow-up lab charge</b> ($109.99) occurs automatically 14 days after your first prescription ships.
      </p>
      <p>
        You will be emailed a requisition form after you are successfully charged, which you will be able to take to a
        Quest location of your choosing to facilitate the test.
      </p>
      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>
      <p>Otherwise, you will be required to provide updated lab work on an annual basis thereafter.</p>
    </>
  );

  const kingOralTrtInModalBody = (
    <>
      <p>
        A baseline lab test is required to begin treatment. Follow-up lab tests are also required after the first and
        second months of treatment.
      </p>
      <p>
        The <b>baseline lab charge</b> for walk-in lab testing from Quest is due today when you submit your payment at
        checkout.
      </p>
      <p>
        The <b>follow-up lab charges</b> ($109.99) occur automatically 14 days after your first and second prescriptions
        ship, respectively.
      </p>
      <p>
        You will be emailed a requisition form after you are successfully charged, which you will be able to take to a
        Quest location of your choosing to facilitate the test.
      </p>
      <p>Additional lab testing may be required by your doctor for ongoing treatment.</p>
      <p>Otherwise, you will be required to provide updated lab work on an annual basis thereafter.</p>
    </>
  );

  const renderModalBody = () => {
    if (productName === AvailableProducts.King) {
      if (openModalAtHomeLab === LabKitTypes.AtHomeLabKit) {
        if (kingV2OralTrt) {
          return oralTrtAtHomeModalBody;
        } else {
          return encloPregAtHomeModalBody;
        }
      } else if (openModalAtHomeLab === LabKitTypes.WalkIn) {
        if (kingV2OralTrt) {
          return kingOralTrtInModalBody;
        } else {
          return kingWalkInModalBody;
        }
      } else {
        if (kingV2OralTrt) {
          return oralTrtOwnLabModalBody;
        } else {
          return encloPregOwnLabModalBody;
        }
      }
    } else {
      if (openModalAtHomeLab === LabKitTypes.AtHomeLabKit) {
        return magicianAtHomeModalBody;
      } else {
        return magicianOwnLabModalBody;
      }
    }
  };

  return (
    <>
      {renderModalBody()}
      <hr />
      <Button onClick={() => setOpenModalAtHomeLab(false)}>Ok</Button>
    </>
  );
};

export default ModalBodyAtHomeLab;
