import React from 'react';
import { connect } from 'react-redux';
import { apiRequestUserCommand, reportBrowserProblem } from 'app/actions/customer';
import { getLocation } from 'app/api';
import { Button, Card, Heading, IconList, PageWrapper } from 'mui';
import MapIcon from 'images/icons/map.svg';

const MapIconComponent = () => <img src={MapIcon} alt="Map" />;

const Allow = ({ onFailed, dispatch }) => {
  const requestLocation = async () => {
    const action = await getLocation()
      .then(({ location, context }) => {
        const cmdType = 'report_customer_location';
        const params = {
          timestamp: location.timestamp,
          accuracy: location.coords.accuracy,
          latitude: location.coords.latitude.toString(),
          longitude: location.coords.longitude.toString(),
        };

        return apiRequestUserCommand({ cmdType, params, context });
      })
      .catch(async (res) => {
        dispatch(
          reportBrowserProblem({
            error: { code: 1 },
            problemType: 'browser_location_error_or_decline',
            details: res,
          }),
        );
        onFailed?.();
      });
    if (action) {
      dispatch(action);
    }
  };
  const reportDevLocation = () => {
    const FLORIDA_POSITION = {
      timestamp: 0,
      accuracy: 2000,
      latitude: '28.131065',
      longitude: '-82.694294',
    };

    const cmdType = 'report_customer_location';
    const params = FLORIDA_POSITION;

    // TODO: Missing context param in apiRequestUserCommand, is this needed?
    const action = apiRequestUserCommand({ cmdType, params });
    dispatch(action);
  };

  // TODO: Is there a better way to test that we're in a local development environment?
  const isLocalDevelopment = window.location.host === 'localhost:3000';
  const onClickHandler = isLocalDevelopment ? reportDevLocation : requestLocation;

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <Heading headingLevel="p" label>
        Verify Location
      </Heading>
      <Card>
        <Card.Body>
          <IconList
            items={[
              {
                title: 'Allow geolocation',
                text: isLocalDevelopment
                  ? 'Hi developer! Click Continue below to bypass the Geolocation and set your location as Florida'
                  : 'Please allow us to use geolocation to make sure you&apos;re in a location ' +
                    'that we can provide high quality care for you.',
                icon: MapIconComponent,
              },
            ]}
          />
          <Button testid="allow-geolocation" onClick={onClickHandler()}>
            {`Continue${isLocalDevelopment ? ' (Dev)' : ''}`}
          </Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default connect()(Allow);
