import React from 'react';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import { Form, Formik } from 'formik';
import InputRow from '../Checkout/InputRow';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import * as actions from 'app/actions/customer';
import { useAppDispatch } from 'app/helpers/hooks';
import { Button, Card, PageWrapper } from 'mui';

const validationSchema = (nextBillingCycle) =>
  Yup.object({
    resume_at: Yup.date()
      .required('Resume date is required')
      .min(new Date(nextBillingCycle), `Resume subscription date must be later than your next billing date`),
  });

const dateformat = 'MMMM D, YYYY';

type Props = {
  setPauseSubscriptionModal: (e) => void;
  nextBillingCycle: string;
  product: string;
  subProduct: string;
  resumeAt: string | null;
  pausedAt: string | null;
  paused: boolean | null;
};

const PauseSubscription = ({
  setPauseSubscriptionModal,
  nextBillingCycle,
  product,
  subProduct,
  resumeAt,
  pausedAt,
  paused,
}: Props) => {
  const dispatch = useAppDispatch();

  const pauseSub = () => (
    <>
      <h3>Pause Subscription</h3>
      <ProductHeader productName={product} subProductName={subProduct} showTitle={false} cardStyle={false} />
      <p>
        Your subscription will pause at the end of your billing cycle,{' '}
        <b>{dayjs(nextBillingCycle).subtract(1, 'day').format(dateformat)}</b>, and resume on the date you select below.
      </p>
      <p>You will not be charged again until your subscription resumes.</p>
      <p>You will receive a reminder two days before your subscription resumes.</p>
      <p>
        Once your subscription resumes, you will receive a notification that your refill questionnaire is ready. This is
        how you will request your next order.
      </p>
      <p>
        <b>Recommended:</b> Set your subscription to resume 7 days ahead of the date you want to receive your next order
        to allow time for fulfillment.
      </p>
    </>
  );

  const changePause = () => (
    <>
      <h3>Subscription is Paused</h3>
      <p>
        Your subscription is scheduled to resume on <b>{dayjs(resumeAt).format(dateformat)}</b>. You can change the
        resume date below or you can resume your subscription immediately if you’re ready now.
      </p>
      <hr />
    </>
  );

  const pauseSubcription = (params) => {
    setPauseSubscriptionModal(false);

    const action = actions.apiRequestUserCommand({
      cmdType: 'pause_subscription',
      params,
      context: {},
    });
    dispatch(action);
  };

  return (
    <PageWrapper>
      <Card className="mb-12">
        <Card.Body>
          {resumeAt ? changePause() : pauseSub()}

          {resumeAt && <h4>Change Resume Date</h4>}

          <Formik
            initialValues={{
              resume_at: resumeAt,
            }}
            validationSchema={validationSchema(pausedAt || nextBillingCycle)}
            onSubmit={(values) => {
              const params = { resume_at: values.resume_at, product_name: product };

              pauseSubcription(params);
            }}
          >
            {({ handleSubmit, isValid }) => (
              <Form className="flex flex-col gap-y-4 lg:gap-y-6">
                <InputRow
                  label="Resume Subscription Date"
                  name="resume_at"
                  type="date"
                  disabledDays={{ before: new Date() }}
                />
                <Button
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                >{`Confirm ${resumeAt ? 'change' : 'pause'}`}</Button>
                <hr />
                {paused && (
                  <Button
                    onClick={() => {
                      const userConfirmed = window.confirm('Are you sure you want to resume your subscription now?');
                      if (userConfirmed) {
                        pauseSubcription({ resume_at: new Date(), product_name: product });
                      }
                    }}
                  >
                    Resume now
                  </Button>
                )}
                <Button
                  variant="outline"
                  onClick={() => setPauseSubscriptionModal(false)}
                >{`Cancel ${resumeAt ? 'change' : 'pause'}`}</Button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default PauseSubscription;
