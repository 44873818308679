import React from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import { EnclomipheneInfo } from 'app/components/customer/steps/Payment/king/EnclomipheneInfo';
import { TopicalTestosteroneInfo } from 'app/components/customer/steps/Payment/king/TopicalTestosteroneInfo';
import { OralTestosteroneInfo } from 'app/components/customer/steps/Payment/king/OralTestosteroneInfo';
import { InjectableTestosteroneInfo } from 'app/components/customer/steps/Payment/king//InjectableTestosteroneInfo';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { Accordion, Badge, Button, Card, IconList } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { useExperiment } from 'app/utils/useExperiment';
import { trackEvent } from 'app/utils/useCommonTracking';

import SlidersIcon from 'images/icons/sliders.svg';
import IngredientIcon from 'images/icons/ingredient.svg';
import FreeshippingIcon from 'images/icons/fast-free-shipping-2.svg';
import AtHomeIcon from 'images/icons/at-home-monitoring-2.svg';

type Props = {
  handleBack: () => void;
  subProductName: KingV2SubProducts;
};

const IngredientIconComponent = () => <img src={IngredientIcon} alt="ingredient" />;
const AtHomeIconComponent = () => <img src={AtHomeIcon} alt="levels" />;
const FreeShippingIconComponent = () => <img src={FreeshippingIcon} alt="free shipping" />;
const SlidersIconComponent = () => <img src={SlidersIcon} alt="at home monitoring" />;

const RecommendationFlowSubProductScreen = ({ subProductName, handleBack }: Props) => {
  const { setFieldValue, handleSubmit } = useFormikContext();

  const subProduct = KingSubProductsConfig[subProductName];
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');

  const getProductInfo = (productName: string) => {
    switch (productName) {
      case 'topical_trt':
        return <TopicalTestosteroneInfo withoutTitle={true} />;
      case 'oral_trt':
        return <OralTestosteroneInfo withoutTitle={true} />;
      case 'injectable_trt':
        return <InjectableTestosteroneInfo withoutTitle={true} />;
      case 'enclomiphene':
        return <EnclomipheneInfo withoutTitle={true} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img className="h-full w-auto" src={ImagesMapping[AvailableProducts.King][subProductName]} alt="king v2" />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.King} className="mb-2">
              {protocolNames[AvailableProducts.King]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.King][subProductName]}</h4>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h5>Treatment Details</h5>
          <p>{subProduct.recommended_for}</p>
          <hr />
          <h5>Active Ingredients</h5>
          {subProduct.active_ingredients.map((ingredient) => (
            <>
              <IconList
                key={ingredient.name}
                items={[
                  {
                    title: ingredient.name,
                    text: ingredient.description,
                    icon: IngredientIconComponent,
                  },
                ]}
              />
              <hr />
            </>
          ))}
          <h5>What to Expect</h5>
          <IconList
            items={[
              {
                title: 'Check Your Levels',
                text: 'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
                icon: AtHomeIconComponent,
              },
              {
                title: 'Get Your Prescription',
                text: 'Once approved, your prescription is shipped straight to your door.',
                icon: FreeShippingIconComponent,
              },
              {
                title: 'Continuous Optimization',
                text:
                  'After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage\n' +
                  ' for optimal results.',
                icon: SlidersIconComponent,
              },
            ]}
          />
          <div className="-mb-8 md:-mb-12 lg:-mb-8">
            <hr />
            <Accordion
              items={[
                {
                  title: 'Side Effects',
                  content: subProduct.side_effects,
                },
                {
                  title: 'Important safety information',
                  content: getProductInfo(subProductName),
                },
              ]}
            />
          </div>
        </Card.Body>
      </Card>

      <div className="fixed-footer">
        <div className="m-auto max-w-[26rem]">
          <p>Plans Starting at ${kingMonthlyPriceExperiment(experimentVariation)[subProductName][12] / 100}/mo</p>

          <Button
            className="mt-4 mb-4"
            type="button"
            onClick={() => {
              setFieldValue('selected_king_v2_product', subProductName);
              trackEvent('Completed Protocol Selector Page', { protocol: 'king', product: subProductName });
              if (subProductName === KingV2SubProducts.Enclo) handleSubmit();
            }}
            disabled={false}
            testid="submit-product-selection"
          >
            Continue with this Protocol
          </Button>

          <p>
            <button onClick={handleBack} className="link bold">
              Go Back
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default RecommendationFlowSubProductScreen;
