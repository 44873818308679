import React, { FC, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'app/components/customer/Spinner';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import 'app/css/NewDesignSystem.scss';
import AvatarOther from '../../../../../images/avatar-other.svg';
import { Button, Heading } from 'mui';
import dayjs from 'dayjs';
import './css/Messages.scss';

export interface IThread {
  id: string;
  title: string;
  sender: string;
  timestamp: string;
  message: string;
  replies: number;
  unread: number;
}

const ThreadCard: FC<{ thread: IThread }> = ({ thread }) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(push(Routes.MessagesThread.replace(':thread_id', thread.id)));
  }, [thread]);

  return (
    <div className="card p-4" onClick={onClick}>
      <div className="flex-row flex gap-4 relative">
        <div>
          <img src={AvatarOther} alt="avatar" />
        </div>
        <div className="flex flex-column gap-3 flex-grow">
          <div className="flex flex-row justify-between mt-1">
            <Heading headingLevel="h5">{thread.title}</Heading>
            <p className="small">
              {dayjs(thread.timestamp).isSame(dayjs(), 'day')
                ? dayjs(thread.timestamp).format('hh:mm A')
                : dayjs(thread.timestamp).format('MM/DD/YYYY')}
            </p>
          </div>
          <p>
            {thread.sender == 'You' && <strong>You: </strong>}
            {thread.message}
          </p>
          <hr />
          <p className="small">
            {thread.replies} replies
            {thread.unread > 0 && ' - '}
            {thread.unread > 0 && <span className="red-text">{thread.unread} unread</span>}
          </p>
        </div>
        {thread.unread > 0 && <div className="red-circle"></div>}
      </div>
    </div>
  );
};

const ThreadsList = () => {
  const dispatch = useDispatch();
  const [threads, setThreads] = useState<IThread[] | null>(null);

  const fetchThreads = useCallback(async () => {
    const response = await axios.get('/api/messages/threads');
    if (response.data.threads.length === 0) {
      dispatch(push(Routes.MessagesNew));
      return;
    }
    setThreads(response.data.threads);
  }, [dispatch]);

  useEffect(() => {
    fetchThreads();
  }, []);

  if (threads === null) return <Spinner isCenter />;

  return threads.map((thread) => <ThreadCard key={thread.id} thread={thread} />);
};

const Messages = () => {
  const dispatch = useDispatch();
  const clickNewMessage = useCallback(() => {
    dispatch(push(Routes.MessagesNew));
  }, [dispatch]);

  return (
    <>
      <div className="centered flex-column p-5">
        <h3 className="ml-10 sm:ml-5 mb-5">Messages</h3>
        <div className="flex-column gap-4">
          <ThreadsList />
        </div>
        <div className="my-16" />
      </div>
      <div className="messages-footer align-items-center">
        <div className="centered flex-column px-10 py-8">
          <Button variant="outline" onClick={clickNewMessage}>
            New Message
          </Button>
        </div>
      </div>
    </>
  );
};

export default Messages;
