import React from 'react';

export const EnclomipheneInfo = ({ withoutTitle = false }) => (
  <>
    {!withoutTitle && <h6 className="underline mt-2 mb-4">Enclomiphene</h6>}

    <p className="bold mb-2">What is Enclomiphene?</p>
    <p className="mb-3">
      Enclomiphene is a prescription medicine used to stimulate your body{"'"}s natural testosterone production by
      acting on the hypothalamus and pituitary gland.
    </p>

    <p className="bold mb-2">Who should not take Enclomiphene?</p>
    <p className="mb-3">
      Do not take enclomiphene if you:
      <ul className="disc-list">
        <li>Are allergic to enclomiphene or any of its ingredients.</li>
        <li>Have liver disease.</li>
        <li>Are under 18 years of age.</li>
      </ul>
    </p>

    <p className="bold mb-2">What should I tell my healthcare provider before taking Enclomiphene?</p>
    <p className="mb-3">
      Before you take enclomiphene, tell your healthcare provider if you:
      <ul className="disc-list">
        <li>Have liver or kidney problems.</li>
        <li>Have any other medical conditions.</li>
        <li>
          Tell your healthcare provider about all the medicines you take, including prescription and over-the-counter
          medicines, vitamins, and herbal supplements.
        </li>
      </ul>
    </p>

    <p className="bold mb-2">How should I take Enclomiphene?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Take enclomiphene exactly as prescribed by your healthcare provider.</li>
        <li>It is usually taken by mouth once daily.</li>
        <li>Do not change your dose without consulting your healthcare provider.</li>
      </ul>
    </p>
    <p className="bold mb-2">What are the possible side effects of Enclomiphene?</p>
    <p className="mb-3">
      Common side effects include:
      <ul className="disc-list">
        <li>Headache</li>
        <li>Nausea</li>
        <li>Mood changes, such as irritability or mood swings</li>
        <li>Acne</li>
      </ul>
    </p>

    <p className="bold mb-2">Less common side effects include:</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Enlargement or tenderness of the breasts</li>
        <li>Weight gain</li>
        <li>Weight gain</li>
      </ul>
    </p>

    <p className="bold mb-2">Serious side effects include:</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Liver problems</li>
        <li>Blood clots</li>
        <li>Severe mood changes</li>
      </ul>
    </p>

    <p className="bold mb-2">Seek emergency medical attention if you experience:</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Sudden vision changes</li>
        <li>Signs of a blood clot</li>
        <li>Severe abdominal pain</li>
        <li>Signs of an allergic reaction</li>
      </ul>
    </p>

    <p className="bold mb-2">What else should I know about Enclomiphene?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Enclomiphene typically improves fertility and testicular function.</li>
        <li>Regular blood tests may be needed to monitor hormone levels.</li>
        <li>Inform your healthcare provider about all other medications and supplements you are taking.</li>
      </ul>
    </p>

    <p className="bold mb-2">How should I store Enclomiphene?</p>
    <p className="mb-3">
      <ul className="disc-list">
        <li>Store at room temperature away from moisture and heat.</li>
        <li>Keep out of reach of children.</li>
      </ul>
    </p>

    <p className="mb-3">General information about the safe and effective use of Enclomiphene.</p>
    <p className="mb-3">
      Do not use enclomiphene for a condition for which it was not prescribed. Do not share this medication with others.
    </p>
  </>
);
