export const SEGMENT_KING_EVENTS = {
  // View Events
  VIEWED_STATE_SELECTION: 'Viewed State Selection Page',
  VIEWED_NAME_ENTRY: 'Viewed Name Entry Page',
  VIEWED_ACCOUNT_CREATION: 'Viewed Account Creation Page',
  VIEWED_GETTING_STARTED: 'Viewed Getting Started Page',
  VIEWED_FERTILITY_PLAN: 'Viewed Fertility Plan Page',
  VIEWED_TESTICULAR_RISK: 'Viewed Testicular Risk Page',
  VIEWED_MEDICAL_DEPENDENCE: 'Viewed Medical Dependence Page',
  VIEWED_LIBIDO_PREFERENCE: 'Viewed Libido Preference Page',
  VIEWED_ORAL_TRT_WARNING: 'Viewed Oral TRT Warning Page',
  VIEWED_TOPICAL_WARNING: 'Viewed Topical Warning Page',
  VIEWED_INJECTABLE_WARNING: 'Viewed Injectable Warning Page',
  VIEWED_TREATMENT_PREFERENCE: 'Viewed Treatment Preference Page',

  // Completion Events
  COMPLETED_STATE_SELECTION: 'Completed State Selection Page',
  COMPLETED_FERTILITY_PLAN: 'Completed Fertility Plan Page',
  COMPLETED_TESTICULAR_RISK: 'Completed Testicular Risk Page',
  COMPLETED_MEDICAL_DEPENDENCE: 'Completed Medical Dependence Page',
  COMPLETED_LIBIDO_PREFERENCE: 'Completed Libido Preference Page',
  COMPLETED_ORAL_TRT_WARNING: 'Completed Oral TRT Warning Page',
  COMPLETED_TOPICAL_WARNING: 'Completed Topical Warning Page',
  COMPLETED_INJECTABLE_WARNING: 'Completed Injectable Warning Page',
  COMPLETED_TREATMENT_PREFERENCE: 'Completed Treatment Preference Page',
};

export const SEGMENT_KING_EVENTS_STEP_MAPPING = {
  fertility: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_FERTILITY_PLAN,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_FERTILITY_PLAN,
  },
  testicular_health: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_TESTICULAR_RISK,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_TESTICULAR_RISK,
  },
  medication_dependence: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_MEDICAL_DEPENDENCE,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_MEDICAL_DEPENDENCE,
  },
  libido_preference: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_LIBIDO_PREFERENCE,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_LIBIDO_PREFERENCE,
  },
  oral_testosterone_fat_intake: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_ORAL_TRT_WARNING,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_ORAL_TRT_WARNING,
  },
  topical_testosterone_application: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_TOPICAL_WARNING,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_TOPICAL_WARNING,
  },
  injectable_testosterone_willingness: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_INJECTABLE_WARNING,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_INJECTABLE_WARNING,
  },
  treatment_preference: {
    viewEvent: SEGMENT_KING_EVENTS.VIEWED_TREATMENT_PREFERENCE,
    completionEvent: SEGMENT_KING_EVENTS.COMPLETED_TREATMENT_PREFERENCE,
  },
};

export const SEGMENT_LOVER_EVENTS = {
  VIEWED_SEX_PAGE: 'Viewed Sex Page',
  VIEWED_ERECTION_PAGE: 'Viewed Erection Page',
  VIEWED_ERECTILE_DYSFUNCTION_PAGE: 'Viewed Erectile Dysfunction Page',
  VIEWED_ERECTILE_DYSFUNCTION_START_PAGE: 'Viewed Erectile Dysfunction Start Page',

  COMPLETED_SEX_PAGE: 'Completed Sex Page',
  COMPLETED_ERECTION_PAGE: 'Completed Erection Page',
  COMPLETED_ERECTILE_DYSFUNCTION_PAGE: 'Completed Erectile Dysfunction Page',
  COMPLETED_ERECTILE_DYSFUNCTION_START_PAGE: 'Completed Erectile Dysfunction Start Page',
};

export const SEGMENT_LOVER_EVENTS_STEP_MAPPING = {
  sex: {
    viewEvent: SEGMENT_LOVER_EVENTS.VIEWED_SEX_PAGE,
    completionEvent: SEGMENT_LOVER_EVENTS.COMPLETED_SEX_PAGE,
  },
  erection: {
    viewEvent: SEGMENT_LOVER_EVENTS.VIEWED_ERECTION_PAGE,
    completionEvent: SEGMENT_LOVER_EVENTS.COMPLETED_ERECTION_PAGE,
  },
  ed_scenario: {
    viewEvent: SEGMENT_LOVER_EVENTS.VIEWED_ERECTILE_DYSFUNCTION_PAGE,
    completionEvent: SEGMENT_LOVER_EVENTS.COMPLETED_ERECTILE_DYSFUNCTION_PAGE,
  },
  ed_start: {
    viewEvent: SEGMENT_LOVER_EVENTS.VIEWED_ERECTILE_DYSFUNCTION_START_PAGE,
    completionEvent: SEGMENT_LOVER_EVENTS.COMPLETED_ERECTILE_DYSFUNCTION_START_PAGE,
  },
};

export const SEGMENT_WARRIOR_EVENTS = {
  VIEWED_HAIR_DESCRIPTION_PAGE: 'Viewed Hair Description Page',
  COMPLETED_HAIR_DESCRIPTION_PAGE: 'Completed Hair Description Page',
};
