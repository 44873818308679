import React, { useState } from 'react';
import { Checkbox as FormikCheckbox } from 'app/components/common/formik/Checkbox';
import { useFormikContext } from 'formik';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import GenericRefundPolicy from './GenericRefundPolicy';

const GenericSelfPay = () => {
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    selfPay: boolean;
  }>();
  const { selfPay } = values;

  const handleCheckboxChange = (e) => {
    setFieldValue('selfPay', e.target.checked);
  };

  return (
    <div>
      <FormikCheckbox
        topAligned
        onChange={handleCheckboxChange}
        checked={selfPay}
        id="selfPay"
        testid="self-pay"
        name="selfPay"
        label={
          <p>
            I acknowledge that I have read, understood, and accept the{' '}
            <button className="link" onClick={() => setOpenRefundModal(true)}>
              Refund Policy
            </button>{' '}
            as well as{' '}
            <a rel="noreferrer" href="/self-pay-agreement" target="_blank">
              Patient Self-Pay Agreement
            </a>{' '}
            stating that the medical group does not participate in any insurance plans, including Medicare / Medicaid.
          </p>
        }
      />

      <SetProductDialog
        isOpen={openRefundModal}
        title="Refund Policy"
        text={<GenericRefundPolicy onClose={() => setOpenRefundModal(false)} />}
        onClose={() => setOpenRefundModal(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </div>
  );
};

export default GenericSelfPay;
