import React from 'react';
import { LabKitTypes } from 'app/constants/Products';
import PriceFormatter from 'app/components/common/PriceFormatter';
import heic2any from 'heic2any';
import { Card, Button } from 'mui';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import { useFormikContext } from 'formik';

const MagicianLabWidget = ({
  setFieldValue,
  setOwnLabFile,
  priceCalculator,
  use_own_lab,
  lab_kit_type,
  setOpenModalAtHomeLab,
  setOpenModalOwnLab,
  own_lab_file_uploaded,
  byolFormSubmitted,
  inputFile,
}) => {
  const atHomeLabPrice = priceCalculator.atHomeLabPrice() / 100;
  const { handleSubmit } = useFormikContext();

  const handleLabTypeChange = (evt) => {
    setFieldValue('lab_kit_type', evt.target.value);
    setFieldValue('use_own_lab', evt.target.value === LabKitTypes.UseYourOwnLab);
  };

  const onImageChange = ({ target }) => {
    const file = target.files?.[0];
    target.value = '';

    if (file) {
      if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 }).then((newImage) => {
          setOwnLabFile(newImage);
        });
      } else {
        setOwnLabFile(file);
      }
    }
  };

  return (
    <Card>
      <Card.Body>
        <p>
          Since you indicated during the medical questionnaire you have Hypertension and/or Kidney Disease, your doctor
          will require you to provide lab work within 60 days of starting treatment.
        </p>
        <p>You can choose to provide your own lab work today or use Maximus{"'"} convenient at-home lab testing.</p>
        <p>
          Don’t worry – your doctor will not delay writing your initial prescription. Your doctor will review your
          medical questionnaire responses and place your order within 48 hours (Mon-Fri) if qualified.{' '}
        </p>
        <label className="flex flex-row justify-between mt-8">
          <span className="flex flex-row gap-x-2">
            <input
              type="radio"
              data-testid="use-lab-kit"
              name="lab_kit_type"
              value={LabKitTypes.AtHomeLabKit}
              className="radio_button min-w-8"
              checked={!use_own_lab && lab_kit_type === LabKitTypes.AtHomeLabKit}
              onChange={handleLabTypeChange}
            />
            <b>At-Home Lab Kits</b>
          </span>
          <b>
            <PriceFormatter price={atHomeLabPrice} />
          </b>
        </label>
        <p className="pl-10 text-sm">
          Maximus uses CLIA-certified lab tests that ship straight to your door. The tests are painless and do not
          require finger-pricking. Results are available FAST (typically within four business days).{' '}
          <button className="link" onClick={() => setOpenModalAtHomeLab(LabKitTypes.AtHomeLabKit)}>
            Full details
          </button>
        </p>
        <label className="flex flex-row justify-between mt-8">
          <span className="flex flex-row gap-x-2">
            <input
              type="radio"
              data-testid="use-own-lab"
              name="use_own_lab"
              className="radio_button min-w-8"
              checked={use_own_lab || lab_kit_type === LabKitTypes.UseYourOwnLab} // for old customers use_own_lab still may be set
              value={LabKitTypes.UseYourOwnLab}
              onChange={handleLabTypeChange}
            />
            <b>Use Your Own Lab Test</b>
          </span>
        </label>
        <p className="pl-10 text-sm">
          You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 90 days
          after starting treatment, or as required by your licensed Maximus doctor).{' '}
          <button className="link" onClick={() => setOpenModalAtHomeLab(LabKitTypes.UseYourOwnLab)}>
            {' '}
            Full details
          </button>
        </p>
        {use_own_lab && (
          <>
            {!own_lab_file_uploaded || !byolFormSubmitted ? (
              <div className="text-main-danger pl-10">
                This option requires lab results be uploaded in order to check out.
              </div>
            ) : (
              <div className="byol-form-success">Successfully uploaded</div>
            )}
            <Button
              variant="outline"
              data-testid="own-lab-modal-open"
              onClick={() => setOpenModalOwnLab(true)}
              disabled={!use_own_lab}
            >
              Upload lab results
            </Button>
            <input
              ref={inputFile}
              type="file"
              id="file"
              style={{ display: 'none' }}
              accept="image/*, application/pdf"
              onChange={onImageChange}
            />
          </>
        )}
        <BackNextFooter
          noBack={true}
          onNext={handleSubmit}
          testId="submit-lab-selection"
          nextDisabled={use_own_lab && !own_lab_file_uploaded}
        />
      </Card.Body>
    </Card>
  );
};

export default MagicianLabWidget;
