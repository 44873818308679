import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import './css/Acknowledgement.scss';
import { useAppSelector } from 'app/helpers/hooks';
import { KingV2SubProducts, AvailableProducts } from 'app/constants/Products';
import SlidersIcon from 'images/testosterone-intro/sliders.svg';
import ShippingIcon from 'images/testosterone-intro/fast-free-shipping.svg';
import MonitoringIcon from 'images/testosterone-intro/at-home-monitoring.svg';
import DiscountBanner from './Payment/DiscountBanner.tsx';
import { Button as MxButton, Card, Heading, IconList, PageWrapper } from 'mui';
import { SEGMENT_KING_EVENTS } from 'app/constants/SegmentEvents';
import { useSegmentTracking } from 'app/utils/useSegmentTracking';

const mapStateToProps = (_) => {
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestOnboardingStepPath({ customer: stateAfterSuccess }));

  return {
    onSuccessActionCreator,
  };
};

const productDetailsVariationTwo = {
  default: {
    title: 'Start optimizing your testosterone',
    desc: ['Personalized protocols to maximize testosterone levels.'],
    gettingStarted: [
      'Test key biomarkers with an at-home lab kit.',
      'Approved medication ships to your door.',
      'Ongoing lab testing and dosage adjustments for best results.',
    ],
  },
  [KingV2SubProducts.EncloPregnolone]: {
    title: 'Boost Your Natural Testosterone With EP Protocol',
    desc: [
      'A convenient prescription tablet that naturally stimulates your body’s testosterone production.',
      'Potential for a 2x increase in testosterone levels.',
    ],
    gettingStarted: [
      'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we send another lab-kit to ensure progress. Your doctor will adjust your dosage for optimal results.',
    ],
  },
  [KingV2SubProducts.EncloPregnoloneTrt]: {
    title: 'Unlock Elite Testosterone Levels With Oral TRT+ Protocol',
    desc: [
      'Boost energy and body composition without impacting your fertility markers, liver, kidney, or testicular function.',
      'Achieve a 2-5x increase in testosterone levels.',
    ],
    gettingStarted: [
      'Receive your At-Home Lab Kit or Quest Labs form to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we’ll send another lab-kit or requisition form to monitor your progress, and your doctor will fine-tune your dosage as needed.',
    ],
  },
  [KingV2SubProducts.OralTrt]: {
    title: 'Unlock Elite Testosterone Levels With Oral TRT Protocol',
    desc: [
      'Boost energy and body composition without impacting your liver, kidney, or testicular function.',
      'Achieve a 2-5x increase in testosterone levels.',
    ],
    gettingStarted: [
      'Receive your At-Home Lab Kit or Quest Labs form to check your testosterone levels quickly.',
      'Once your doctor approves, your prescription ships to your door.',
      'After your first month, we’ll send another lab-kit or requisition form to monitor your progress, and your doctor will fine-tune your dosage as needed.',
    ],
  },
};

const iconComponent = (icon) => () => <img src={icon} alt="" />;

const Acknowledgement = ({ onSuccessActionCreator, step, dispatch }) => {
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, AvailableProducts.King));
  const kingSubProductName = product?.get('opt_in_choice')?.get('selected_king_v2_product');
  useSegmentTracking(SEGMENT_KING_EVENTS.VIEWED_GETTING_STARTED);

  const onClick = () => {
    const cmdType = 'answer_questions';
    const params = { [step]: true };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  const productDetails = productDetailsVariationTwo[kingSubProductName] || productDetailsVariationTwo['default'];
  const variationTwoIcons = [MonitoringIcon, ShippingIcon, SlidersIcon];
  const gettingStartedHeadings = ['Check your levels', 'Get Your Prescription', 'Continuous Optimization'];

  const gettingStarted = () => {
    const items = productDetails.gettingStarted.map((item, index) => ({
      title: gettingStartedHeadings[index],
      text: item,
      icon: iconComponent(variationTwoIcons[index]),
    }));

    return <IconList items={items} />;
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <div>
        <Heading headingLevel="p" label>
          Get Started
        </Heading>
        <Heading headingLevel="h3">{productDetails.title}</Heading>
      </div>
      <Card>
        <Card.Body>
          <Heading headingLevel="p" label>
            How it works
          </Heading>
          <DiscountBanner />
          {gettingStarted()}
          <MxButton variant="primary" onClick={onClick} data-testid="next-step">
            Start Clinical Intake
          </MxButton>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(Acknowledgement);
