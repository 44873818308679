import React, { FC, useCallback } from 'react';
import './css/HomePageSocialMedia.scss';
import assetLibrary from 'app/utils/assetLibrary';
import { Badge } from 'mui';
import { ProtocolProps } from './helpers/protocols';
import * as Routes from 'app/constants/Routes';

export type ProtocolCardItemProps = {
  title: string;
  description: string;
  url: string;
  cta?: string;
};

export type ProtocolCardLink = {
  label: string;
  url: string;
};

const ProtocolUpsellCard: FC<ProtocolProps> = ({ protocol }) => {
  const handleGetStartedClick = useCallback(() => {
    window.location.href = `${Routes.ContinueOnboarding}?product=${protocol.slug}`;
  }, []);

  const handleLearnMoreClick = useCallback(() => {
    window.open(protocol.upsell.link, '_blank');
  }, []);

  return (
    <div className="dash-card product-upsell-card flex flex-row gap-2">
      <img
        className="product-upsell-card__product-image"
        src={assetLibrary[protocol.upsell.image]}
        alt={protocol.name}
      />
      <div className="product-upsell-card__product-content flex flex-column gap-3">
        <Badge variant={protocol.slug}>{protocol.name}</Badge>

        <p className="product-upsell-card__title">{protocol.upsell.title}</p>
        <p className="product-upsell-card__description">{protocol.upsell.description}</p>
        <div className="flex flex-column mt-4 align-left gap-1">
          <button
            className="product-upsell-card__cta"
            onClick={handleGetStartedClick}
            data-testid={`get-started-${protocol.slug}`}
          >
            Get Started
          </button>
          <button className="product-upsell-card__learn-more" onClick={handleLearnMoreClick}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProtocolUpsellCard;
