import React, { FC } from 'react';
import './css/HomePageSocialMedia.scss';
import iconX from 'images/social-x.svg';
import iconReddit from 'images/social-reddit.svg';
import iconInstagram from 'images/social-instagram.svg';
import iconFacebook from 'images/social-facebook.svg';

type HomePageSocialMediaProps = {
  iconsOnTop?: boolean;
};

const HomePageSocialMedia: FC<HomePageSocialMediaProps> = ({ iconsOnTop = false }) => {
  return (
    <section className="social-media center-align mt-16 w-full">
      {!iconsOnTop && (
        <p className="mb-8 underlined">
          <a href="https://linktr.ee/maximustribe" rel="noreferrer" target="_blank">
            Join our social community
          </a>
        </p>
      )}
      <nav className="flex flex-row gap-5 flex-center">
        <a href="https://www.reddit.com/r/MaximusTribe/" target="_blank" rel="noreferrer">
          <img src={iconReddit} alt="Reddit" />
        </a>
        <a href="https://www.facebook.com/maximustribehealth" target="_blank" rel="noreferrer">
          <img src={iconFacebook} alt="Facebook" />
        </a>
        <a href="https://instagram.com/maximustribe" target="_blank" rel="noreferrer">
          <img src={iconInstagram} alt="Instagram" />
        </a>
        <a href="https://x.com/maximustribe" target="_blank" rel="noreferrer">
          <img src={iconX} alt="X" />
        </a>
      </nav>
      {iconsOnTop && (
        <p className="underlined">
          <a href="https://linktr.ee/maximustribe" rel="noreferrer" target="_blank">
            Join our social community
          </a>
        </p>
      )}
      <div className="mb-8" />
    </section>
  );
};

export default HomePageSocialMedia;
