import React from 'react';
import { Button, Card, PageWrapper } from 'mui';
import './css/ContactUs.scss';
import { isDev } from 'app/helpers/env';

const ContactUs = () => {
  const openLiveChat = () => {
    try {
      window.Intercom('showSpace', 'messages');
    } catch (e) {
      if (isDev()) console.log('Error opening Intercom: ', e.message);
    }
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <div>
        <h3 className="mb-2">Contact Us</h3>
        <p>We’re here to help.</p>
      </div>
      <Card>
        <Card.Body>
          <h4>Help Center</h4>
          <p>Your question may have already been answered.</p>
          <p>
            Go to{' '}
            <a href="https://support.maximustribe.com" target="_blank" rel="noreferrer">
              support.maximustribe.com
            </a>{' '}
            to search our help center for quick answers.
          </p>
          <a
            className="mxbtn px-4 py-2 text-sm md:text-base mxbtn-outline w-full"
            target="_blank"
            href="https://support.maximustribe.com"
            rel="noreferrer"
          >
            Visit Help Center
          </a>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Medical Questions</h4>
          <p>
            If your question is medical in nature (related to your health, your response to Maximus protocols, etc.),
            please log in to Elation Patient Passport to message your Maximus doctor.
          </p>
          <a
            className="mxbtn px-4 py-2 text-sm md:text-base mxbtn-outline w-full"
            target="_blank"
            href="https://maximustribe.com/passport"
            rel="noreferrer"
          >
            Visit Elation Passport
          </a>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Concierge team</h4>
          <p>
            If you would like to contact a member of the Maximus concierge team, please contact us via the Live Chat
            (fastest) link below or e-mail <a href="mailto:support@maximustribe.com">support@maximustribe.com</a>
          </p>
          <Button variant="outline" onClick={openLiveChat}>
            Live Chat
          </Button>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Join the Maximus Tribe on Discord</h4>
          <p>Join the Maximus Tribe on Discord to chat with other Maximus members, ask questions, and get support.</p>
          <a
            className="mxbtn px-4 py-2 text-sm md:text-base mxbtn-outline w-full"
            target="_blank"
            href="https://discord.gg/maximus"
            rel="noreferrer"
          >
            Join Discord
          </a>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default ContactUs;
