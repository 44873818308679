import React, { FC, useEffect } from 'react';
import assetLibrary from 'app/utils/assetLibrary';
import { selectCustomer } from 'app/selectors/customer';
import './css/HomePage.scss';
import { useAppSelector } from 'app/helpers/hooks';
import ProtocolCard from './ProtocolCard';
import HomePageResources from './HomePageResources';
import HomePageSocialMedia from './HomePageSocialMedia';
import { PROTOCOLS } from './helpers/protocols';
import homePageNextItems from 'app/components/customer/dashboard/HomePage/helpers/homePageNextItems';
import ProtocolUpsellCard from './ProtocolUpsellCard';
import homePageShipmentNotifications from 'app/components/customer/dashboard/HomePage/helpers/homePageShipmentNotifications';

const UserHeader: FC = () => {
  const customer = useAppSelector(selectCustomer);
  const name = customer.get('first_name');

  return (
    <div className="dash-card user-header mt-2">
      <div className="flex flex-row gap-2">
        <img className="me-4 user-header__avatar" src={assetLibrary['user-placeholder']} alt="user avatar" />
        <div>
          <p className="user-header__greeting">Welcome Back</p>
          <p className="user-header__name">{name}</p>
        </div>
      </div>
    </div>
  );
};

const HomePage = () => {
  const customer = useAppSelector(selectCustomer);
  const activeProtocols = PROTOCOLS.filter((item) =>
    customer.getIn(['maximus_products', item.slug])?.get('current_subscription'),
  );
  const ongoingProtocols = PROTOCOLS.filter(
    (item) =>
      !activeProtocols.includes(item) &&
      customer
        .getIn(['maximus_products', item.slug])
        ?.get('intakes')
        ?.last()
        ?.get('steps')
        ?.some((step) => step.get('completed')),
  );
  const upsellProtocols = PROTOCOLS.filter(
    (item) => !activeProtocols.includes(item) && !ongoingProtocols.includes(item),
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="dash-bg" />
      <div className="dash centered flex-column flex-between px-5 gap-8 mb-10">
        <UserHeader />
        {activeProtocols.concat(ongoingProtocols).map((protocol) => {
          const items = homePageNextItems(customer, protocol.slug).map(({ title, description, cta }) => ({
            title,
            description,
            cta,
            url: `?product=${protocol.slug}`,
          }));

          const notifications = homePageShipmentNotifications(customer)
            .filter(({ protocolName: product }) => product === protocol.slug)
            .map(({ title, description, trackingUrl }) => ({
              title,
              description,
              trackingUrl,
            }));

          return (
            <ProtocolCard
              protocol={protocol}
              items={items}
              notifications={notifications}
              key={`protocol-card-${protocol.slug}`}
            />
          );
        })}
        {upsellProtocols.map((protocol) => (
          <ProtocolUpsellCard protocol={protocol} key={`protocol-card-${protocol.slug}`} />
        ))}
      </div>
      <HomePageResources />
      <HomePageSocialMedia />
    </>
  );
};

export default HomePage;
