import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';
import { Formik, Form, Field } from 'formik';
import { Dropdown } from 'app/components/common/formik/Dropdown';
import FieldError from 'app/components/common/formik/FieldError';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import { RegistrationSchemaStep2 } from 'app/helpers/validators';
import { Card, Heading, Button as MaximusButton } from 'mui';

const msoTermsOfUseLink = '/terms-of-use';
const msoPrivacyPolicyLink = '/privacy-policy';
const pcInformedConsentLink = '/telehealth-informed-consent';
const pcPrivacyPracticesLink = '/notice-privacy-practices';

const Step1 = ({ initialValues, onSubmit, experimentVariant }) => {
  const stateSelectionExperiment = experimentVariant === 'registration_state_selection_variation_1';

  return (
    <Formik
      initialValues={{
        ...initialValues,
        agree: initialValues.pc_opt_in && initialValues.mso_opt_in,
      }}
      onSubmit={({ agree, ...values }) =>
        onSubmit({
          ...values,
          pc_opt_in: agree,
          mso_opt_in: agree,
        })
      }
      validationSchema={RegistrationSchemaStep2}
    >
      {({ isSubmitting }) => (
        <Form>
          <Card>
            <Card.Body>
              <div>
                <Heading headingLevel="h3" className="mb-2">
                  {stateSelectionExperiment ? 'Can we ship to you?' : 'What’s your location and age?'}
                </Heading>
                <p>
                  {stateSelectionExperiment
                    ? 'Some products are only available in specific states. Select your state to see products available to you'
                    : 'Provide your age and state to confirm treatment eligibility and shipping availability if qualified.'}
                </p>
              </div>
              <Dropdown
                id="state"
                name="state"
                emptyValue="Select Your State"
                position="bottom"
                className="registration-flow__dropdown"
                label={stateSelectionExperiment ? 'U.S. State' : 'State'}
              />
              <Checkbox
                id="over_18"
                name="over_18"
                label="I am at least 18 years old."
                labelClassName="pt-[2px]"
                error={<Field name="over_18" component={FieldError} />}
              />
              <Checkbox
                id="agree"
                name="agree"
                error={<Field name="agree" component={FieldError} />}
                label={
                  <>
                    By clicking this box, I acknowledge that I have read, understood, and agree to the{' '}
                    <a rel="noreferrer" href={msoTermsOfUseLink} target="_blank">
                      Terms of Use
                    </a>
                    ,{' '}
                    <a rel="noreferrer" href={msoPrivacyPolicyLink} target="_blank">
                      Privacy Policy
                    </a>
                    ,{' '}
                    <a rel="noreferrer" href={pcInformedConsentLink} target="_blank">
                      Telehealth Informed Consent
                    </a>
                    , and acknowledge the{' '}
                    <a rel="noreferrer" href={pcPrivacyPracticesLink} target="_blank">
                      Notice of Privacy Practices
                    </a>
                  </>
                }
              />
              <MaximusButton
                disabled={isSubmitting}
                type="submit"
                testid="registration-step-2-submit"
                className="button-next"
              >
                Next
              </MaximusButton>
              <p>
                Already have an account? <Link to={Routes.Login}>Sign In</Link>
              </p>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default Step1;
