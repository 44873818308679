import React, { useContext, useMemo } from 'react';
import { Accordion, Card } from 'mui';
import OPTIONS from 'app/constants/option-groups.json';
import { ProductContext } from '../Product';
import './css/FAQ.scss';

const ProgressTrackerFAQ = () => {
  const productCtx = useContext(ProductContext);
  const faqItemsProduct = window.faqItems?.filter((i) => i.product === productCtx?.selectedProduct);

  const availableStatesList = useMemo(
    () =>
      OPTIONS.states
        .filter((state: { label: string; value: string }) => window.signupStates.includes(state.value))
        .map((state) => state.label)
        .join(', '),
    [window.signupStates],
  );

  const parseAnswer = (answer: string) => answer.replace('[AVAILABLE_STATES]', availableStatesList);

  if (!faqItemsProduct?.length) {
    return null;
  }

  const accordionItems = () => {
    return faqItemsProduct.map((item) => ({
      content: <p dangerouslySetInnerHTML={{ __html: parseAnswer(item.answer) }} />,
      open: false,
      title: item.question,
    }));
  };

  return (
    <div className="centered mt-12 lg:mt-16">
      <h3 className="ml-10 sm:ml-5">F.A.Q.</h3>
      <Card className="customer-card-content mt-4 lg:mt-6 py-6 lg:py-8">
        <Accordion items={accordionItems()} />
      </Card>
    </div>
  );
};
export default ProgressTrackerFAQ;
