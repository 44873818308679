import { Cmd, loop } from 'redux-loop';
import { updateOneThread } from 'app/helpers/reducerHelpers';
import { fromJS, Map } from 'immutable';
import { apiRequestSuccess, apiRequestFailure } from 'app/helpers/commandHelpers';
import * as selectors from 'app/selectors/customerReducer';
import { apiRequest } from 'app/api';
import { hardRedirectRequested } from 'app/actions/customer';
import * as Routes from 'app/constants/Routes';

export const apiRequestSuccessHandler = (state, action) => {
  const { onSuccessActionCreator, onSuccessAction, json, reqId = 0 } = action;

  const succ = state.set('connectivity', 'online').deleteIn(['requestsInProgress', 'userFetch', reqId.toString()]);
  let newState = succ;

  const basic = fromJS((json.entity && [json.entity]) || json.entities || []);
  const more = fromJS(json.more_entities || []);
  const entities = basic.concat(more);
  const hardReject = false;
  let accountDeleted = false;
  if (entities) {
    newState = entities.reduce((oldState, entity) => {
      if (entity.get('entity_type') === 'private_message') {
        return oldState.update('threads', (threads) =>
          (threads || Map()).update(entity.get('thread'), (messages) => updateOneThread(messages, entity)),
        );
      }
      if (entity.get('entity_type') === 'customer') {
        if (!entity.get('customer')) {
          return oldState.set('customer', entity);
        }
        if (entity.get('id') === oldState.getIn(['customer', 'id'])) {
          return oldState.set('customer', entity);
        }

        return oldState;
      }
      if (entity.get('entity_type') === 'tombstone') {
        accountDeleted = true;

        return oldState;
      }
      if (entity.get('entity_type') === 'admin') {
        return oldState;
      }
      if (entity.get('entity_type') === 'berbix_transaction') {
        return oldState.set('the_berbix_transaction', entity);
      }
      if (entity.get('entity_type') === 'berbix_tokens') {
        return oldState.setIn(['berbix_tokens', entity.get('transaction_id')], entity);
      }

      return oldState;
    }, succ);
  }
  if (hardReject) {
    return loop(newState, Cmd.action(hardRedirectRequested(Routes.ComingSoon)));
  }
  if (accountDeleted) {
    return loop(newState, Cmd.action(hardRedirectRequested(Routes.Goodbye)));
  }

  const cmds = [];
  const theForm = (action.context && action.context.form) || action.form;
  if (theForm) {
    newState = newState.setIn(['forms', theForm, 'errors'], {});
  }
  if (onSuccessAction) {
    cmds.push(Cmd.action(onSuccessAction));
  }
  if (onSuccessActionCreator) {
    cmds.push(Cmd.action(onSuccessActionCreator(action, newState)));
  }
  if (cmds.length !== 0) {
    return loop(newState, Cmd.list([Cmd.list(cmds)]));
  }

  return newState;
};

export const apiRequestProblemsHandler = (state, action) => {
  let newState = state;

  const theForm = (action.context && action.context.form) || action.form;
  if (theForm) {
    newState = newState
      .deleteIn(['requestsInProgress', 'userFetch'], action.reqId.toString())
      .set('connectivity', 'online')
      .setIn(['forms', theForm, 'errors'], action.json.errors);
  }

  const cmds = [];
  if (action.context && action.context.onFailureActionCreator) {
    cmds.push(Cmd.action(action.context.onFailureActionCreator(action, newState)));
  }
  if (cmds.length !== 0) {
    return loop(newState, Cmd.list([Cmd.list(cmds)]));
  }

  return newState;
};
export const apiRequestErrorsHandler = (state, action) => {
  const newState = state
    .deleteIn(['requestsInProgress', 'userFetch'], action.reqId.toString())
    .set('connectivity', 'online');
  if (action.status === 403) {
    return loop(newState, Cmd.action(hardRedirectRequested(Routes.Login)));
  }

  const cmds = [];
  if (action.context && action.context.onServerFailureActionCreator) {
    cmds.push(Cmd.action(action.context.onServerFailureActionCreator(action, newState)));
  }
  if (cmds.length !== 0) {
    return loop(newState, Cmd.list([Cmd.list(cmds)]));
  }

  return newState;
};

export const apiRequestFailedHandler = (state, action) => {
  if (action.reqId) {
    return state.deleteIn(['requestsInProgress', 'userFetch'], action.reqId.toString()).set('connectivity', 'offline');
  }

  return state.set('connectivity', 'offline');
};

export const locationChangeHandler = (state, action) => {
  const path = action.payload.location.pathname;

  const regex = /^\/dashboard\/messages\/(.+)$/g;
  const found = regex.exec(path);
  const thread = found && found.slice(-1);

  const customer = selectors.selectCustomer(state);
  if (thread && customer) {
    const userId = customer.get('id');
    const requestCmd = Cmd.run(apiRequest, {
      successActionCreator: apiRequestSuccess,
      failActionCreator: apiRequestFailure,
      args: [42, 'GET', `/api/private_messages?user_id=${userId}&thread=${thread}`, null, { thread }],
    });

    return loop(state.set('currentPathname', path), Cmd.list([requestCmd]));
  }
  if (state.get('messagesPollingId')) {
    return loop(
      state.delete('messagesPollingId').set('currentPathname', path),
      Cmd.clearInterval(selectors.selectMessagesPollingId(state)),
    );
  }

  return state;
};
