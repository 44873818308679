import React from 'react';
import { useFormikContext } from 'formik';
import { kingMonthlyPriceExperiment } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';
import { useExperiment } from 'app/utils/useExperiment';

export const MultimonthConfig = [
  {
    value: 12,
    specialLabel: 'Best Value',
    savings: '$1200 yearly savings',
    subheader: 'Bills every 12 months',
    title: 'Yearly Plan',
  },
  {
    value: 3,
    specialLabel: 'Most Popular',
    savings: '$600 yearly savings',
    subheader: 'Bills every 3 months',
    title: 'Quarterly Plan',
  },
  {
    value: 1,
    subheader: 'Bills every month',
    title: 'Monthly Plan',
  },
];

const KingMultimonthPlanSelectionForm = ({ intake }) => {
  const kingV2Offered = intake.get('king_v2_offered');

  const { values } = useFormikContext<{
    multimonth_plan: number;
    selected_king_v2_product: string;
  }>();
  const selectedSubProduct = (kingV2Offered && values.selected_king_v2_product) || KingV2SubProducts.Enclo;
  const experimentVariation = useExperiment('testosterone_pricing', 'testosterone_pricing_variation_0');

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.King}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={MultimonthConfig}
      priceConfig={kingMonthlyPriceExperiment(experimentVariation)}
    />
  );
};

export default KingMultimonthPlanSelectionForm;
