import React, { FC } from 'react';
import type { FieldProps } from 'formik';

const FieldError: FC<FieldProps<any>> = ({ field, form }) => {
  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  if (!error || !touched) {
    return null;
  }

  const errorMessage = typeof error == 'string' ? error : String(error);

  return <div>{errorMessage}</div>;
};

export default FieldError;
