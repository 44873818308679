import React from 'react';
import { CustomerUserImm, MagicianIntake, MagicianProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import MagicianProductSelectionForm from 'app/components/customer/steps/Payment/magician/MagicianProductSelectionForm';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import MagicianPriceCalculator from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import PriceCalculator from 'app/helpers/priceCalculator';
import { useAppSelector } from 'app/helpers/hooks';
import { selectMagicianDiagnoses2Attr, selectMagicianDiagnosesAttr } from 'app/selectors/customer';
import MagicianMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/magician/MagicianMultimonthPlanSelectionForm';
import { useExperiment } from 'app/utils/useExperiment';
import PageHeader from 'app/components/common/PageHeader';
import { push } from 'connected-react-router/immutable';

const SINGLE_PLAN_VARIATIONS = ['weight_loss_pricing_q1_25_variation_1', 'weight_loss_pricing_q1_25_variation_2'];

class MagicianCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<MagicianIntake>;
  product: ImmutableMap<MagicianProduct>;

  constructor(
    customer: CustomerUserImm,
    intake: ImmutableMap<MagicianIntake>,
    product: ImmutableMap<MagicianProduct>,
    isOnboarding: boolean,
  ) {
    super(customer, intake, isOnboarding);

    this.intake = intake;
    this.product = product;
  }

  currentProductName = () => AvailableProducts.Magician;

  pricingExperimentQ125 = () =>
    useExperiment('weight_loss_pricing_q1_25', 'weight_loss_pricing_q1_25_variation_0', this.customer);

  firstStep = () => {
    return this.customer.get('tirzepatide_available_for_customers')
      ? updateSubscriptionSteps.productStep
      : SINGLE_PLAN_VARIATIONS.includes(this.pricingExperimentQ125())
        ? updateSubscriptionSteps.paymentStep
        : updateSubscriptionSteps.planStep;
  };

  secondStep = () => {
    return SINGLE_PLAN_VARIATIONS.includes(this.pricingExperimentQ125())
      ? updateSubscriptionSteps.paymentStep
      : updateSubscriptionSteps.planStep;
  };

  defaultMultimonthPlan = () => {
    switch (this.pricingExperimentQ125()) {
      case 'weight_loss_pricing_q1_25_variation_1':
        return 1;
      case 'weight_loss_pricing_q1_25_variation_2':
        return 3;
      default:
        return 3;
    }
  };

  productSpecificInitialValues = () => {
    return {
      drug_variant: this.product?.get('opt_in_choice')?.get('drug_variant') || MagicianSubProducts.Semaglutide,
      lab_kit_type: this.customer.get('king_lab_kit_type'),
      multimonth_plan: this.product?.get('opt_in_choice')?.get('multimonth_plan') || this.defaultMultimonthPlan(),
      starter_pack: this.product?.get('opt_in_choice')?.get('starter_pack'),
    };
  };

  multimonthEnabled = () => !!this.intake.get('magician_multimonth_enabled');

  updateSubProductCommandName = 'select_magician_drug_variant';

  multimonthPlansOptions = (_subProductName) => [
    {
      value: 1,
      label: 'Monthly Plan',
    },
    {
      value: 3,
      label: 'Quarterly Plan',
    },
  ];

  selectProductStepPath = () => 'sub_product_selection';
  selectPlanStepPath = () => 'multimonth_plan_selection';

  renderProductStep = (firstTimeChoice: boolean = false, handleSubmit: any) => (
    <MagicianProductSelectionForm
      firstTimeChoice={firstTimeChoice}
      handleSubmit={handleSubmit}
      multimonth_plan={this.productSpecificInitialValues()['multimonth_plan']}
      starter_pack={this.productSpecificInitialValues()['starter_pack']}
    />
  );

  renderMultimonthStep = (_handleChangeProduct, firstTimeChoice) => (
    <MagicianMultimonthPlanSelectionForm firstTimeChoice={firstTimeChoice} />
  );

  renderPlanSelection = (_onMultimonthUpdate, _subProductName) => null;

  priceCalculator = (values: any, subscription_paid: boolean = false, lab_paid: boolean = false) => {
    const magicianPriceCalculator = new MagicianPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      this.intake.get('name'),
      values.use_own_lab,
      values.multimonth_plan,
      values.drug_variant,
      this.isOnboarding,
      this.crlFeatureEnabledEncloPregWeightLoss(),
      subscription_paid,
      lab_paid,
      this.productSpecificInitialValues()['starter_pack'],
    );

    return new PriceCalculator(magicianPriceCalculator, this.product.get('discounts'));
  };

  showLabKitOnboarding = () =>
    this.isOnboarding &&
    (useAppSelector(selectMagicianDiagnosesAttr('high_blood_pressure')) ||
      useAppSelector(selectMagicianDiagnoses2Attr('kidney_disease')));

  showChangeProductButton = () => this.customer.get('tirzepatide_available_for_customers');

  subProductName = (values) => values.drug_variant;

  subProductNameForIntakeCheckout = () =>
    this.product?.get('opt_in_choice')?.get('drug_variant') || MagicianSubProducts.Semaglutide;

  useOwnLab = () => !!this.customer.get('magician_use_own_lab');

  showMultimonthPlansOptions = () => !SINGLE_PLAN_VARIATIONS.includes(this.pricingExperimentQ125());

  renderProductStepHeader = (_values) => (
    <PageHeader
      title={`Choose Your ${this.product?.get('opt_in_choice')?.get('starter_pack') ? 'Starter Pack' : 'Protocol'}`}
    />
  );

  enableBuildingBlocks = () =>
    this.product?.get('opt_in_choice')?.get('multimonth_plan') > 0 && window.enableMagicianBuildingBlock == '1';

  checkoutBreadcrumbs = (dispatch, onPlanChange: any = null, onProductChange: any = null): any => {
    onProductChange ||= () => dispatch(push(this.selectProductStepPath()));
    onPlanChange ||= () => dispatch(push(this.selectPlanStepPath()));

    return (
      <p>
        {this.customer.get('tirzepatide_available_for_customers') && (
          <a data-testid="change-product" onClick={onProductChange}>
            Change Product
          </a>
        )}
        {this.multimonthEnabled() && this.product?.get('opt_in_choice')?.get('multimonth_plan') > 0 && (
          <>
            {this.customer.get('tirzepatide_available_for_customers') ? ' | ' : ''}
            <a data-testid="change-plan" onClick={onPlanChange}>
              Change Plan
            </a>
          </>
        )}
      </p>
    );
  };
}

export default MagicianCheckoutHelper;
