import * as Routes from './Routes';

export const LOGGED_IN_TOP = [
  {
    title: 'Dashboard',
    path: Routes.Dashboard,
    type: 'internal-link',
  },
  {
    title: 'Settings',
    path: Routes.Settings,
    type: 'internal-link',
  },
  {
    title: 'Notifications',
    path: Routes.NotificationCenter,
    type: 'internal-link',
  },
  {
    title: 'Contact Us',
    path: Routes.ContactUs,
    type: 'internal-link',
  },
  {
    title: 'Logout',
    testId: 'logout-button',
    type: 'logout',
  },
];
export const LOGGED_OUT_TOP = [
  {
    title: 'Login',
    path: Routes.Login,
    type: 'internal-link',
  },
  {
    title: 'Sign-Up',
    path: Routes.ProductSelection,
    type: 'external-link-redirect',
  },
];
export const BOTTOM_LINKS = [
  {
    title: 'Accessibility',
    path: Routes.AccessibilityStatement,
    type: 'external-link',
  },
  {
    title: 'Terms',
    path: Routes.TermsOfUse,
    type: 'external-link',
  },
  {
    title: 'Privacy',
    path: Routes.PrivacyPolicy,
    type: 'external-link',
  },
];
