import React, { createContext, useState, useContext, useMemo, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import pick from 'lodash/pick';
import defaults from 'lodash/defaults';
import isPlainObject from 'lodash/isPlainObject';
import { SanitizeHTML } from 'app/utils';
import './css/Snackbar.scss';

export enum SnackbarPositions {
  TopLeft = 'top-left',
  TopCenter = 'top-center',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomCenter = 'bottom-center',
  BottomRight = 'bottom-right',
}

export enum SnackbarTypes {
  Info = 'info',
  Success = 'success',
  Error = 'error',
}

export interface ISnackbarOptions {
  message?: string | JSX.Element;
  duration?: number;
  interval?: number;
  position?: SnackbarPositions;
  type?: SnackbarTypes;
  style?: React.CSSProperties;
  closeStyle?: React.CSSProperties;
}

export type SnackbarProps = ISnackbarOptions['message'] | ISnackbarOptions;

export interface ISnackbarContext {
  openSnackbar: (opts: ISnackbarOptions) => void;
  closeSnackbar: () => void;
}

export interface ISnackbarMethods extends ISnackbarContext {
  openSnackbar: (options?: SnackbarProps) => void;
}

export const SnackbarDefaults: Required<ISnackbarOptions> = {
  message: '',
  duration: 8000,
  interval: 250,
  position: SnackbarPositions.BottomCenter,
  type: SnackbarTypes.Info,
  style: {},
  closeStyle: {},
};

const SnackbarContext = createContext<ISnackbarContext | null>(null);

const sanitizeOptions = (...opts: Array<SnackbarProps | undefined>): Required<ISnackbarOptions> => {
  const options = opts
    .map((opt = {}) => (isPlainObject(opt) ? opt : { message: opt }))
    .map((opt) => pick(opt, Object.keys(SnackbarDefaults)));

  return defaults({}, ...options, SnackbarDefaults);
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<null | ReturnType<typeof setTimeout>>(null);
  const [options, setOptions] = useState<ISnackbarOptions>(SnackbarDefaults);

  const context = useMemo<ISnackbarContext>(
    () => ({
      openSnackbar: (opts) => {
        if (open) setOpen(false);
        setTimeout(
          () => {
            setOptions(opts);
            setOpen(true);
          },
          open ? opts.interval : 0,
        );
      },
      closeSnackbar: () => {
        setOpen(false);
      },
    }),
    [open],
  );

  return (
    <SnackbarContext.Provider value={context}>
      {children}

      <CSSTransition
        in={open}
        timeout={300}
        mountOnEnter
        unmountOnExit
        onEnter={() => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          setTimeoutId(setTimeout(() => setOpen(false), options.duration));
        }}
        className={cx('snackbar-wrapper', `snackbar-wrapper--${options.position}`)}
        classNames={{
          enter: cx('snackbar--enter', `snackbar--enter-${options.position}`),
          enterActive: cx('snackbar--enter-active', `snackbar--enter-active-${options.position}`),
          exitActive: cx('snackbar--exit-active', `snackbar--exit-active-${options.position}`),
        }}
      >
        <div data-testid="snackbar">
          <div role="alert" className={cx('snackbar', `snackbar--${options.type}`)} style={options.style}>
            {typeof options.message === 'string' ? (
              <SanitizeHTML html={options.message} className="snackbar__message" />
            ) : (
              options.message
            )}
            <button
              onClick={context.closeSnackbar}
              className="snackbar__close"
              style={options.closeStyle}
              title="Close"
              tabIndex={0}
              type="button"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </CSSTransition>
    </SnackbarContext.Provider>
  );
};

const CloseIcon = () => (
  <svg role="img" aria-label="Close" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="currentColor"
      d="M11.73 1.58L7.31 6l4.42 4.42-1.06 1.06-4.42-4.42-4.42 4.42-1.06-1.06L5.19 6 .77 1.58 1.83.52l4.42 4.42L10.67.52z"
    />
  </svg>
);

export const useSnackbar = (initProps?: SnackbarProps): ISnackbarMethods => {
  const { openSnackbar, closeSnackbar } = useContext(SnackbarContext) || {};
  if (!openSnackbar || !closeSnackbar) {
    throw new Error('Snackbar must be used within a SnackbarProvider');
  }

  return {
    openSnackbar(openProps?: SnackbarProps) {
      openSnackbar(sanitizeOptions(openProps, initProps));
    },
    closeSnackbar,
  };
};

export const withSnackbar = <P extends ISnackbarMethods>(
  Component: React.ComponentType<P>,
  opts?: ISnackbarOptions,
) => {
  const WithSnackbar = (props: Omit<P, keyof ISnackbarMethods>) => {
    const { openSnackbar, closeSnackbar } = useSnackbar(opts);

    return <Component {...(props as P)} openSnackbar={openSnackbar} closeSnackbar={closeSnackbar} />;
  };

  WithSnackbar.displayName = `withSnackbar(${Component.displayName || Component.name || 'Component'})`;

  return WithSnackbar;
};
