import React from 'react';
import { useQuery } from 'app/helpers/hooks';
import HomePage from 'app/components/customer/dashboard/HomePage/HomePage';
import ProtocolPage from 'app/components/customer/dashboard/HomePage/ProtocolPage';

const Dashboard = (_props) => {
  const query = useQuery();
  const protocol = query.get('product');

  return protocol ? <ProtocolPage protocolName={protocol.toString()} /> : <HomePage />;
};

export default Dashboard;
