import React, { ComponentProps, FC } from 'react';
import { useField } from 'formik';
import { FormCheckbox } from 'mui';

export const Checkbox: FC<
  {
    id?: string;
    name: string;
  } & ComponentProps<typeof FormCheckbox>
> = ({ id, name, ...restProps }) => {
  const [{ value, onChange, onBlur }] = useField({ id, name });

  return (
    <FormCheckbox id={id} name={name} testid={id} checked={value} onChange={onChange} onBlur={onBlur} {...restProps} />
  );
};
