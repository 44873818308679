export type Protocol = {
  slug: string;
  name: string;
  upsell: {
    title: string;
    description: string;
    link: string;
    image: string;
  };
};

export type ProtocolProps = {
  protocol: Protocol;
};

export const PROTOCOLS: Protocol[] = [
  {
    slug: 'king',
    name: 'Testosterone',
    upsell: {
      title: 'Unlock your optimal testosterone health',
      description: 'Discover personalized products to support your unique testosterone health journey.',
      link: 'https://www.maximustribe.com/testosterone',
      image: 'prod-king-enclomiphene',
    },
  },
  {
    slug: 'lover',
    name: 'Blood Flow',
    upsell: {
      title: 'The new way to treat ED',
      description:
        'This protocol extends your duration, reduces refractory period, and leads to greater satisfaction for your partner.',
      link: 'https://www.maximustribe.com/blood-flow-protocol',
      image: 'prod-lover-vardenafil_tadalafil',
    },
  },
  {
    slug: 'warrior',
    name: 'Hair Growth',
    upsell: {
      title: 'Great Hair Starts with Maximus',
      description: 'Get tailored prescriptions to customize your hair growth journey.',
      link: 'https://www.maximustribe.com/hair-growth',
      image: 'prod-warrior-oral_dutasteride_topical_minoxidil',
    },
  },
  {
    slug: 'magician',
    name: 'Weight Loss',
    upsell: {
      title: 'Science-first weight loss program',
      description:
        'GLP-1 is a hormone that regulates blood sugar levels and appetite. It is released after meals and affects the pancreas, stomach, and brain.',
      link: 'https://www.maximustribe.com/weight-loss',
      image: 'prod-magician-semaglutide',
    },
  },
];
