import React, { FC, ReactNode } from 'react';
import './css/PageHeader.scss';

const PageHeader: FC<{
  title: ReactNode;
  children?: ReactNode;
}> = ({ title, children }) => {
  return (
    <>
      {!title && children}
      {title && <h3>{title}</h3>}
    </>
  );
};

export default PageHeader;
