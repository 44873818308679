import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormField } from 'mui';

const TextArea = ({ id, name, ...restProps }) => {
  const [{ value }] = useField({ id, name });
  const { setFieldValue } = useFormikContext();
  const onChange = ({ target: { value } }) => setFieldValue(name, value);

  return (
    <FormField {...restProps} multiline inputClassName="min-h-[5rem]" testid={name} value={value} onChange={onChange} />
  );
};

export default TextArea;
