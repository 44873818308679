import React, { useState, useMemo, FC, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Question from 'app/constants/Question';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import { getComponentForQuestion, getDefaultValue } from 'app/components/customer/questions/helpers';
import { getIntakeSchema } from 'app/helpers/validators';
import { AvailableProducts } from 'app/constants/Products';
import protocolNames from 'app/utils/protocolNames';
import { Card, Badge } from 'mui';
import { trackEvent } from 'app/utils/useCommonTracking';
import { SEGMENT_WARRIOR_EVENTS } from 'app/constants/SegmentEvents';

const INITIAL_STEPS = ['current_hair'];

const getNextStep = (steps, currentIndex, values) => {
  const nextStep = steps[currentIndex + 1];

  if (!nextStep) {
    return null;
  }

  if (values.current_hair === 'never' && ['current_hair'].includes(nextStep)) {
    return getNextStep(steps, currentIndex + 1, values);
  }

  return nextStep;
};

const Step0 = ({ onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(INITIAL_STEPS[0]);
  const questions = useMemo(
    () => INITIAL_STEPS.reduce((o, q) => o.set(q, new Question(q)), new Map()),
    [INITIAL_STEPS],
  );
  const [initialValues, setInitialValues] = useState(
    INITIAL_STEPS.reduce((o, q) => ({ ...o, [q]: getDefaultValue({ question: questions.get(q) }) }), {}),
  );
  const position = INITIAL_STEPS.indexOf(currentStep);
  const total = questions.size;
  useEffect(() => {
    if (currentStep === 'current_hair') {
      trackEvent(SEGMENT_WARRIOR_EVENTS.VIEWED_HAIR_DESCRIPTION_PAGE, {});
    }
  }, [currentStep]);

  const question = questions.get(currentStep);
  const IntakeSchema = getIntakeSchema(question);
  const Component = getComponentForQuestion({ question }) as FC<{
    question: any;
    name: string;
    checkedImage: typeof checkboxCheckedImage;
    uncheckedImage: typeof checkboxUncheckedImage;
    contained: boolean;
  }>;

  const submitNextStep = (values) => {
    const nextStep = getNextStep(INITIAL_STEPS, INITIAL_STEPS.indexOf(currentStep), values);
    if (currentStep === 'current_hair') {
      trackEvent(SEGMENT_WARRIOR_EVENTS.COMPLETED_HAIR_DESCRIPTION_PAGE, { answer: values.current_hair });
    }
    if (!nextStep) {
      const clearValues = Object.entries(values).reduce((o, [k, v]) => {
        if (v !== '') {
          o[k] = v;
        }

        return o;
      }, {});
      onSubmit(clearValues);
    } else {
      setInitialValues(values);
      setCurrentStep(nextStep);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitNextStep} validationSchema={IntakeSchema} enableReinitialize>
      {() => (
        <Form>
          <div className="ml-10 sm:ml-5 mb-3 lg:mb-4">
            <h3>Let’s find the best protocol</h3>
            <p>Just answer a few questions and we’ll help you find the best protocol to fit your needs.</p>
          </div>
          <Card>
            <Card.Body>
              <div className="flex flex-row justify-between">
                <Badge variant={AvailableProducts.Warrior}>{protocolNames[AvailableProducts.Warrior]}</Badge>
                <span>
                  {position + 1} of {total}
                </span>
              </div>
              <p>{question.sentence}</p>
              {question.data.description && (
                <div>
                  {question.data.description.includes('Why are we asking this?') && <b>Why are we asking this?</b>}
                  <p className="pre-wrap text-sm">{question.data.description.split('\n\n').pop()}</p>
                </div>
              )}
              <div className="component">
                <Component
                  question={question}
                  name={currentStep}
                  checkedImage={checkboxCheckedImage}
                  uncheckedImage={checkboxUncheckedImage}
                  contained={false}
                />
              </div>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default Step0;
