import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import RadioQuestion from 'app/components/customer/questions/Radio';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import Question from 'app/constants/Question';
import { Badge, Card as MxCard, PageWrapper, ProgressBar as MxPb } from 'mui';
import protocolNames from 'app/utils/protocolNames';
import TextArea from 'app/components/common/formik/TextArea';

const LoverRefillConditions = ({
  dispatch,
  previousStepAction,
  position,
  total,
  intake,
  hideBackButton,
  questionName,
  initialValues,
  onSuccessActionCreator,
  productName,
}) => {
  const redirectPrevious = () => dispatch(previousStepAction);
  const onSubmit = (params) => {
    const name = 'conditions_and_medications';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessActionCreator, form: name },
    });
    dispatch(action);
  };
  const refillConditionsQuestion = useMemo(() => new Question(questionName), [questionName]);

  return (
    <PageWrapper>
      <MxCard>
        <MxCard.Body>
          <MxPb progress={(100 * position) / total} />
          <div className="flex flex-row justify-between">
            <Badge variant={productName}>{protocolNames[productName]}</Badge>
            <span>
              {position + 1} of {total}
            </span>
          </div>
          <p>
            <strong>{refillConditionsQuestion.sentence}</strong>
          </p>
          <Formik
            initialValues={{
              [questionName]: initialValues[questionName],
              [`${questionName}_description`]: initialValues[`${questionName}_description`],
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                <div className="flex flex-col gap-y-4 lg:gap-y-6">
                  <RadioQuestion
                    name={questionName}
                    submit={(current_answer: string) => {
                      return current_answer.toLowerCase() != 'yes';
                    }}
                  />
                </div>
                {values[questionName] === 'yes' && (
                  <div className="text-area-question" key={refillConditionsQuestion.name}>
                    <TextArea
                      onChange={({ target: { value: content } }) => {
                        setFieldValue(`${questionName}_description`, content);
                      }}
                      data-testid="other-textarea"
                      defaultValue={values[`${questionName}_description`]}
                      name={`${questionName}_description`}
                      id={`${questionName}_description`}
                      placeholder="Let us know here..."
                    />
                  </div>
                )}
                <BackNextFooter
                  noBack={hideBackButton}
                  onNext={handleSubmit}
                  onBack={redirectPrevious}
                  testId="submit-medical-conditions"
                />
              </>
            )}
          </Formik>
        </MxCard.Body>
      </MxCard>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  const productName = selectors.selectCurrentIntakeProduct(state);
  const previousStepAction = push(selectors.selectPreviousStepPath(state));
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));
  const prevStep = selectors.selectPreviousStep(state)?.toJS();
  const hideBackButton = !prevStep?.revisitable;
  const currentStrictlyIntakeSteps = selectors.selectStrictlyProductIntakeStepsByLastIntake(state, productName);
  const position = (selectors.selectCurrentIntakeStepIndex(state) as number) + 1;
  const total = currentStrictlyIntakeSteps.size;
  const questionName = selectors.selectCurrentIntakeStep(state).get('name');
  const initialValues = selectors.selectInitialIntakeFormValues(state).toJS();

  return {
    position,
    total,
    intake: selectors.selectCurrentIntake(state),
    previousStepAction,
    hideBackButton,
    questionName,
    initialValues,
    onSuccessActionCreator,
    productName,
  };
};

export default connect(mapStateToProps)(LoverRefillConditions);
