import React from 'react';
import { Formik, Form } from 'formik';
import Input from 'app/components/common/formik/Input';
import { RegistrationSchemaStep3 } from 'app/helpers/validators';
import { useSegmentTracking } from 'app/utils/useSegmentTracking';
import { SEGMENT_KING_EVENTS } from 'app/constants/SegmentEvents';
import { Button, Card } from 'mui';

const NameStep = ({ initialValues, onSubmit, trackSegmentEvent = false }) => {
  useSegmentTracking(trackSegmentEvent ? SEGMENT_KING_EVENTS.VIEWED_NAME_ENTRY : undefined);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={RegistrationSchemaStep3}>
      {({ isSubmitting }) => (
        <Form>
          <Card>
            <Card.Body>
              <div>
                <h3 className="mb-2">Enter Name</h3>
                <p>Used by doctor and pharmacy for personalized treatment.</p>
              </div>

              <div className="flex flex-col gap-y-2">
                <div className="inputs__item">
                  <Input
                    id="first_name"
                    name="first_name"
                    placeholder="Enter first name"
                    label="First Name"
                    className="text-field"
                  />
                </div>

                <div className="inputs__item">
                  <Input
                    id="last_name"
                    name="last_name"
                    placeholder="Enter last name"
                    label="Last Name"
                    className="text-field"
                  />
                </div>
              </div>

              <Button disabled={isSubmitting} type="submit" data-testid="registration-step-3-submit">
                Next
              </Button>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default NameStep;
