import React, { createContext, useEffect } from 'react';

const RedesignContext = createContext(false);

const DISABLED_PATHS = ['admin'];

export const RedesignProvider = ({ children }) => {
  const disabled = DISABLED_PATHS.some((str) => window.location.pathname.includes(str));
  const isNewVersion = !disabled;

  useEffect(() => {
    const element = document.querySelector('html');

    const htmlClass = isNewVersion ? 'new-theme' : 'old-theme';
    element?.classList?.toggle(htmlClass);
  }, [isNewVersion]);

  return <RedesignContext.Provider value={isNewVersion}>{children}</RedesignContext.Provider>;
};
