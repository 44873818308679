import { P } from 'app/components/common/Typography';
import React, { useState } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import ModalBodySubscriptionMonthlyInstallment from 'app/components/customer/Checkout/ModalBodySubscriptionMonthlyInstallment';

const GenericSubscriptionSummaryMonthlyInstallment = ({ multimonthPlan }) => {
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const period = multimonthPlan == 3 ? 'quarter' : 'year';

  return (
    <>
      <div className="bg-main-grey rounded-xl p-4 lg:p-8">
        <div className="text-xs">
          <P className="pb-4">
            Your plan bills every 30 days, but as a {period}ly plan, you’ll continue to be charged and receive your
            medication for the full {period}, even if you cancel midway. Unless canceled before the term ends, your plan
            will automatically renew for another {period}.
          </P>
          <P className="pb-4">Medication ships monthly to allow for treatment customization.</P>
          <P>
            <button className="link" onClick={() => setOpenModalSubscription(true)}>
              Learn more
            </button>
          </P>
        </div>
      </div>
      <SetProductDialog
        isOpen={openModalSubscription}
        title="Billing Details"
        text={
          <ModalBodySubscriptionMonthlyInstallment
            setOpenModalSubscription={setOpenModalSubscription}
            subscriptionPeriodDays={30}
            multimonthPlan={multimonthPlan}
          />
        }
        onClose={() => setOpenModalSubscription(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default GenericSubscriptionSummaryMonthlyInstallment;
