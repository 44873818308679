import React, { FC } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/helpers/hooks';
import { Wrap } from 'app/components/common/Wrap';
import { Animate } from 'app/components/customer/steps/Animate';
import * as selectors from 'app/selectors/customer';
import { ComingSoon, createIntake, ProductDashboard } from 'app/constants/Routes';
import Steps from './steps';
import BlackQuestions from './questions';
import { selectStrictlyProductIntakeStepsByLastIntake } from 'app/selectors/customer';

const Intake: FC<{
  intakeName: string;
}> = ({ intakeName }) => {
  const isAnimationEnabled = localStorage.getItem('enabledAnimation') === 'true';
  const location = useLocation();
  const steps = useAppSelector(selectors.selectStrictlyIntakeSteps).map((step) => step.get('name'));
  const allSteps = useAppSelector(selectors.selectCurrentSteps);
  const intake = useAppSelector(selectors.selectCurrentIntake);
  const productName = useAppSelector(selectors.selectCurrentIntakeProduct);
  const productFlags = useAppSelector((state) => selectors.selectProductFlags(state, productName));
  const stepObject = useAppSelector(selectors.selectCurrentStepObject);

  const currentStrictlyIntakeSteps = useAppSelector((state) =>
    selectStrictlyProductIntakeStepsByLastIntake(state, productName),
  );

  let position = 0;
  if (stepObject) {
    position = currentStrictlyIntakeSteps.findIndex((s) => s.get('name') === stepObject.get('name')) + 1;
  }
  const total = currentStrictlyIntakeSteps.size;

  const furthestStepUrl = useAppSelector((state) => selectors.selectFurthestProductStepPath(state, productName));

  return (
    <Wrap if={isAnimationEnabled} with={Animate} wrapperProps={{ location, className: 'centered' }}>
      <Switch location={location}>
        {allSteps.map((stepObject) => (
          <Route
            key={stepObject.get('name')}
            path={createIntake(intakeName, selectors.urlFriendlyName(stepObject))}
            exact
            render={() => {
              const step = stepObject.get('name');
              if (intake.get('type') === 'lover_onboarding') {
                // TODO: we need fix it for King Onboarding too
                const pricySteps = ['payment', 'location_confirmation', 'identity_verification', 'checkpoint'];

                const pricyStep = pricySteps.includes(stepObject && stepObject.get('name'));
                const notEligible =
                  productFlags.includes('location_rejected') || productFlags.includes('health_rejected');

                const ineligible = pricyStep && notEligible;
                if (ineligible) {
                  return <Redirect to={ComingSoon} />;
                }
              }
              const steps_without_generic_component = ['freeform', 'id_review', 'schedule_appointment'];

              const Component: FC<any> =
                steps.includes(step) && !steps_without_generic_component.includes(step) ? BlackQuestions : Steps;

              return <Component step={step} position={position} total={total} />;
            }}
          />
        ))}
        <Redirect to={furthestStepUrl || ProductDashboard(productName)} />
      </Switch>
    </Wrap>
  );
};

export default Intake;
