import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Login } from 'app/constants/Routes';
import { Button } from 'mui';

const BeenHereBefore: FC<{
  setShowLoginModal: (show: boolean) => void;
}> = ({ setShowLoginModal }) => {
  const history = useHistory();

  return (
    <div>
      <Button onClick={() => history.push(Login)} type="button" className="mb-4">
        Login
      </Button>
      <Button variant="text" onClick={() => setShowLoginModal(false)}>
        Create an account
      </Button>
    </div>
  );
};

export default BeenHereBefore;
