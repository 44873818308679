/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Success from 'images/text-input/Success.svg';
import Failure from 'images/text-input/Failure.svg';
import cx from 'classnames';
import { useField } from 'formik';
import FormInput from 'app/components/common/formik/FormInput';
import useWindowDimensions from 'app/utils/useWindowDimensions';
import defaultTheme from 'mui/tailwind.config';
import 'app/components/common/css/TextInput.scss';

export interface SpreedlyPaymentFrameType {
  transferFocus: (name: string) => void;
  setTitle: (name: string, title: string) => void;
  setStyle: (name: string, style: string) => void;
  setNumberFormat: (format: string) => void;
  setPlaceholder: (name: string, type: string) => void;
  setFieldType: (name: string, type: string) => void;
  on: (name: string, callback: (name, type, _, inputProperties) => void) => void;
  init: (apiKey: string, options: any) => void;
  unload: () => void;
  removeHandlers: () => void;
  tokenizeCreditCard: (options: any) => void;
}

const SpreedlyCardInput = ({ onInit }: { onInit: (spreedly: SpreedlyPaymentFrameType | null) => void }) => {
  const expiryRef = React.useRef<HTMLInputElement>(null);
  const [numberFocused, setNumberFocused] = useState(false);
  const [cvvFocused, setCvvFocused] = useState(false);
  const [speedlyIframe, setSpeedlyIframe] = useState<SpreedlyPaymentFrameType | null>(null);
  const [numberError, setNumberError] = useState<string | undefined>('Invalid Card Number');
  const [cvvError, setCvvError] = useState<string | undefined>('Invalid CVV');
  const [_numberField, numberFieldState, numberFieldFunc] = useField({
    name: 'cardNumber',
    validate: () => numberError,
  });
  const [cvvField, cvvFieldState, cvvFieldFunc] = useField({ name: 'cvv', validate: () => cvvError });

  const { width } = useWindowDimensions();
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setIsDesktop((width || 0) >= parseInt(defaultTheme.theme.screens.lg));
  }, [width]);
  const [, expiryDateFieldState, expiryDateFieldFunc] = useField({
    name: 'expiryDate',
    defaultValue: '',
    validate: (value) => {
      if (!value) {
        return 'Required';
      }
      const [month, year] = value.split('/');
      if (!month || !year || year.length !== 2 || month.length !== 2) {
        return 'Invalid expiration Date';
      }
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (Number('20' + year) < currentYear) {
        return 'Expiration date is in the past';
      }
      if (Number('20' + year) === currentYear && Number(month) < currentMonth) {
        return 'Expiration date is in the past';
      }
    },
  });
  useEffect(() => {
    onInit(speedlyIframe);
  }, [speedlyIframe]);

  useEffect(() => {
    setSpeedlyIframe(null);
    const otherIFrame: SpreedlyPaymentFrameType = new (window as any).SpreedlyPaymentFrame();

    otherIFrame.init((window as any).spreedlyEnvironmentKey, {
      numberEl: 'spreedly-number',
      cvvEl: 'spreedly-cvv',
    });
    otherIFrame.on('ready', function () {
      setSpeedlyIframe(otherIFrame);

      otherIFrame.setFieldType('number', 'tel');
      otherIFrame.setNumberFormat('prettyFormat');
      otherIFrame.setPlaceholder('number', '1234 1234 1234 1234');
      otherIFrame.setPlaceholder('cvv', 'CVV');
      otherIFrame.on('fieldEvent', function (name, type, _, inputProperties) {
        if (name == 'number') {
          if (type == 'focus') {
            setNumberFocused(true);
          }
          if (type == 'blur') {
            setNumberFocused(false);
            numberFieldFunc.setTouched(true, false);
          }
          if (type == 'input') {
            setNumberError(inputProperties['validNumber'] ? undefined : 'Invalid Card Number');
          }
        }
        if (name == 'cvv') {
          if (type == 'focus') {
            setCvvFocused(true);
          }
          if (type == 'blur') {
            setCvvFocused(false);
            cvvFieldFunc.setTouched(true, false);
          }
          if (type == 'input') {
            setCvvError(inputProperties['validCvv'] ? undefined : 'Invalid CVV');
            cvvFieldFunc.setValue(cvvField.value, true);
          }
        }
      });
    });
    return () => {
      otherIFrame.unload();
    };
  }, []);

  useEffect(() => {
    const fontSize = !isDesktop ? '14px' : '16px';
    const lineHeight = isDesktop ? '24px' : '20px';
    const height = isDesktop ? '24px' : '20px';
    const fw = 'font-weight: 300;';

    speedlyIframe?.setStyle(
      'number',
      `${fw} font-size: ${fontSize}; font-family: Inter, sans-serif; background-color: white; line-height: ${lineHeight}; text-align: 'center'; height: ${height}; width: 100%`,
    );
    speedlyIframe?.setStyle(
      'cvv',
      `${fw} font-size: ${fontSize}; font-family: Inter, sans-serif; background-color: white; line-height: ${lineHeight}; text-align: 'center'; height: ${height};`,
    );
    speedlyIframe?.setStyle('placeholder', 'color: #a9b6ff;');
  }, [isDesktop, speedlyIframe]);

  return (
    <>
      <div className="mt12">
        <label className="hs2-text-input__label medium" onClick={() => speedlyIframe?.transferFocus('number')}>
          Card Number
        </label>
        <div className="hs2-text-input__container form-field-container">
          <div className="form-field-input-holder mt-2">
            <div
              id="spreedly-number"
              onClick={() => speedlyIframe?.transferFocus('number')}
              className={cx('form-field-input h-[44px] lg:h-[48px] py-0', {
                failed: numberFieldState.touched && !!numberError,
                valid: numberFieldState.touched && !numberError,
                focus: numberFocused,
              })}
            >
              {numberFieldState.touched &&
                (!numberError ? (
                  <img className="hs2-text-input__state" src={Success} alt="Success" />
                ) : (
                  <img className="hs2-text-input__state" src={Failure} alt="Failure" />
                ))}
            </div>
          </div>
        </div>
        {numberFieldState.touched && numberError && <div className="hs2-text-input__error medium">{numberError}</div>}
      </div>
      <div className="mt12">
        <div className="flex gap-x-4">
          <div className="flex1">
            <label htmlFor="" className="hs2-text-input__label medium">
              Expiration Date
            </label>
            <FormInput
              ref={expiryRef}
              name="expiryDate"
              type="tel"
              placeholder="MM/YY"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const prevValue = expiryDateFieldState.value || '';
                let value = (e.target.value || '').replace(/[^(\d|/)]/g, '');
                const separator = '/';
                while (value.lastIndexOf('/') !== value.indexOf(separator)) {
                  value = value.slice(0, value.lastIndexOf('/')) + value.slice(value.lastIndexOf('/') + 1);
                }
                if (value[0] === separator) {
                  value = value.slice(1);
                }
                let newValue = '';
                let secondPart;
                const [firstPart, temp] = value.split(separator);
                secondPart = temp;

                if (firstPart && firstPart.length >= 2) {
                  if (firstPart[0] === '0') {
                    if (firstPart.length > 2) {
                      newValue = firstPart.slice(0, 2) + separator + firstPart.slice(1);
                    } else {
                      newValue = firstPart + separator;
                    }
                  } else if (firstPart[0] === '1') {
                    if (firstPart[1] > '2') {
                      newValue = '0' + firstPart.slice(0, 1) + separator + firstPart.slice(1);
                    } else {
                      newValue = firstPart.slice(0, 2) + separator + firstPart.slice(2);
                    }
                  }
                } else if (firstPart && firstPart.length === 0) {
                  newValue = '';
                } else if (firstPart && firstPart.length === 1) {
                  if (firstPart[0] > '1') {
                    newValue = '0' + firstPart[0] + separator;
                  } else if (value[0] > '0' && value[1] == '/') {
                    newValue = '0' + firstPart.slice(0, 1) + separator + firstPart.slice(1);
                  } else {
                    newValue = firstPart[0];
                  }
                }
                // If year is provided by Autofill and is 4 digits long, shorten it to 2 digits
                if (secondPart && secondPart.length === 4) {
                  secondPart = secondPart.slice(2);
                }
                if (secondPart) {
                  newValue += secondPart.slice(0, parseInt(secondPart) - (newValue.length - 2 - separator.length));
                }
                if (newValue.length > 4 + separator.length) {
                  return;
                }
                if (value === prevValue.slice(0, value.length)) {
                  expiryDateFieldFunc.setValue(value, true);
                } else {
                  expiryDateFieldFunc.setValue(newValue, true);
                }
                expiryDateFieldFunc.setTouched(true, false);
              }}
            />
          </div>
          <div className="flex1">
            <label
              htmlFor="spreedly-cvv"
              className="hs2-text-input__label medium"
              onClick={() => speedlyIframe?.transferFocus('cvv')}
            >
              CVV
            </label>
            <div className="hs2-text-input__container form-field-container">
              <div className="form-field-input-holder mt-2">
                <div
                  id="spreedly-cvv"
                  onClick={() => speedlyIframe?.transferFocus('cvv')}
                  className={cx('form-field-input h-[44px] lg:h-[48px] py-0', {
                    failed: cvvFieldState.touched && !!cvvError,
                    valid: cvvFieldState.touched && !cvvError,
                    focus: cvvFocused,
                  })}
                >
                  {cvvFieldState.touched &&
                    (!cvvError ? (
                      <img className="hs2-text-input__state" src={Success} alt="Success" />
                    ) : (
                      <img className="hs2-text-input__state" src={Failure} alt="Failure" />
                    ))}
                </div>
              </div>
            </div>
            {cvvFieldState.touched && cvvError && <div className="hs2-text-input__error medium mt-1">{cvvError}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SpreedlyCardInput);
