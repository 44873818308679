import React, { useMemo, useState } from 'react';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import { connect } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import { useAppDispatch } from 'app/helpers/hooks';
import { ShippingSchemaWithoutFullName } from 'app/helpers/validators';
import { selectCustomerId, selectCustomerShippingAddress } from 'app/selectors/customer';
import { apiRequestPromise } from 'app/api';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';
import { Button, Card } from 'mui';

const mapStateToProps = (stateRedux) => {
  const shippingAddress = selectCustomerShippingAddress(stateRedux);
  const user_id = selectCustomerId(stateRedux);

  return { shippingAddress, user_id };
};

const DumbShippingCard = ({ shippingAddress, user_id }) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { setFieldValue } = useFormikContext();
  const shippingAddressValues = useMemo(() => shippingAddress?.toJS() || {}, [shippingAddress]);
  const { address_line_1, address_line_2, city, state: shippingState, postal_code } = shippingAddressValues;

  const onFormSubmitted = async (values) => {
    const { address_line_1, address_line_2, city, state, postal_code } = values;
    const params = {
      address_line_1,
      address_line_2,
      city,
      state,
      postal_code,
    };

    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'update_shipping_address',
        user_id,
        params: { ...params },
      });
      setExpanded(false);
      dispatch(apiRequestSuccess(resp));
    } catch (error) {
      const message = error instanceof Error ? error.message : 'An error occurred (DumbShippingCard#onFormSubmitted)';
      dispatch(apiRequestFailure({ message, reqId: 42, request: {} }));
    }

    for (const [k, v] of Object.entries(params)) {
      setFieldValue(k, v);
    }
  };

  return (
    <Card>
      <Card.Body>
        <h4>Shipping address</h4>
        {!expanded && (
          <>
            <address>
              <p>{address_line_1}</p>
              {address_line_2 && <p>{address_line_2}</p>}
              <p>
                {city}, {shippingState} {postal_code}
              </p>
            </address>
            <Button variant={'outline'} onClick={() => setExpanded(true)}>
              Update Address
            </Button>
          </>
        )}
        {expanded && (
          <Formik
            initialValues={shippingAddressValues}
            validationSchema={ShippingSchemaWithoutFullName}
            onSubmit={onFormSubmitted}
            enableReinitialize
          >
            {({ handleSubmit, isSubmitting }) => (
              <>
                <ShippingFields />
                <div className={'flex flex-col gap-y-2'}>
                  <Button variant={'primary'} onClick={() => handleSubmit()} disabled={isSubmitting}>
                    Update
                  </Button>
                  <Button variant={'text'} onClick={() => setExpanded(false)}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps)(DumbShippingCard);
