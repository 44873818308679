/* eslint-disable react/no-unstable-nested-components */
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { IPaymentContext, PaymentContext, PaymentContextUserInfo } from './context';
import SpreedlyPaymentContext from './SpreedlyPaymentContext';
import SpreedlyCardInput, {
  SpreedlyPaymentFrameType,
} from 'app/components/customer/steps/Payment/CardInput/Spreedly/CreditCardInput';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';
import { FormikContextType, FormikProvider, useFormik } from 'formik';

const PaymentContextProvider = ({ children }) => {
  const customer = useAppSelector(selectCustomer);
  const [isValid, setIsValid] = useState(false);

  const contextValue = useMemo((): [IPaymentContext, FC<{ children: ReactNode }>] => {
    let cardFormContext: FormikContextType<any> | null = null;
    let spreedly: SpreedlyPaymentFrameType | null = null;

    const isValid = () => {
      if (!cardFormContext) {
        return false;
      }

      return cardFormContext?.isValid;
    };

    const ctx = {
      isValid,
      paymentProvider: 'spreedly' as const,
      componentFactory: (props) => {
        const formContext = useFormik({ validateOnMount: false, initialValues: {}, onSubmit: () => {} });
        cardFormContext = formContext;
        setIsValid(isValid());
        useEffect(() => {
          return () => {
            cardFormContext = null;
          };
        }, []);
        return (
          <FormikProvider value={formContext}>
            <SpreedlyCardInput
              onInit={(spreedlyInstance) => {
                if (spreedly && spreedlyInstance !== spreedly) {
                  // Remove old handlers
                  spreedly.removeHandlers();
                }
                spreedly = spreedlyInstance;
              }}
              {...props}
            />
          </FormikProvider>
        );
      },
      createPaymentMethod: (userInfo: PaymentContextUserInfo) => {
        return new Promise((resolve, reject) => {
          if (!spreedly || !cardFormContext) {
            throw new Error('Form not initialized');
          }
          cardFormContext.setFieldError('FORM_ERROR', undefined);
          const expiryDateField = cardFormContext.getFieldMeta<string>('expiryDate');
          if (expiryDateField.error) {
            throw new Error('Invalid Expiration Date');
          }
          if (cardFormContext.getFieldMeta('cardNumber').error) {
            throw new Error('Invalid Card Number');
          }
          if (cardFormContext.getFieldMeta('cvv').error) {
            throw new Error('Invalid CVV');
          }

          try {
            const { full_name, city, state, address_line_1, address_line_2, postal_code } = userInfo;
            spreedly.on('paymentMethod', (paymentMethodId, result) => {
              if (!result.errors?.length) {
                resolve({ paymentMethodId, cardBrand: result.card_type, cardLastFour: result.last_four_digits });
              }
              reject(new Error('Something went wrong'));
            });
            spreedly.on('errors', (errors) => {
              reject(errors[0]);
            });
            spreedly.tokenizeCreditCard({
              full_name,
              month: expiryDateField.value?.split('/')[0],
              year: '20' + expiryDateField.value?.split('/')[1],

              address1: address_line_1,
              address2: address_line_2 || '',
              city,
              state,
              zip: postal_code,
            });
          } catch (e: any) {
            cardFormContext.setFieldError('FORM_ERROR', e?.message || 'Something went wrong');
            reject(e);
          }
        });
      },
    };
    return [ctx as any, ({ children }) => <SpreedlyPaymentContext>{children}</SpreedlyPaymentContext>];
  }, [customer.get('spreedly_iframes_enabled')]);
  const Wrapper = contextValue[1];

  return (
    <PaymentContext.Provider value={{ ...contextValue[0], invalid: !isValid }}>
      <Wrapper>{children}</Wrapper>
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
