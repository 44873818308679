import React, { useContext, useEffect, useRef, useState } from 'react';
import LabImage from 'images/LabImage.png';
import 'app/css/NewDesignSystem.scss';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { Formik, FormikProps } from 'formik';
import LaasShippingForm from 'app/components/customer/steps/Payment/Generic/LaasShippingForm';
import PersonalDetailForm from 'app/components/customer/steps/Payment/Generic/LaasPersonalDetailsForm';
import GenericPaymentMethod from 'app/components/customer/steps/Payment/Generic/GenericPaymentMethod';
import { BillingSchema } from 'app/helpers/validators';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import * as actions from 'app/actions/customer';
import {
  selectCustomer,
  selectCustomerShippingAddress,
  selectLatestPaymentMethodErrorMessage,
  selectCustomerProduct,
} from 'app/selectors/customer';
import LabsPriceCalculator from 'app/helpers/productSpecificPriceCalculators/labsPriceCalculator';
import { ShippingValues } from 'app/components/customer/steps/Payment/Generic/GenericShippingForm';
import PaymentContextProvider from 'app/components/customer/steps/Payment/PaymentContext/provider';
import DiscountCouponsList from 'app/components/customer/steps/Payment/DiscountCouponsList';
import CouponCodeInput from 'app/components/customer/steps/Payment/CouponCodeInput';
import { AvailableProducts, LabKitTypes, LaaSType } from 'app/constants/Products';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import { NOOP, reportBrowserProblem } from 'app/actions/customer';
import { PaymentContext } from 'app/components/customer/steps/Payment/PaymentContext/context';
import axios from 'axios';
import { getCSRF } from 'app/api';
import * as routerSelectors from 'app/selectors/router';
import { Card, PageWrapper } from 'mui';

const LabCheckoutPage = () => {
  const shippingFormRef = useRef<FormikProps<any>>(null);
  const [shippingFormValid, setShippingFormValid] = useState(false);
  const personalFormRef = useRef<FormikProps<any>>(null);
  const [personalFormValid, setPersonalFormValid] = useState(false);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const customer = useAppSelector(selectCustomer);
  const latestPaymentMethodErrorMessage = useAppSelector(selectLatestPaymentMethodErrorMessage);
  const [errorMessage, setErrorMessage] = useState('');
  const currentPaymentMethod = customer.get('payment_method');
  const dispatch = useAppDispatch();
  const paymentCtx = useContext(PaymentContext)!;
  const birthday = customer.get('questionnaire')?.get('date_of_birth');
  const sub_product = useAppSelector((state) => routerSelectors.selectRouterQuery(state).get('sub_product'));
  const idVerificationEnabled = customer.get('identity_verification_enabled');
  const showPersonalDetailForm = idVerificationEnabled ? false : !birthday;

  const base_t_kit = sub_product == LaaSType.BaseTestKit;

  const product = useAppSelector((state) => selectCustomerProduct(state, AvailableProducts.King));
  const discounts = product ? product.get('discounts') : [];
  const paymentErrorMessage = errorMessage || customer.get('payment_error_message') || latestPaymentMethodErrorMessage;
  const priceCalculator = new LabsPriceCalculator(discounts, sub_product);

  const initialValues = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    full_name: '',
    postal_code: '',
    ...shippingAddress?.toJS(),
  };

  const enableOnlyLab = async () => {
    await axios.post(
      '/api/commands',
      {
        user_id: customer.get('id'),
        cmdType: 'enable_only_lab',
        type: 'enable_only_lab',
      },
      { headers: { 'X-CSRF-Token': getCSRF() } },
    );
  };

  useEffect(() => {
    if (!customer.get('only_lab')) {
      enableOnlyLab();
    }
  }, [customer.get('only_lab')]);

  const onSubmit = async (values) => {
    try {
      // Update when apply to the rest of the protocols
      const { address_line_1, address_line_2, city, state, postal_code } = values;
      let params: { [key: string]: any } = {
        address_line_1,
        address_line_2,
        city,
        postal_code,
        state,
      };

      if (!birthday && personalFormValid && personalFormRef.current?.dirty) {
        const response = (await personalFormRef.current.submitForm()) as { type: string } | undefined;
        if (response?.type === NOOP) return;
      }

      if (shippingFormValid && shippingFormRef.current?.dirty) {
        const response = (await shippingFormRef.current.submitForm()) as { type: string } | undefined;
        if (response?.type === NOOP) return;
      }

      if (!currentPaymentMethod?.get('card_last4')) {
        let result;
        if (!values.applePayPaymentMethod) {
          const full_name = `${customer.get('first_name')} ${customer.get('last_name')}`;
          result = await paymentCtx.createPaymentMethod({ ...values, full_name });
        } else {
          result = values.applePayPaymentMethod;
        }

        const paymentData = {
          card_brand: result.cardBrand,
          card_last4: result.cardLastFour,
          payment_method_id: result.paymentMethodId,
        };
        params = { ...params, ...paymentData };
      }
      params = {
        ...params,
        payment_method_source: paymentCtx.paymentProvider,
        lab_kit_type: LabKitTypes.AtHomeLabKit,
        intake_name: 'onboarding',
        only_lab: true,
        laas_type: base_t_kit ? LaaSType.BaseTestKit : LaaSType.AdvancedTestKit,
        async: true,
      };
      const action = actions.apiRequestUserCommand({
        cmdType: 'opt_in_to_intake_payment',
        params,
        context: { onSuccessAction: push(Routes.LabCheckoutSuccess) },
      });
      dispatch(action);
    } catch (e) {
      dispatch(
        reportBrowserProblem({
          error: { code: 1 },
          problemType: `${paymentCtx.paymentProvider}_failed`,
          details: e,
        }),
      );
      setErrorMessage(e instanceof Error ? e.message : 'Error create payment method');

      return;
    }
  };

  const renderDueToday = () => (
    <>
      <h5 className="font-medium">Due today</h5>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row justify-between">
          <div>At-home {base_t_kit ? '' : 'Advanced'} Lab Kit</div>
          <div>
            {priceCalculator.totalDueToday() > 0 ? (
              <PriceFormatter price={priceCalculator.atHomeLabPrice()} />
            ) : (
              <div>Free</div>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div>Priority shipping (to you)</div>
          <div>Free</div>
        </div>
        <div className="flex flex-row justify-between">
          <div>Overnight Shipping (to the lab)</div>
          <div>Free</div>
        </div>
        <DiscountCouponsList priceCalculator={priceCalculator} />
      </div>
      <hr />
      <div className="flex flex-row justify-between">
        <h4>Total</h4>
        <PriceFormatter price={priceCalculator.totalDueToday()} />
      </div>
      <CouponCodeInput productName={AvailableProducts.King} />
    </>
  );

  const renderGenericCheckoutSection = (values, isValid, isSubmitting) => (
    <>
      {renderDueToday()}
      <GenericPaymentMethod
        paymentErrorMessage={paymentErrorMessage}
        currentPaymentMethod={currentPaymentMethod}
        onCheckout={onSubmit}
        priceCalculator={priceCalculator}
        laasFlow={true}
        shippingFormRef={shippingFormRef}
        disabled={isSubmitting || !isValid || !values.selfPay || (!shippingAddress && !shippingFormValid)}
        setOptInParams={NOOP}
        setUnavailableProductDialog={NOOP}
        bodyClassName=""
      />
    </>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BillingSchema}
      enableReinitialize={false}
      validateOnMount
    >
      {({ isSubmitting, values, isValid }: FormikProps<ShippingValues>) => (
        <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
          <h3>Purchase</h3>
          <Card>
            <Card.Body>
              <div className="product-image">
                <img src={LabImage} alt="Lab" />
              </div>
              <hr />
              <div className="flex flex-row justify-between items-center">
                <h4>Testosterone {base_t_kit ? 'Base' : 'Advanced'} Test</h4>
                <PriceFormatter price={priceCalculator.atHomeLabPrice()} />
              </div>
              <p>
                Our <b className="bold">Testosterone {base_t_kit ? '' : 'Advanced'} Test Kit</b> arrives straight to
                your door by free Priority Shipping and includes free Fed-Ex home pickup to get it to the lab.
              </p>
              <hr />
              {showPersonalDetailForm && (
                <>
                  <PersonalDetailForm personalFormRef={personalFormRef} setPersonalFormValid={setPersonalFormValid} />
                  <hr />
                </>
              )}
              <LaasShippingForm shippingFormRef={shippingFormRef} setShippingFormValid={setShippingFormValid} />
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>{renderGenericCheckoutSection(values, isValid, isSubmitting)}</Card.Body>
          </Card>
        </PageWrapper>
      )}
    </Formik>
  );
};

export default (props) => (
  <PaymentContextProvider>
    <LabCheckoutPage {...props} />
  </PaymentContextProvider>
);
