import { Checkbox } from 'app/components/common/formik/Checkbox';
import React from 'react';

interface SmsConsentProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const SmsConsent: React.FC<SmsConsentProps> = ({ onChange, checked }) => (
  <Checkbox
    topAligned
    label={
      <p>
        Allow text/SMS messages from Maximus. Message and data rates may apply. Reply STOP to opt-out.{' '}
        <a href="/terms-of-use" target="_blank">
          Terms and Privacy Policy
        </a>
      </p>
    }
    testId="receive_sms"
    name="receive_sms"
    onChange={onChange}
    checked={checked}
  />
);

export default SmsConsent;
