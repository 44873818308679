import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, WarriorSubProducts } from 'app/constants/Products';
import { ImmutableList } from 'app/types/admin';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';
import { WARRIOR_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import { WarriorSubProductsConfig } from 'app/components/customer/steps/Payment/warrior/WarriorSubProductConfig';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import { Badge, Button, Card } from 'mui';

type Props = {
  availableSubProducts: ImmutableList<WarriorSubProducts> | null;
};

const WarriorSubProductsList = ({ availableSubProducts }: Props) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto'; // Ensure scrolling works on all browsers
  }, []);

  const renderProductCard = (subProductName: any) => {
    const subProduct = WarriorSubProductsConfig[subProductName];

    return (
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.Warrior][subProductName]}
              alt="warrior v2"
            />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.Warrior} className="mb-2">
              {protocolNames[AvailableProducts.Warrior]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.Warrior][subProductName]}</h4>
          </div>
          <p>
            <b className="bold">Recommended for: </b>
            {subProduct.recommended_for}
          </p>
          <hr />
          <p className="text-center flex flex-row flex-center gap-x-1 mb-2">
            Plans starting at
            <PriceFormatter period="mo" size="small" price={WARRIOR_MONTHLY_PRICE[subProductName][3]} />
          </p>
          <Button data-testid="own-lab-modal-close" onClick={() => setFieldValue('warrior_strength', subProductName)}>
            Continue
          </Button>
        </Card.Body>
      </Card>
    );
  };

  if (!availableSubProducts) return null;

  return (
    <div className="flex flex-col gap-y-6 lg:gap-y-8">
      {availableSubProducts.map((subProductName) => renderProductCard(subProductName))}
    </div>
  );
};

export default WarriorSubProductsList;
