import React, { useRef, useEffect } from 'react';
import * as Braze from '@braze/web-sdk';
import { Switch, Redirect, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/helpers/hooks';
import { ProductProvider } from 'app/components/customer/Product/provider';
import MessagesButton from 'app/components/customer/dashboard/Messages/MessagesButton';
import BlackSettings from 'app/components/customer/Settings';
import ContactUs from 'app/components/customer/ContactUs';
import Dashboard from 'app/components/customer/dashboard';
import * as Routes from 'app/constants/Routes';
import RefillCheckpoint from 'app/components/customer/steps/RefillCheckpoint';
import BillingPreferences from 'app/components/customer/dashboard/BillingPreferences';
import ManageSubscriptions from 'app/components/customer/dashboard/ManageSubscriptions';
import GenericUpdateSubscription from 'app/components/customer/steps/Payment/Generic/GenericUpdateSubscription';
import PasswordPreferences from 'app/components/customer/dashboard/PasswordPreferences';
import BillingIssueModal from 'app/components/customer/dashboard/BillingIssueModal';
import CongratsKing from 'app/components/customer/Congrats/King';
import CongratsMagician from 'app/components/customer/Congrats/Magician';

import AppointmentPage from 'app/components/customer/AppointmentPage';
import LabCheckoutPage from 'app/components/customer/LabCheckoutPage';
import { selectCustomerId, selectLatestPaymentMethodErrorMessage } from 'app/selectors/customer';
import Intake from './Intake';

import ContinueOnboarding from './ContinueOnboarding';
import './css/Customer.scss';
import UseOwnLabStep from 'app/components/customer/steps/UseOwnLabStep';
import AnnualLabCheckout from 'app/components/customer/dashboard/AnnualLabCheckout';
import LabCheckoutSuccessPage from 'app/components/customer/LabCheckoutSuccessPage';
import { Drawer, NavBar } from 'mui';
import DrawerMenu from 'app/components/common/DrawerMenu';
import ApplySubscriptionDeal from 'app/components/customer/dashboard/ApplySubscriptionDeal';
import Messages from 'app/components/customer/dashboard/Messages/Messages';
import MessagesNew from 'app/components/customer/dashboard/Messages/MessagesNew';
import MessagesThread from 'app/components/customer/dashboard/Messages/MessagesThread';
import NotificationCenter from 'app/components/customer/dashboard/NotificationCenter';

const TrackGrowthAsync = React.lazy(() => import('app/components/customer/steps/TrackGrowth'));
const UpdateQuestionnaireAsync = React.lazy(() => import('app/components/customer/Product/UpdateQuestionnaire'));
const UpdateQuestionnaireConfirmationAsync = React.lazy(
  () => import('app/components/customer/Product/UpdateQuestionnaire/Confirmation'),
);

const Customer = () => {
  const mainRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();
  const paymentErrorMessageStripe = useAppSelector(selectLatestPaymentMethodErrorMessage);
  const customerId = useAppSelector(selectCustomerId);
  const onAllowedPages =
    !pathname.includes('billing') && !pathname.includes('payment') && !pathname.includes('confirm_shipping');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.brazeSdkApiKey && window.brazeSdkHost) {
      Braze.initialize(window.brazeSdkApiKey, {
        baseUrl: window.brazeSdkHost,
        allowUserSuppliedJavascript: true,
      });
      Braze.changeUser(customerId);
      Braze.automaticallyShowInAppMessages();
      Braze.openSession();
    }
  }, [pathname]);

  return (
    <div className="grid-container">
      <ProductProvider>
        <Drawer sideContent={<DrawerMenu loggedIn />}>
          <NavBar rightContent={<MessagesButton />} />
          <main ref={mainRef}>
            <Switch>
              <Route exact path={Routes.ClinicalQuestion} component={RefillCheckpoint} />
              <Route exact path={Routes.Dashboard} component={Dashboard} />
              <Route exact path={Routes.ContinueOnboarding} component={ContinueOnboarding} />
              <Route exact path={Routes.ContinueIntake} component={ContinueOnboarding} />
              <Route
                exact
                path={Routes.UpdateQuestionnaire + '/complete'}
                component={UpdateQuestionnaireConfirmationAsync}
              />
              <Route path={Routes.UpdateQuestionnaire} component={UpdateQuestionnaireAsync} />
              <Route exact path={Routes.Intake} component={Intake} />
              <Route exact path={Routes.Settings} component={BlackSettings} />
              <Route exact path={Routes.ContactUs} component={ContactUs} />
              <Route exact path={Routes.BillingSettings} component={BillingPreferences} />
              <Route exact path={Routes.WarriorRetakePhotos} component={TrackGrowthAsync} />
              <Route exact path={Routes.ManageSubscription} component={ManageSubscriptions} />
              <Route exact path={Routes.ApplySubscriptionDeal} component={ApplySubscriptionDeal} />
              <Route exact path={Routes.UpdateSubscription} component={GenericUpdateSubscription} />
              <Route exact path={Routes.PasswordSettings} component={PasswordPreferences} />
              <Route exact path={Routes.CongratsKing} component={CongratsKing} />
              <Route exact path={Routes.CongratsMagician} component={CongratsMagician} />
              <Route exact path={Routes.ScheduleAppointment} component={AppointmentPage} />
              <Route exact path={Routes.ReScheduleAppointment} component={AppointmentPage} />
              <Route exact path={Routes.LabCheckout} component={LabCheckoutPage} />
              <Route exact path={Routes.LabCheckoutSuccess} component={LabCheckoutSuccessPage} />
              <Route exact path={Routes.UploadOwnLabs} component={UseOwnLabStep} />
              <Route exact path={Routes.AnnualLabCheckout} component={AnnualLabCheckout} />
              <Route exact path={Routes.Messages} component={Messages} />
              <Route exact path={Routes.MessagesNew} component={MessagesNew} />
              <Route exact path={Routes.MessagesThread} component={MessagesThread} />
              <Route exact path={Routes.NotificationCenter} component={NotificationCenter} />
              <Route path={Routes.Dashboard} render={() => <Redirect to={Routes.Dashboard} />} />
            </Switch>
          </main>
          {paymentErrorMessageStripe && onAllowedPages && <BillingIssueModal message={paymentErrorMessageStripe} />}
        </Drawer>
      </ProductProvider>
    </div>
  );
};

export default Customer;
