import React, { useCallback, useContext } from 'react';
import { Card } from 'mui';
import TrackerNotification, { Notification, NotificationType } from '../ProgressTracker/common/TrackerNotification';
import { useAppSelector } from 'app/helpers/hooks';
import {
  selectCustomerId,
  selectCustomerDismissedNotifications,
  selectCustomerLabOrders,
  selectProductLabOrderResults,
  selectAllErxShipmentDatesV2,
} from 'app/selectors/customer';
import { ProductContext } from '../Product';

const NotificationCenter = () => {
  const customerId = useAppSelector(selectCustomerId);
  const dismissedNotifications = useAppSelector(selectCustomerDismissedNotifications);
  const productCtx = useContext(ProductContext);

  const isDismissed = useCallback(
    (name) => dismissedNotifications.some((n) => n.get('notification_name') === name),
    [dismissedNotifications],
  );

  const labInfo = useAppSelector(selectCustomerLabOrders)
    .toJS()
    .filter((order) => order.intake_name !== null);
  const labOrderResults = useAppSelector((state) => selectProductLabOrderResults(state, productCtx.selectedProduct));
  const erxShipmentDates = useAppSelector((state) => selectAllErxShipmentDatesV2(state)) || [];

  const collectAllNotifications = () => {
    const result: Array<Notification> = [];

    labInfo.forEach((order) => {
      if (order.shipped_at) {
        result.push({
          type: NotificationType.LabInfo,
          date: order.shipped_at,
          dismissed: isDismissed(`lab-shipped-${order.shipped_at}`),
          obj: order,
        });
      }
    });

    labOrderResults.forEach((labOrderResult) => {
      result.push({
        type: NotificationType.LabOrderResult,
        date: labOrderResult.get('date'),
        dismissed: isDismissed(`lab-result-${labOrderResult.get('file').get('blob_id')}`),
        obj: labOrderResult,
      });
    });

    erxShipmentDates.forEach((erxShipmentDate) => {
      result.push({
        type: NotificationType.ErxShipmentDate,
        date: erxShipmentDate.date,
        dismissed: isDismissed(`erx-${erxShipmentDate.intake}`),
        obj: erxShipmentDate,
      });
    });

    return result.sort((a, b) => {
      if (a.dismissed && !b.dismissed) {
        return 1;
      }
      if (!a.dismissed && b.dismissed) {
        return -1;
      }

      return Date.parse(b.date) - Date.parse(a.date);
    });
  };

  const allNotifications = collectAllNotifications();

  return (
    <div className="centered mt-12 lg:mt-16">
      <h3 className="ml-10 sm:ml-5">Notifications</h3>
      <Card className="customer-card-content mt-4 lg:mt-6 py-7 lg:py-10 mb-4 lg:mb-6">
        {allNotifications.length === 0 ? (
          <p className="text-center">No notifications available</p>
        ) : (
          allNotifications.map((notification, index) => (
            <React.Fragment key={`${notification.type}-${notification.date}-${index}`}>
              <TrackerNotification notification={notification} customerId={customerId} />
              {index < allNotifications.length - 1 && <div className="my-8 gray-border-top"></div>}
            </React.Fragment>
          ))
        )}
      </Card>
    </div>
  );
};

export default NotificationCenter;
