import React, { useState } from 'react';
import { FormField, Button } from 'mui';
import axios from 'axios';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import { getCSRF } from 'app/api';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';

const MessagesNew = () => {
  const [message, setMessage] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const customer = useAppSelector(selectCustomer);
  const physicianName = customer.get('physician')?.get('short_name');

  const sendMessage = async () => {
    setSending(true);
    setError(null);
    try {
      const response = await axios.post(
        '/api/messages/reply',
        {
          message: message,
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      );
      const threadId = response.data['thread_id'];
      if (threadId) {
        dispatch(push(Routes.MessagesThread.replace(':thread_id', threadId)));
      } else {
        setError('Unable to send the message. Please try again later.');
      }
    } catch (error) {
      setError('Failed to send message. Please try again.');
    } finally {
      setSending(false);
    }
  };

  return (
    <div className="centered flex-column -middle height__full-screen flex-between p-5">
      <h3 className="ml-10 sm:ml-5 mb-5">Messages</h3>
      <div className="card p-8">
        <h4 className="mb-5">New message</h4>
        <FormField
          label={`To: ${physicianName || 'Maximus Provider'}`}
          multiline
          placeholder=""
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={4}
          disabled={sending}
          errorText={error}
          autoFocus={true}
        />
        <Button onClick={sendMessage} className="mt-5" variant="primary" disabled={sending || message.trim() === ''}>
          Send
        </Button>
        <Button variant="text" disabled={sending} onClick={() => dispatch(push(Routes.Messages))}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default MessagesNew;
