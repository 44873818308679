import React from 'react';
import { apologizeForSelector } from 'app/selectors/apologize';
import { useAppSelector } from 'app/helpers/hooks';
import { Redirect } from 'react-router';
import { Root } from 'app/constants/Routes';
import FailureState from '../customer/dashboard/FailureState';
import Header from '../customer/Header';

// eslint-disable-next-line react/display-name
export default () => {
  const apologizeFor = useAppSelector(apologizeForSelector);

  if (!apologizeFor) {
    return <Redirect to={Root} />;
  }

  return (
    <div className="grid-container">
      <Header />
      <main>
        <FailureState state={apologizeFor} />
      </main>
    </div>
  );
};
