import React from 'react';
import { Button, Card, PageWrapper } from 'mui';

const Denied = () => (
  <PageWrapper>
    <Card>
      <Card.Body>
        <h4>We Apologize</h4>
        <p>
          We&apos;re sorry but we need to have access to your device location in order to confirm your current position.
          Please enable location settings in your Site Settings / Browser settings and reload this page.
        </p>
        <Button data-testid="reload-page" text="Reload Page" onClick={() => window.location.reload()}>
          Reload Page
        </Button>
      </Card.Body>
    </Card>
  </PageWrapper>
);

export default Denied;
