import React, { useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import PuffLoader from 'react-spinners/PuffLoader';
import './css/Spinner.scss';

const Spinner = ({ isCenter = false }) => {
  const [showReload, setShowReload] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowReload(true);
    }, 15000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const reloadPage = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.reload();
  }, []);

  return (
    <div className={cx({ spinner__center: isCenter })}>
      <div className="spinner__auto">
        <PuffLoader color="#4f91ff" />
      </div>
      {showReload && (
        <a href="#" className="spinner__text" onClick={reloadPage}>
          This operation took longer than usual,
          <br />
          please click here to refresh
        </a>
      )}
    </div>
  );
};

export default Spinner;
