import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { AvailableProducts, WarriorSubProducts } from 'app/constants/Products';
import SlidersIcon from 'images/icons/sliders.svg';
import FreeshippingIcon from 'images/icons/fast-free-shipping-2.svg';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import { WARRIOR_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import { WarriorSubProductsConfig } from 'app/components/customer/steps/Payment/warrior/WarriorSubProductConfig';
import { WarriorSafetyInfo } from 'app/components/customer/steps/Payment/warrior/WarriorSafetyInfo';
import WarriorSideEffectsInfo from 'app/components/customer/steps/Payment/warrior/WarriorSideEffectsInfo';
import WarriorSafetyAcknowledgment from 'app/components/customer/steps/Payment/warrior/WarriorSafetyAcknowledgment';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import IngredientIcon from 'images/icons/ingredient.svg';
import AtHomeIcon from 'images/icons/at-home-monitoring-2.svg';
import { Accordion, Badge, Button, Card, IconList } from 'mui';

type Props = {
  handleSubmit: () => void;
};

const IngredientIconComponent = () => <img src={IngredientIcon} alt="ingredient" />;
const AtHomeIconComponent = () => <img src={AtHomeIcon} alt="at home monitoring" />;
const FreeShippingIconComponent = () => <img src={FreeshippingIcon} alt="free shipping" />;
const SlidersIconComponent = () => <img src={SlidersIcon} alt="at home monitoring" />;

const WarriorSubProductScreen = ({ handleSubmit }: Props) => {
  const [openModalAcknowledgment, setOpenModalAcknowledgment] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    warrior_strength: WarriorSubProducts;
  }>();

  const subProduct = WarriorSubProductsConfig[values.warrior_strength];

  const handleProductSelection = () => {
    if (values.warrior_strength === 'oral_minoxidil' || values.warrior_strength === 'topical_minoxidil') {
      handleSubmit();
    } else {
      setOpenModalAcknowledgment(true);
    }
  };

  const renderFooter = () => (
    <div className="fixed-footer">
      <div className="m-auto max-w-[26rem]">
        <p>Plans Starting at ${WARRIOR_MONTHLY_PRICE[values.warrior_strength][3] / 100}/mo</p>

        <Button
          className="mt-4 mb-4"
          type="button"
          onClick={handleProductSelection}
          disabled={false}
          testid="submit-product-selection"
        >
          Continue with this Protocol
        </Button>

        <p>
          <button onClick={() => setFieldValue('warrior_strength', false)} className="link bold">
            Explore Other Treatments
          </button>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <Card>
        <Card.Body>
          <figure className="h-[180px] lg:h-[240px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.Warrior][values.warrior_strength]}
              alt="king v2"
            />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.Warrior} className="mb-2">
              {protocolNames[AvailableProducts.Warrior]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.Warrior][values.warrior_strength]}</h4>
          </div>
          <Badge variant="outline">Recommended</Badge>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h5>Treatment Details</h5>
          <p>{subProduct.recommended_for}</p>
          <hr />
          <h5>Active Ingredients</h5>
          {subProduct.active_ingredients.map((ingredient) => (
            <>
              <IconList
                key={ingredient.name}
                items={[
                  {
                    title: ingredient.name,
                    text: ingredient.description,
                    icon: IngredientIconComponent,
                  },
                ]}
              />
              <hr />
            </>
          ))}
          <h5>What to Expect</h5>
          <IconList
            items={[
              {
                title: 'Check Your Levels',
                text: 'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
                icon: AtHomeIconComponent,
              },
              {
                title: 'Get Your Prescription',
                text: 'Once approved, your prescription is shipped straight to your door.',
                icon: FreeShippingIconComponent,
              },
              {
                title: 'Continuous Optimization',
                text:
                  'After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage\n' +
                  ' for optimal results.',
                icon: SlidersIconComponent,
              },
            ]}
          />
          <div className="-mb-8 md:-mb-12 lg:-mb-8">
            <hr />
            <Accordion
              items={[
                {
                  title: 'Side Effects',
                  content: <WarriorSideEffectsInfo sub_product={values.warrior_strength} />,
                },
                {
                  title: 'Important safety information',
                  content: <WarriorSafetyInfo sub_product={values.warrior_strength} />,
                },
              ]}
            />
          </div>
        </Card.Body>
      </Card>
      {renderFooter()}
      <SetProductDialog
        isOpen={openModalAcknowledgment}
        title={<h4 className="subtitle bold mb0">Safety Information</h4>}
        text={
          <WarriorSafetyAcknowledgment
            handleSubmit={handleSubmit}
            selectOtherTreatments={() => setFieldValue('warrior_strength', false)}
          />
        }
        onClose={() => setOpenModalAcknowledgment(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </>
  );
};

export default WarriorSubProductScreen;
