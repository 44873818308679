import React, { useState, useMemo, FC, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Question from 'app/constants/Question';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import { getComponentForQuestion, getDefaultValue } from 'app/components/customer/questions/helpers';
import { getIntakeSchema } from 'app/helpers/validators';
import { AvailableProducts } from 'app/constants/Products';
import { Card, Badge, ProgressBar } from 'mui';
import { trackEvent } from 'app/utils/useCommonTracking';
import protocolNames from 'app/utils/protocolNames';
import { SEGMENT_LOVER_EVENTS_STEP_MAPPING } from 'app/constants/SegmentEvents';

const INITIAL_STEPS = ['sex', 'erection', 'ed_scenario', 'ed_start'];

const getNextStep = (steps, currentIndex, values) => {
  if (values['sex'] === 'f') return null;

  const nextStep = steps[currentIndex + 1];

  if (!nextStep) {
    return null;
  }

  if (values.erection === 'never' && ['ed_scenario', 'ed_start'].includes(nextStep)) {
    return getNextStep(steps, currentIndex + 1, values);
  }

  return nextStep;
};

const Step0 = ({ onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(INITIAL_STEPS[0]);
  useEffect(() => {
    trackEvent(SEGMENT_LOVER_EVENTS_STEP_MAPPING[currentStep]?.viewEvent);
  }, [currentStep]);
  const questions = useMemo(
    () => INITIAL_STEPS.reduce<Map<any, any>>((o, q) => o.set(q, new Question(q)), new Map()),
    [INITIAL_STEPS],
  );
  const [initialValues, setInitialValues] = useState(
    INITIAL_STEPS.reduce((o, q) => ({ ...o, [q]: getDefaultValue({ question: questions.get(q) }) }), {}),
  );
  const position = INITIAL_STEPS.indexOf(currentStep);
  const total = questions.size;

  const question = questions.get(currentStep);
  const IntakeSchema = getIntakeSchema(question);
  const Component = getComponentForQuestion({ question }) as FC<{
    question: any;
    name: string;
    checkedImage: typeof checkboxCheckedImage;
    uncheckedImage: typeof checkboxUncheckedImage;
    contained: boolean;
  }>;

  const submitNextStep = (values) => {
    const nextStep = getNextStep(INITIAL_STEPS, position, values);
    trackEvent(SEGMENT_LOVER_EVENTS_STEP_MAPPING[currentStep]?.completionEvent, { answer: values[currentStep] });
    if (!nextStep) {
      const clearValues = Object.entries(values).reduce((o, [k, v]) => {
        if (v !== '') {
          o[k] = v;
        }

        return o;
      }, {});
      onSubmit(clearValues);
    } else {
      setInitialValues(values);
      setCurrentStep(nextStep);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitNextStep} validationSchema={IntakeSchema} enableReinitialize>
      {() => (
        <Form>
          <Card>
            <Card.Body>
              <ProgressBar progress={(100 * position) / total} />
              <div className="flex flex-row justify-between">
                <Badge variant={AvailableProducts.Lover}>{protocolNames[AvailableProducts.Lover]}</Badge>
                <span>
                  {position + 1} of {total}
                </span>
              </div>
              <p>{question.sentence}</p>
              {question.data.description && (
                <div>
                  {question.data.description.includes('Why are we asking this?') && <b>Why are we asking this?</b>}
                  <p className="pre-wrap text-sm">{question.data.description.split('\n\n').pop()}</p>
                </div>
              )}
              <div className="component">
                <Component
                  question={question}
                  name={currentStep}
                  checkedImage={checkboxCheckedImage}
                  uncheckedImage={checkboxUncheckedImage}
                  contained={false}
                />
              </div>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default Step0;
