import React, { useCallback } from 'react';
import Carousel from 'app/components/carousel/Carousel';
import { Button } from 'mui';
import ImageLabKit from 'images/progress-tracker/Ebook.png';
import ImageDiscord from 'images/progress-tracker/Discord.png';
import './css/HomePageResources.scss';

const HomePageResources = () => {
  const Slide = ({ title, description, btnText, btnLink, imageSrc, imageAlt = 'Resource Image' }) => {
    const handleClick = useCallback(() => {
      window.open(btnLink, '_blank');
    }, [btnLink]);

    return (
      <div className="slide-card mx-2 lg:mx-4">
        <img className="slide-card__image" src={imageSrc} alt={imageAlt} />
        <div className="slide-card__body">
          <h4 className="slide-card__body__title">{title}</h4>
          <p className="slide-card__body__description mb-5">{description}</p>
          <Button variant="outline" onClick={handleClick}>
            {btnText}
          </Button>
        </div>
      </div>
    );
  };

  const slides = [
    <Slide
      key="The Maximus E-Book"
      title="The Maximus E-Book"
      description="To learn more about testosterone and the steps you can take to maximize your masculinity, check out the
          Maximus E-Book."
      btnText="View E-book"
      btnLink={window.EBOOK_URL}
      imageSrc={ImageLabKit}
    />,
    <Slide
      key="Discord Community"
      title="Discord Community"
      description="Discord is the social platform we use to offer coaching and peer discussions. Join the tribe today."
      btnText="JOIN DISCORD"
      btnLink={window.DISCORD_URL}
      imageSrc={ImageDiscord}
    />,
  ];

  return (
    <div className="dash-resources centered">
      <h3 className="ml-10 sm:ml-5 mb-5">Resources</h3>
      <Carousel items={slides} />
    </div>
  );
};
export default HomePageResources;
