import React from 'react';

const GenericOnboardingServices = () => (
  <div className="border border-primary-200 rounded-xl p-4 lg:p-8">
    <p className="mb-2 lg:mb-4">
      <b>Plan includes: </b>
    </p>
    <ul className="disc-list">
      <li>Medication</li>
      <li>Doctor messaging</li>
      <li>Concierge support & coaching</li>
      <li>Free shipping</li>
    </ul>
  </div>
);

export default GenericOnboardingServices;
