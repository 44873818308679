import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormikErrors } from 'formik';
import { CustomerShippingAddress } from 'app/types';
import { APIProvider, useMap, useMapsLibrary, Map as GMap } from '@vis.gl/react-google-maps';
import GoogleLogo from 'images/google-logo/google_on_white_hdpi.png';
import './css/GoogleAutoComplete.scss';

interface ShippingValues extends CustomerShippingAddress {
  phone_number?: string;
  receive_sms?: boolean;
}

interface ParsedAddressObject {
  street?: string;
  streetNumber?: number;
  city?: string;
  zip?: number;
  state?: string;
}

export const GoogleAutoComplete: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  inputDefaultClassName?: string;
  values?: ShippingValues;
  setFieldValue?: (
    field: string,
    value: React.SetStateAction<any>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  children: any;
}> = ({ values, setFieldValue, setFieldTouched, children }) => {
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutoCompletservice] = useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [predictions, setPredictions] = useState<Array<google.maps.places.AutocompletePrediction>>([]);
  const [lockPredictions, setLockPredictions] = useState<boolean>(false);
  const places = useMapsLibrary('places');
  const map = useMap();
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPredictions([]);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (!places || !map) return;

    setAutoCompletservice(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
    setPlacesService(new places.PlacesService(map));

    return () => setAutoCompletservice(null);
  }, [places, map]);

  useEffect(() => {
    if (!values || lockPredictions) return;
    const street1 = values.address_line_1;
    fetchPrediction(street1);
  }, [places, values?.address_line_1]);

  const fetchPrediction = useCallback(
    async (input) => {
      if (!autocompleteService || !input) {
        setPredictions([]);
        return;
      }
      const request = {
        input: input,
        componentRestrictions: { country: 'us' },
        sessionToken,
      };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictions(response.predictions);
    },
    [values, sessionToken],
  );

  const handlePrediction = (placeId: string) => {
    if (!places || !setFieldValue || !setFieldTouched) return;

    const detailRequestOptions = {
      placeId,
      fields: ['address_components'],
      sessionToken,
    };

    const detailRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
      const parsedAddressObject: ParsedAddressObject = parseAddressComponent(placeDetails?.address_components || []);
      const { street, streetNumber, city, zip, state } = parsedAddressObject;
      if (street && streetNumber) {
        setFieldValue('address_line_1', `${parsedAddressObject.streetNumber} ${parsedAddressObject.street}`);
        setFieldTouched('address_line_1');
      }
      if (city) {
        setFieldValue('city', city);
        setFieldTouched('city');
      }
      if (zip) {
        setFieldValue('postal_code', zip);
        setFieldTouched('postal_code');
      }
      if (state) {
        setFieldValue('state', state);
      }
      setPredictions([]);
      setLockPredictions(false);
    };

    setLockPredictions(true);
    placesService?.getDetails(detailRequestOptions, detailRequestCallback);
  };

  const parseAddressComponent = (addressComponent: any[]) => {
    const result = {};

    for (const component of addressComponent) {
      const type = component.types;
      let key: string | undefined;

      if (type.includes('street_number')) {
        key = 'streetNumber';
      } else if (type.includes('route')) {
        key = 'street';
      } else if (type.includes('locality')) {
        key = 'city';
      } else if (type.includes('administrative_area_level_1')) {
        key = 'state';
      } else if (type.includes('postal_code')) {
        key = 'zip';
      }

      if (key) result[key] = component.short_name;
    }

    return result;
  };

  const renderPredictions = () => {
    if (!predictions.length) return null;

    return (
      <ul className="google_autocomplete_container" ref={wrapperRef}>
        {predictions.map(({ place_id, description }) => {
          return (
            <React.Fragment key={`${place_id}_wrapper`}>
              <li className="input" key={place_id} onClick={() => handlePrediction(place_id)}>
                {description}
              </li>
            </React.Fragment>
          );
        })}
        <div className="google-logo-section" key="google-logo-section">
          Powered by <img src={GoogleLogo} />
        </div>
      </ul>
    );
  };

  const newProps = { ...children.props, predictions: renderPredictions() };

  return React.cloneElement(children, newProps);
};

// For the why: Google Maps need to live outside or useMap hook won't work.
// This needs to live as a set if you want autocomplete work.
const GoogleAutoCompleteWrapper: FC<{
  values: ShippingValues;
  setFieldValue: (
    field: string,
    value: React.SetStateAction<any>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  inputDefaultClassName?: string;
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  children: any;
}> = ({ values, setFieldValue, setFieldTouched, inputDefaultClassName, disabled, labels, className, children }) => {
  return (
    <APIProvider apiKey={window.googleMapsAPIKey}>
      <GoogleAutoComplete
        values={values}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        inputDefaultClassName={inputDefaultClassName}
        disabled={disabled}
        labels={labels}
        className={className}
      >
        {children}
      </GoogleAutoComplete>
      <div className="display-none">
        <GMap zoom={15} center={{ lat: 37.7749, lng: -122.4194 }} />
      </div>
    </APIProvider>
  );
};

export default GoogleAutoCompleteWrapper;
