import dayjs from 'dayjs';
import { getTracking } from 'app/utils/tracking';

type HomePageUpNextItem = { title: string; description: string; trackingUrl: string | undefined; protocolName: string };

const homePageShipmentNotifications = (customer): HomePageUpNextItem[] => {
  const nextLabShipment = customer.get('next_lab_shipment');
  const nextErxShipment = customer.get('next_erx_shipment');
  const upNextItems: HomePageUpNextItem[] = [];

  const mapShipmentStatusDescription = (status) => {
    return {
      unknown: 'Unknown',
      in_transit: 'Arrives',
      transit: 'Arrives',
      pre_transit: 'Shipped',
      delivered: 'Arrived',
      returned: 'Returned',
      failure: 'Failure',
    }[status];
  };

  const mapShipmentStatus = (status) => {
    return {
      unknown: 'Unknown',
      in_transit: 'In transit',
      transit: 'In transit',
      pre_transit: 'Shipped',
      delivered: 'Delivered',
      returned: 'Returned',
      failure: 'Failure',
    }[status];
  };

  const createShipmentItem = (shipment, title) => {
    const tracking = getTracking(shipment.get('tracking_number'), shipment.get('carrier'));
    const dateToDisplay = shipment.get('eta')
      ? dayjs(shipment.get('eta')).format('MMMM DD, YYYY')
      : dayjs(shipment.get('date_shipped')).format('MMMM DD, YYYY');
    return {
      title: `${title} - ${mapShipmentStatus(shipment.get('status'))}`,
      description: `${mapShipmentStatusDescription(shipment.get('status'))} ${dateToDisplay}`,
      trackingUrl: tracking?.trackingUrl?.replace('%s', tracking.trackingNumber),
      protocolName: shipment.get('protocol_name'),
    };
  };

  if (nextLabShipment) {
    upNextItems.push(createShipmentItem(nextLabShipment, 'Follow-up Lab Kit'));
  }

  if (nextErxShipment) {
    upNextItems.push(createShipmentItem(nextErxShipment, 'Next shipment'));
  }

  return upNextItems;
};

export default homePageShipmentNotifications;
