import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { push } from 'connected-react-router/immutable';
import * as routerSelectors from 'app/selectors/router';
import * as selectors from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';
import Input from 'app/components/common/formik/Input';
import { PasswordUpdateIfExistSchema, PasswordUpdateIfNewSchema } from 'app/helpers/validators';
import { passwordUpdateSubmitted, PASSWORD_UPDATE_SUCCESS, NOOP } from 'app/actions/customer';
import { Button, Card, Modal, PageWrapper } from 'mui';

const mapStateToProps = (state) => {
  const customerHasPassword = selectors.selectHasPassword(state);
  const enableResetMode = routerSelectors.selectRouterQuery(state).get('reset') === 'true';

  return {
    customerHasPassword,
    enableResetMode,
  };
};

export const PasswordPreferencesForm = ({ customerHasPassword, enableResetMode, dispatch, customOnCancel }) => {
  const [success, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState(enableResetMode);

  let validationSchema = PasswordUpdateIfNewSchema;
  const initialValues: Record<string, string> = {
    password: '',
    password_confirmation: '',
  };

  if (customerHasPassword && !newPassword) {
    initialValues.password_current = '';
    validationSchema = PasswordUpdateIfExistSchema;
  }

  const onCancel = () => {
    if (customOnCancel) {
      customOnCancel();
    } else {
      dispatch(push(Routes.Settings));
    }
  };

  const onSubmit = async (values, form) => {
    const action = passwordUpdateSubmitted({
      params: enableResetMode ? { ...values, skip_verify: true } : values,
      context: {
        onSuccessActionCreator: () => {
          setSuccess(true);
          if (!values.password_current) {
            setNewPassword(true);
          }

          return { type: PASSWORD_UPDATE_SUCCESS };
        },
        onFailureActionCreator: ({ json: { error } }) => {
          if (error === 'invalid_password') {
            form.setFieldError('password_current', 'Incorrect current password');
          } else {
            form.setErrors({ api: error });
          }

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <>
          <Modal.Header>
            <h4>Update Password</h4>
          </Modal.Header>
          <Modal.Body>
            <p>
              {customerHasPassword && !newPassword ? (
                <>To update your password, provide your current password along with your preferred new password.</>
              ) : (
                <>
                  Please enter and confirm your new password. Once you set your new password you will be able to use it
                  to log in. You can update your password at any time by returning to this page.
                </>
              )}
            </p>
            <div className="flex flex-col gap-y-2">
              {customerHasPassword && !newPassword && (
                <Input
                  id="password_current"
                  name="password_current"
                  label="Current Password"
                  type="password"
                  className="text-field"
                  placeholder="Enter Password"
                />
              )}
            </div>
            <hr />
            <div className="flex flex-col gap-y-2">
              <Input
                id="password"
                name="password"
                label="New Password"
                type="password"
                className="text-field"
                placeholder="Enter Password"
              />
              <Input
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                label="Re-enter new Password"
                className="text-field"
                placeholder="Enter Password"
              />
            </div>
            {success && <p className="text-medium text-magician">Password successfully updated</p>}
            {errors && errors.api && <div className="password-settings__error">{errors.api}</div>}
            {success ? (
              <Button onClick={onCancel}>Back to Settings</Button>
            ) : (
              <>
                <Button onClick={() => handleSubmit()} disabled={isSubmitting}>
                  Update
                </Button>
                <Button onClick={onCancel} variant="outline">
                  Cancel
                </Button>
              </>
            )}
          </Modal.Body>
        </>
      )}
    </Formik>
  );
};

const PasswordPreferences = ({ customerHasPassword, enableResetMode, dispatch }) => {
  const formProps = { customerHasPassword, enableResetMode, dispatch, customOnCancel: undefined };

  return (
    <PageWrapper>
      <Card>
        <Card.Body>
          <PasswordPreferencesForm {...formProps} />
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(PasswordPreferences);
