import React, { useState } from 'react';
import { push } from 'connected-react-router/immutable';
import { Formik } from 'formik';
import { apiRequestUserCommand } from 'app/actions/customer';
import { connect } from 'react-redux';
import * as selectors from 'app/selectors/customer';
import { PharmacySchema } from 'app/helpers/validators';
import ApcImg from 'images/pharmacy/APC-Logo 1.png';
import PcaaImg from 'images/pharmacy/PcaaImg.png';
import LegitScriptImg from 'images/pharmacy/LegitScriptImg.png';
import PharmacyAddress from './Address';
import OtherOption from './OtherOption';
import { Button, Card, PageWrapper } from 'mui';

const mapStateToProps = (state) => {
  const onSuccessAction = push(selectors.selectNextStepPath(state));
  const productName = selectors.selectCurrentIntakeProduct(state);
  const product = selectors.selectCustomerProduct(state, productName)?.toJS();
  const pharmacyChoice = product.pharmacy_choice || 'maximus_preferred_pharmacy';
  const pharmacyInfo = product.pharmacy_info || {
    pharmacy_name: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    phone_number: '',
  };
  const { name = '', zip = '', address_1 = '', address_2 = '', ...rest } = pharmacyInfo;

  const initialValues = {
    ...rest,
    choice: pharmacyChoice,
    pharmacy_name: name,
    address_line_1: address_1,
    address_line_2: address_2,
    postal_code: zip,
  };

  return {
    onSuccessAction,
    pharmacyChoice,
    initialValues,
    productName,
  };
};

export const PharmacyStep = ({ dispatch, pharmacyChoice, onSuccessAction, initialValues, productName }) => {
  const [expanded, setExpanded] = useState(pharmacyChoice === 'other');
  const [checkboxValue, toggleCheckbox] = useState(false);
  const pharmacy = 'maximus_preferred_pharmacy';

  const onSubmit = (values) => {
    const cmdType = 'select_pharmacy';
    const action = apiRequestUserCommand({
      cmdType,
      params: {
        ...values,
        product_name: productName,
      },
      context: { onSuccessAction },
    });
    dispatch(action);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={PharmacySchema} validateOnMount>
      {({ handleSubmit, values: { choice }, isValid, setFieldValue }) => (
        <PageWrapper>
          <Card>
            <Card.Body>
              <h4>Pharmacy Confirmation</h4>
              <p>
                Our clinical protocols use custom doses of specific, speciality compounded drugs which are only
                available at a limited set of compounding pharmacies in the US. If deemed clinically appropriate by your
                attending physician, your prescription will be mailed from an accredited Maximus recommended pharmacy.
              </p>
              <p>
                These pharmacies include:{' '}
                <a href="https://www.belmarpharmasolutions.com/" target="_blank" rel="noreferrer">
                  Belmar Pharma Solutions
                </a>
                {', '}
                <a href="https://precisioncompoundingpharmacy.net/" target="_blank" rel="noreferrer">
                  Precision Compounding Pharmacy
                </a>
                {', '}
                <a href="https://tailormadecompounding.com" target="_blank" rel="noreferrer">
                  Tailor Made Compounding
                </a>
                .
              </p>
              <div className="flex flex-row justify-around">
                <div>
                  <img src={ApcImg} alt="Apc" />
                </div>
                <div>
                  <img src={PcaaImg} alt="Pcaa" />
                </div>
                <div>
                  <img src={LegitScriptImg} alt="LegitScript" />
                </div>
              </div>
              <p>
                We honor our patient&apos;s freedom if you prefer to{' '}
                <button
                  color="gray"
                  className="link"
                  onClick={async () => {
                    if (expanded) {
                      await setFieldValue('choice', pharmacy);
                    }

                    setExpanded((prevValue) => !prevValue);
                  }}
                >
                  choose the pharmacy
                </button>{' '}
                you would like your prescription sent to.
              </p>
              <OtherOption
                setFieldValue={setFieldValue}
                expanded={expanded}
                checkboxValue={checkboxValue}
                toggleCheckbox={toggleCheckbox}
              />
              <PharmacyAddress choice={choice} />
              {(!expanded || checkboxValue) && (
                <Button onClick={handleSubmit} disabled={!isValid} testid="next-step">
                  {expanded ? 'Use This Pharmacy' : 'Confirm'}
                </Button>
              )}
              {expanded && (
                <>
                  <hr className="m-0 p-0" />
                  <Button
                    variant="text"
                    onClick={async () => {
                      await setFieldValue('choice', pharmacy);
                      setExpanded(false);
                      toggleCheckbox(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>
        </PageWrapper>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(PharmacyStep);
