import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import './css/MagicianIntro.scss';
import * as Routes from 'app/constants/Routes';
import protocolNames from '../../../utils/protocolNames';
import { Button, Card, IconList, PageWrapper } from 'mui';
import { AvailableProducts } from '../../../constants/Products';
import StocksListIcon from 'images/stocks-list-icon.svg';

const benefits = [
  'You choose which is best for you: Semaglutide (brand name Wegovy®) or Tirzepatide (brand name Mounjaro™) based on your personal goals and needs',
  'Both options compounded with vitamin B6 to mitigate nausea',
  'Both options paired with science-backed guidance for maintaining lean muscle mass',
];
const access = [
  'Prescription medication (if qualified)',
  'Licensed doctor consultations',
  'Health coaching, community, & other exclusive content',
  'Priority shipping for medication',
];

const mapStateToProps = (state) => ({
  intake: selectors.selectCurrentIntake(state),
});

const MagicianOnboardingAcknowledgement = ({ step, intake, dispatch }) => {
  const action = actions.apiRequestUserCommand({
    cmdType: 'answer_questions',
    params: { [step]: true, intake: intake.get('name') },
    context: {
      onSuccessActionCreator: ({ json: { entity } }) =>
        push(
          Routes.createIntake(
            intake.get('name'),
            entity.maximus_products.magician.intakes[0].steps.filter((step) => !step.completed)[0].url_friendly_name,
          ),
        ),
    },
  });

  const onClick = () => {
    dispatch(action);
  };

  const iconsList = (elements) => {
    const items = elements.map((item, _index) => ({
      text: item,
      icon: () => <img src={StocksListIcon} alt="" />,
    }));

    return <IconList items={items} />;
  };

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-4 lg:gap-y-6">
      <div className="ml-10 sm:ml-5">
        <h3 className="mb-2">{protocolNames[AvailableProducts.Magician]} Protocol</h3>
        <p>
          A comprehensive {protocolNames[AvailableProducts.Magician]} Protocol developed by Maximus to help men preserve
          lean muscle mass while achieving their weight loss goals. The protocol uses cutting-edge compound medications,
          health coaching, and exclusive, science-backed educational content.
        </p>
      </div>
      <Card>
        <Card.Body>
          <h5>The Maximus approach:</h5>
          {iconsList(benefits)}
          <hr />
          <h5>Your subscription covers the cost of:</h5>
          {iconsList(access)}
          <Button variant="primary" onClick={onClick} data-testid="next-step">
            Start My Journey
          </Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default connect(mapStateToProps)(MagicianOnboardingAcknowledgement);
