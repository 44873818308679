import { useEffect } from 'react';

export default ({ children }) => {
  useEffect(() => {
    if (!window.Spreedly) {
      // Load https://core.spreedly.com/iframe/iframe-v1.min.js
      const script = document.createElement('script');
      script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
      script.async = true;

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  });

  return children;
};
