import React, { FC } from 'react';

import { useField } from 'formik';
import CommonDropdown from 'app/components/common/Dropdown';

export const Dropdown: FC<{
  id?: string;
  multiple?: boolean;
  className?: string;
  name: string;
  disabled?: boolean;
  emptyValue?: string;
  showLabel?: boolean;
  position?: string;
  label?: string | undefined;
  options?: {
    label: string;
    value: any;
  }[];
  onStateChange?: (option: string) => void;
}> = ({ options = [], ...inputProps }) => {
  const [field, { touched, error }, { setValue }] = useField({ ...inputProps, type: 'select' });

  return (
    <CommonDropdown
      // name={name}
      onClick={(option) => {
        setValue(option.value);
        inputProps.onStateChange && inputProps.onStateChange(option.value);
      }}
      {...field}
      disabled={inputProps.disabled}
      emptyValue={inputProps.emptyValue}
      showLabel={inputProps.showLabel}
      position={inputProps.position}
      className={inputProps.className}
      options={options}
      testId={inputProps.id}
      label={inputProps.label}
      errorHelperText={touched && error}
      error={touched && Boolean(error)}
    />
  );
};
