import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PageWrapper, Card, Button } from 'mui';

const LoverPostScript = () => {
  const customer = useAppSelector(selectors.selectCustomer);
  const physicianTitle = customer.getIn(['physician', 'short_name']);
  const intake = useAppSelector(selectors.selectCurrentIntake);
  const currentStep = useAppSelector(selectors.selectCurrentStepName);
  const nextStep = useAppSelector(selectors.selectNextStepPath);
  const onSuccessAction = push(nextStep);
  const dispatch = useAppDispatch();

  const onClick = () => {
    const cmdType = 'answer_questions';
    const params: any = { [currentStep]: true };
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessAction },
    });
    dispatch(action);
  };
  const isNeedPressure =
    !intake.getIn(['questionnaire', 'blood_pressure_reading']) ||
    intake.getIn(['questionnaire', 'blood_pressure_systolic']) === 'dont_remember' ||
    intake.getIn(['questionnaire', 'blood_pressure_diastolic']) === 'dont_remember';

  return (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <h3 className="ml-10 sm:ml-5">Almost there!</h3>
      <Card>
        <Card.Body>
          <h5>We have successfully received your questionnaire. There is one step left.</h5>
          {isNeedPressure && (
            <p className="mt-4">
              To evaluate which treatment plan is best for you, your doctor needs you to take your blood pressure at a
              local pharmacy, grocery store, or with a healthcare provider. Return once you know your blood pressure and{' '}
              {physicianTitle} will make a treatment recommendation.
            </p>
          )}
          <Button data-testid="next-step" onClick={onClick}>
            Continue
          </Button>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
};

export default LoverPostScript;
